import React from 'react';
// Models
import IReservation from 'models/Reservation';
// MUI
import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
// Utilities
import { formatStartEndDate } from 'utilities/DateTimeFormatter';
import { formatCash } from 'utilities/Utilities';

type Props = {
  onClose: () => void;
  reservation: IReservation;
}

const PaymentSuccess:React.FC<Props> = ({ onClose, reservation }) => {
  const classes = useStyles();

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', p: { xs: 2, md: 4 }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography className={classes.title}>Payment successful!</Typography>
      <img alt="Success" style={{ width: '100px', objectFit: 'contain' }} src="/img/icons/done_success.svg" />
      <Typography className={classes.subtitle}>{reservation.propertyName}</Typography>
      <Typography className={classes.text}>{formatStartEndDate(reservation.start, reservation.end)}</Typography>
      <Typography className={classes.text}>{`Amount paid ${formatCash(reservation.price)}`}</Typography>
      <Box sx={{  pt: 4 }}>
        <Button
          sx={{ width: '160px' }}
          variant="contained"
          onClick={onClose}
        >Close</Button>
      </Box>
    </Box>

  );
}

export default PaymentSuccess;

const useStyles = makeStyles({
  title: {
    fontSize: '24px',
    color: '#4BB543',
  },
  subtitle: {
    paddingTop: '8px',
    fontSize: '20px',
    fontWeight: 500,
  },
  text: {
    paddingTop: '8px',
  },
});
