import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from 'hooks/useDialog';
// Models
import { IPoll } from "models/Poll";
// Async
import PollsAsync from "store/polls/pollsAsync";
// Actions
import { pollActions } from 'store/polls/pollsSlice';
// Selectors
import { selectAccountsSettings } from "store/accounts/accountsSelectors";
import { selectPolls, selectTotal, selectParams } from "store/polls/pollsSelectors";
// Components
import Title from "components/Title";
import PollCard from "./PollCard";
import PollDetailsDialog from './PollDetails.dialog';
// Mui
import { makeStyles } from '@mui/styles';
import { Box, Tab, TablePagination, Tabs } from "@mui/material";
// Utilities
import { Loader, NoData } from "components/Utilities";

const PollsPage:React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const polls = useAppSelector(selectPolls);
  const total = useAppSelector(selectTotal);
  const params = useAppSelector(selectParams);
  const accountsSettings = useAppSelector(selectAccountsSettings);

  const [tab, setTab] = useState<string>('Unvoted');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ page, setPage ] = useState<number>(0);
  const [ size, setSize ] = useState<number>(20);

  const handleChangeTab = (_: any, newTab: string) => {
    setTab(newTab);
  };

  const handleChangePage = (_:any, nextPage:number) => {
    setIsLoading(true);
    setPage(nextPage);
    dispatch(PollsAsync.fetchPolls({ ...params, page, size }))
      .unwrap()
      .finally(() => setIsLoading(false));
  }

  const handleChangeLimit = (event: any) => {
    const { value:size } = event.target;
    setIsLoading(true);
    setSize(size);
    setPage(0);
    dispatch(PollsAsync.fetchPolls({ ...params, page, size }))
      .unwrap()
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(PollsAsync.fetchPolls({ ...params, page: 0, size: 20, voted: `${tab === 'Voted'}` }))
      .unwrap()
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [tab]);

  const { Dialog, openDialog, closeDialog } = useDialog();

  const handleOpenDetailsDialog = (poll: IPoll) => {
    dispatch(pollActions.setSelectedPoll(poll));
    openDialog();
  }

  if ( accountsSettings.pollModuleEnabled === false ) return <Navigate to="/" replace />;

  return (
    <React.Fragment>
      <Dialog maxWidth="md">
        <PollDetailsDialog
          onClose={closeDialog}
        />
      </Dialog>

      <Box className={classes.page}>
        <div className="container">
          <Title>Polls</Title>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: 'max-content', mt: 2 }}>
            <Tabs
              variant="scrollable"
              value={tab}
              onChange={handleChangeTab}
              aria-label="polls tabs"
            >
              {['Unvoted', 'Voted'].map((key: string) => (
                <Tab sx={{ fontSize: { xs: '12px', sm: '14px' } }} key={key} value={key} label={key} />
              ))}
            </Tabs>
          </Box>
          {isLoading ? <Loader /> : (
            !!total ? (
              <Box className={classes.list} sx={{ mt: 2 }}>
                <Box className={classes.items}>
                  {polls?.map(poll => (
                    <PollCard key={poll.id} poll={poll} openDetailsDialog={handleOpenDetailsDialog} />
                  ))}
                </Box>
                <Box className={classes.pagination}>
                  <TablePagination
                    component="div"
                    count={total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={size}
                    onRowsPerPageChange={handleChangeLimit}
                    labelRowsPerPage="Items per page"
                    rowsPerPageOptions={[20, 50, 100]}
                  />
                </Box>
              </Box>
            ) : <NoData />
          )}
        </div>
      </Box>
    </React.Fragment>
  );
}

export default PollsPage;

const useStyles = makeStyles({
  page: {
    height: 'calc(100vh - 266px)',
    backgroundColor: '#F5F6F7',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
      height: 'calc(100vh - 236px)',
    },
  },
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 462px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 503px)',
    },
  },
  pagination: {
    boxShadow: '0px 0px 0px 1px #E0E0E0'
  }
});
