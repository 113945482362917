import React, { useEffect, useState } from "react";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// async
import ReservationsAsync from "store/reservations/reservationsAsync";
// types
import ReservationStatus from "types/ReservationStatus";
// Models
import IReservation from "models/Reservation";
// selectors
import { selectReservations, selectTotal } from "store/reservations/reservationsSelectors";
import { selectCurrentUser } from "store/users/usersSelectors";
// components
import ReservationCard from "./ReservationCard";
import Title from "components/Title";
import { Loader, NoData } from "components/Utilities";
// mui
import { Box, Tab, TablePagination, Tabs } from "@mui/material";
import { makeStyles } from '@mui/styles';

const ReservationsHistory:React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const currentUser = useAppSelector(selectCurrentUser);
  const reservations = useAppSelector(selectReservations);
  const total = useAppSelector(selectTotal);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);

  const [tab, setTab] = useState<ReservationStatus>(ReservationStatus.Pending);

  const handleChangeTab = (_: any, newTab: ReservationStatus) => {
    setTab(newTab);
  };

  const handleChangePage = (_:any, nextPage:number) => {
    setIsLoading(true);
    setPage(nextPage);
    dispatch(ReservationsAsync.fetchReservations({ size, page: nextPage, statuses: [tab] }))
      .unwrap()
      .finally(() => setIsLoading(false));
  }

  const handleChangeLimit = (event: any) => {
    setIsLoading(true);
    const { value:size } = event.target;
    setSize(size);
    setPage(0);
    dispatch(ReservationsAsync.fetchReservations({ size, page: 0, statuses: [tab] }))
      .unwrap()
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(ReservationsAsync.fetchReservations({ statuses: [tab], size }))
      .unwrap()
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [tab]);
 
  useEffect(() => {
    setIsLoading(true);
    dispatch(ReservationsAsync.fetchReservations({ size, page: 0, statuses: [tab] }))
      .unwrap()
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <Box className={classes.page}>
      <div className="container">
        <Title>Reservations</Title>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: 'max-content', mt: 2 }}>
          <Tabs
            variant="scrollable"
            value={tab}
            onChange={handleChangeTab}
            aria-label="reservations status tabs"
          >
            {Object.keys(ReservationStatus).map((key: string) => (
              <Tab sx={{ fontSize: { xs: '12px', sm: '14px' } }} key={key} value={ReservationStatus[key as keyof typeof ReservationStatus]} label={key} />
            ))}
          </Tabs>
        </Box>
        {isLoading ? <Loader /> : (
          !!total ? (
            <Box className={classes.list} sx={{ mt: 3 }}>
              <Box className={classes.items}>
                {reservations?.map((reservation: IReservation) => (
                  <ReservationCard key={reservation.id} reservation={reservation} />
                ))}
              </Box>
              <Box className={classes.pagination}>
                <TablePagination
                  component="div"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={size}
                  onRowsPerPageChange={handleChangeLimit}
                  labelRowsPerPage="Items per page"
                  rowsPerPageOptions={[20, 50, 100]}
                />
              </Box>
            </Box>
          ) : <NoData />
        )}
      </div>
    </Box>
  );
}

export default ReservationsHistory;

const useStyles = makeStyles({
  page: {
    height: 'calc(100vh - 266px)',
    backgroundColor: '#F5F6F7',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
      height: 'calc(100vh - 236px)',
    },
  },
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 462px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 503px)',
    },
  },
  pagination: {
    boxShadow: '0px 0px 0px 1px #E0E0E0'
  }
});
