import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';

export default class ChatsAsync {
  private static _url:string = '/client/chats';
  private static _http:HttpClient = new HttpClient();

  // Fetch chats messages
  public static fetchChatsMessages = createAsyncThunk('chats/fetchChatsMessages', async (params:any, thunkApi) => {
    const { chatId, ...nextParams } = params;
    const response:Response = await this._http.get(`${this._url}/${chatId}/messages`, nextParams);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Refetch chats messages
  public static refetchChatsMessages = createAsyncThunk('chats/refetchChatsMessages', async (params:any, thunkApi) => {
    const { chatId, ...nextParams } = params;
    const response:Response = await this._http.get(`${this._url}/${chatId}/messages`, nextParams);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });
  
  // Fetch chats
  public static fetchChats = createAsyncThunk('chats/fetchChats', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(this._url, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Refetch notifications
  public static refetchChats = createAsyncThunk('chats/refetchChats', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(this._url, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch chat by id
  public static fetchChatById = createAsyncThunk('chats/fetchChatById', async (chatId:number, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/${chatId}`);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Leave chat
  public static leaveChat = createAsyncThunk('chats/leaveChat', async (chatId:number) => {
    await this._http.put(`${this._url}/${chatId}/actions/leave`, null);
    return chatId;
  });

}
