import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from 'hooks/useDialog';
// Async
import UsersAsync from 'store/users/usersAsync';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
// MUI
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Typography } from '@mui/material';
// Components
import Title from 'components/Title';
import SignInForm from 'components/SignIn.form';
import { getDomain } from 'utilities/Utilities';

const UnsubscribePage:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const { email, token }: any = search.slice(1).split('&').reduce((acc, cur) => {
    const [ name, value ] = cur.split('=');
    return { ...acc, [name]: value  }
  }, {});

  const currentUser = useAppSelector(selectCurrentUser);
  const [loading, setLoading] = useState<boolean>(false);
  const [unsubscribed, setUnsubscribed] = useState<boolean>(false);

  const unsubscribe = () => {
    setLoading(true);
    dispatch(UsersAsync.unsubscribe(token))
      .unwrap()
      .then(() => setUnsubscribed(true))
      .finally(() => setLoading(false))
  }

  const { Dialog, openDialog, closeDialog } = useDialog();

  useEffect(() => {
    return () => { setUnsubscribed(false) };
  }, []);

  if (unsubscribed) return (
    <React.Fragment>
      <Dialog maxWidth="sm">
        <SignInForm onClose={closeDialog} />
      </Dialog>
      <Box className={classes.page} sx={{ padding: { xs: '16px', sm: '32px', lg: '64px 0' } }}>
        <Paper variant="outlined" className={[classes.content, 'container'].join(' ')}>
          <Typography sx={{
            fontSize: '20px', textAlign: 'center',
          }}>You have been successfully unsubscribed. You can always subscribe back by updating email preferences on our profile page.</Typography>
          
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              component={NavLink} to="/"
              variant="outlined"
            >Main page</Button>
            {currentUser ? (
              <Button
                component={NavLink} to="/profile"
                variant="contained"
              >Profile page</Button>
            ) : (
              <Button
                variant="contained"
                onClick={openDialog}
              >Sign in</Button>
            )}
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  )

  return (
    <Box className={classes.page} sx={{ padding: { xs: '16px', sm: '32px', lg: '64px 0' } }}>
      <Paper variant="outlined" className={[classes.content, 'container'].join(' ')}>
        <Title>Unsubscribe</Title>
        <Typography sx={{ textAlign: 'center' }}>{`Your email address ${email}`}</Typography>
        <Typography sx={{ textAlign: 'center' }}>{`Click unsubscribe button below to be excluded from ${getDomain()} email campaigns.`}</Typography>
        
        <LoadingButton
          loading={loading}
          onClick={unsubscribe}
          variant="contained"
          color="error"
        >Unsubscribe</LoadingButton>
      </Paper>
    </Box>
  );
}

export default UnsubscribePage;

const useStyles = makeStyles({
  page: {
    minHeight: 'calc(100vh - 266px)',
    backgroundColor: '#F5F6F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width: 1240px)': {
      minHeight: 'calc(100vh - 236px)',
    },
  },
  content: {
    maxWidth: '600px',
    padding: '40px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
  },
});
