import React, { useEffect, useState } from "react";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from "hooks/useDialog";
// types
import TimeshareStatus from "types/TimeshareStatus";
import TimeshareTypes from "types/TimeshareTypes";
// store
import TimesharesAsync from "store/timeshares/timesharesAsync";
import { selectIsMyTimeshares, selectMyTimesharesTotal, selectMyTimesharesParams } from "store/timeshares/timesharesSelectors";
// components
import MyTimeshareCard from "./MyTimeshareCard";
import Title from "components/Title";
import { Loader, NoData } from "components/Utilities";
import TimesharesForm from "./MyTimeshares.form";
// mui
import { Box, Button, Tab, TablePagination, Tabs } from "@mui/material";
import { makeStyles } from '@mui/styles';
// Utilities
import { checkPage, getContent, getOptionsFromEnum, textFromCamelToNormalCase } from "utilities/Utilities";

type Props = {
  type: TimeshareTypes;
}

const MyTimeshares:React.FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const timeshares = useAppSelector(selectIsMyTimeshares) || [];
  const total = useAppSelector(selectMyTimesharesTotal);
  const params = useAppSelector(selectMyTimesharesParams);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);

  const [tab, setTab] = useState<TimeshareStatus>(params.statuses[0]);

  const handleChangeTab = (_: any, newTab: TimeshareStatus) => {
    setTab(newTab);
  };

  const handleChangePage = (_:any, nextPage:number) => {
    setIsLoading(true);
    setPage(nextPage);
    dispatch(TimesharesAsync.fetchMyTimeshares({ size, page: nextPage, statuses: [tab], type }))
      .unwrap()
      .finally(() => setIsLoading(false));
  }

  const handleChangeLimit = (event: any) => {
    setIsLoading(true);
    const { value:size } = event.target;
    setSize(size);
    setPage(0);
    dispatch(TimesharesAsync.fetchMyTimeshares({ size, page: 0, statuses: [tab], type }))
      .unwrap()
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(TimesharesAsync.fetchMyTimeshares({ statuses: [tab], size, type }))
      .unwrap()
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [tab]);
  
  useEffect(() => {
    if (timeshares && !timeshares.length && !!total) {
      const newPage = checkPage(page, size, total);
      setPage(newPage);
      setIsLoading(true);
      dispatch(TimesharesAsync.fetchMyTimeshares({...params, page: newPage, type }))
        .unwrap()
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line
  }, [timeshares]);

  const { Dialog, openDialog, closeDialog } = useDialog();

  const isRentType = type === TimeshareTypes.Rent;

  return (
    <React.Fragment>
      <Dialog maxWidth="md">
        <TimesharesForm onClose={closeDialog} type={type} />
      </Dialog>

      <Box className={classes.page}>
        <div className="container">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'  }}>
            <Title>{`${textFromCamelToNormalCase(type)} ${getContent('labels').labelTimesharePluralText}`}</Title>
            <Button
              variant="contained"
              size="small"
              onClick={openDialog}
              sx={{ display: { sm: 'none' } }}
            >
              {`Create ${getContent('labels').labelTimeshareSingularText}`}
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2  }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: 'max-content' }}>
              <Tabs
                variant="scrollable"
                value={tab}
                onChange={handleChangeTab}
                aria-label="property timeshares status tabs"
              >
                {getOptionsFromEnum(TimeshareStatus).filter(s => isRentType ? s.value !== TimeshareStatus.Sold : true).map(status => (
                  <Tab sx={{ fontSize: { xs: '12px', sm: '14px' } }} key={status.value} value={status.value} label={status.label} />
                ))}
              </Tabs>
            </Box>
            <Button
              variant="contained"
              size="small"
              onClick={openDialog}
              sx={{ mb: 2, display: { xs: 'none', sm: 'inline-flex' } }}
            >
              {`Create ${getContent('labels').labelTimeshareSingularText}`}
            </Button>
          </Box>
          {isLoading ? <Loader /> : (
            !!total ? (
              <Box className={classes.list} sx={{ mt: 2 }}>
                <Box className={classes.items}>
                  {timeshares?.map(timeshare => (
                    <MyTimeshareCard key={timeshare.id} timeshare={timeshare} />
                  ))}
                </Box>
                <Box className={classes.pagination}>
                  <TablePagination
                    component="div"
                    count={total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={size}
                    onRowsPerPageChange={handleChangeLimit}
                    labelRowsPerPage="Items per page"
                    rowsPerPageOptions={[20, 50, 100]}
                  />
                </Box>
              </Box>
            ) : <NoData />
          )}
        </div>
      </Box>
    </React.Fragment>
  );
}

export default MyTimeshares;

const useStyles = makeStyles({
  page: {
    height: 'calc(100vh - 266px)',
    backgroundColor: '#F5F6F7',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
      height: 'calc(100vh - 236px)',
    },
  },
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 463px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 495px)',
    },
  },
  pagination: {
    boxShadow: '0px 0px 0px 1px #E0E0E0'
  },
});
