import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import { useAppDispatch } from 'hooks/redux';
// Async
import ChatsAsync from 'store/chats/chatsAsync';
// Models
import { IChat } from 'models/Chat';
// MUI
import { makeStyles } from '@mui/styles';
import {
  Card, Grid, IconButton, Typography, Box, Tooltip
} from '@mui/material';
import {
  LogoutOutlined as LogoutOutlinedIcon,
} from '@mui/icons-material';
// Components
import ConfirmLeaveChat from './ConfirmLeaveChat';

type Props = {
  chat: IChat,
};

const ChatCard:React.FC<Props> = ({ chat }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const handleOpenConfirmModal = (e: any) => {
    e.stopPropagation();
    setOpenConfirmModal(prev => !prev);
  }

  const leaveChat = () => {
    dispatch(ChatsAsync.leaveChat(chat.id));
  }

  return (
    <React.Fragment>
      <ConfirmLeaveChat open={openConfirmModal} onClose={handleOpenConfirmModal} handleRemove={leaveChat} chatName={chat.name} />

      <Card
        className={classes.card}
        sx={{ background: '#fff' }}
        onClick={() => navigate(`${chat.id}`)}
      >
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography className={classes.title}>
              {chat.name}
              <span className={classes.text} style={{ paddingLeft: '5px' }}>{`(${chat.participants.length} participants)`}</span>
            </Typography>
            <Typography className={classes.text}>{chat.lastMessage ? chat.lastMessage : 'No message(s)'}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 0.5 }}>
              <IconButton
                color="error"
                onClick={handleOpenConfirmModal}
              >
                <Tooltip title="Leave">
                  <LogoutOutlinedIcon />
                </Tooltip>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}

export default ChatCard;

const useStyles = makeStyles({
  card: {
    width: '100%',
    padding: '16px',
    borderRadius: 0,
    borderBottom: '1px solid #eee',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#fff',
    }
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    maxWidth: '50%',
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    color: 'rgba(0, 0, 0, 0.6)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
});
