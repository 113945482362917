import { FC, useRef, useEffect, ChangeEvent } from 'react';
// Types
import AssetType from 'types/AssetType';
import AssetContractType from 'types/AssetContractType';
// Mui
import { Button } from '@mui/material';

type Props = {
  type: AssetType;
  contractType: AssetContractType | undefined;
  multiple: boolean;
  onChange: (files:FileList) => void;
}

const AssetsFilePicker:FC<Props> = ({ type, contractType, multiple, onChange }) => {
  const id = `assets-${type}${contractType ? `-${contractType}` : ''}picker`
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line
      if ( inputRef.current ) inputRef.current.value = '';
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    if ( event.target.files ) onChange(event.target.files)
  }

  const accept = type === AssetType.Images ? '.jpg,.jpeg,.png,.avif' : 'images/*';

  return (
    <label htmlFor={id}>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        id={id}
        accept={accept}
        multiple={multiple}
        onChange={handleChange}
      />
      <Button variant="contained" component="span">
        Upload
      </Button>
    </label>
  )
}

export default AssetsFilePicker;
