import React from 'react';
// Hooks
import useScrollIntoView from 'hooks/useScrollIntoView';
// Types
import Domain from 'types/Domain';
// Components
import ListYourPropertyBenefits from './ListYourPropertyBenefits';
import ListYourPropertyHowWorks from './ListYourPropertyHowWorks';
import ListYourPropertyForm from './ListYourPropertyForm';
// Mui
import { makeStyles } from '@mui/styles';
import { Box, Typography, Button } from '@mui/material';
// Utilities
import { getContent, getDomain } from 'utilities/Utilities';
import SubscriptionPackages from './SubscriptionPackages';

const ListYourPropertyPage:React.FC = () => {
  const classes = useStyles();
  const { scrollTo } = useScrollIntoView();

  const { banner, benefits, howItWorks } = getContent('listYourPropertyPage');

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const domain = getDomain();

  return (
    <React.Fragment>
      <Box className={[classes.banner, classes.mainBlock].join(' ')}>
        <div className="container" style={{ height: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '48px', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box>
              <Typography className={classes.title}>{banner.title}</Typography>
              <Typography className={classes.text} sx={{ pt: 5 }}>
                {banner.text}
              </Typography>
              <Typography className={classes.text} sx={{ pt: 2 }}>
                {banner.hint}
              </Typography>
              {banner.button && (
                <Button
                  className={classes.button}
                  sx={{ mt: 5 }}
                  variant="outlined"
                  onClick={() => scrollTo('how-it-works')}
                >
                  {banner.button}
                </Button>
              )}
            </Box>
            {domain === Domain.Dashweek && <ListYourPropertyForm />}
          </Box>
          {domain === Domain.Corevote && <SubscriptionPackages />}
        </div>
      </Box>

      <ListYourPropertyBenefits title={benefits.title} items={benefits.items} />
      {howItWorks && <ListYourPropertyHowWorks />}

      <Box className={classes.block}>
        <div className="container">
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'center' }}>
            <Typography sx={{ fontSize: { xs: '24px', md: '48px' }, fontWeight: '500', color: '#fff' }}>
              Ready to list your timeshare?
            </Typography>
            <Button
              onClick={scrollToTop}
              variant="contained"
              className={classes.signUpButton}
            >
              Sign up
            </Button>
          </Box>
        </div>
      </Box>
    </React.Fragment>
  );
}

export default ListYourPropertyPage;

const useStyles = makeStyles({
  banner: {
    backgroundImage: 'url("/img/listYourPropertyBg.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
  },
  mainBlock: {
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '80px 0',
    },
  },
  block: {
    background: `linear-gradient(315deg, ${getContent('theme').secondaryColor} 0%, ${getContent('theme').primaryColor} 100%)`,
    padding: '24px 16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '64px',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '40px',
    lineHeight: '120%',
    letterSpacing: '-1px',
    color: '#fff',
    '@media (min-width: 1104px)': {
      fontSize: '60px',
    }
  },
  text: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    color: '#fff',
    textAlign: 'justify'
  },
  button: {
    color: '#fff',
    borderColor: '#fff',
    transition: 'background-color 0.3s',
    '&:hover': {
      borderColor: '#fff',
      backgroundColor: 'rgba(255,255,255,0.08)',
    }
  },
  signUpButton: {
    width: '240px',
    color: getContent('theme').primaryColor,
    background: '#fff',
    transition: 'background 0.3s',
    '&:hover': {
      background: 'rgba(255,255,255,0.9)',
    }
  }
});
