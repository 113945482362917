import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';

export default class LandingPagesAsync {
  private static _url:string = '/client/landingPages';
  private static _http:HttpClient = new HttpClient();

  // Fetch landing page
  public static fetchLandingPage = createAsyncThunk('landingPages/fetchLandingPage', async (landingPageId: number, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/${landingPageId}`);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });
}
