// Mui
import { createTheme } from '@mui/material/styles';

const theme = (theme: any) => {
  const { primaryColor, primaryColorRgb, secondaryColor } = theme;

  return createTheme({
    typography: {
      fontFamily: [
        'Work Sans',
        'sans-serif',
        '-apple-system'
      ].join(',')
    },
    palette: {
      primary: {
        main: 'rgba(0, 0, 0, 0.87)',
      },
      secondary: {
        main: primaryColor,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: 600,
            textTransform: 'capitalize'
          },
          contained: {
            background: `linear-gradient(315deg, ${secondaryColor} 0%, ${primaryColor} 100%)`,
            color: 'white',
            transition: 'initial',
            '&:hover': {
              background: primaryColor
            },
            '&:disabled': {
              background: 'rgba(0,0,0,0.12)'
            }
          },
          outlined: {
            color: primaryColor,
            border: `1px solid rgba(${primaryColorRgb}, 0.5)`,
            transition: 'initial',
            '&:hover': {
              backgroundColor: `rgba(${primaryColorRgb}, 0.1)`,
              borderColor: `rgba(${primaryColorRgb}, 0.5)`,
            }
          },
          text: {
            color: primaryColor,
          }
        },
        variants: [
          {
            props: { color: 'error' },
            style: {
              background: `linear-gradient(315deg, #d32f2f 0%, #e52424 100%)`,
              color: 'white',
              transition: 'initial',
              '&:hover': {
                background: '#e52424',
              },
              '&:disabled': {
                background: 'rgba(0,0,0,0.12)'
              }
            }
          },
          {
            props: { variant: 'contained', color: 'secondary' },
            style: {
              background: `#fff`,
              color: primaryColor,
              transition: 'ease-in-out 0.3s',
              '&:hover': {
                background: '#fff',
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
              },
              '&:disabled': {
                background: 'rgba(0,0,0,0.12)'
              }
            }
          }
        ]
      },
      MuiIconButton: {
        variants: [
          {
            props: { color: 'primary' },
            style: {
              padding: '7px',
              background: `linear-gradient(315deg, ${secondaryColor} 0%, ${primaryColor} 100%)`,
              color: '#fff',
              transition: 'initial',
              '&:hover': {
                background: primaryColor
              },
              '&:disabled': {
                borderColor: 'rgba(0,0,0,0.12)'
              }
            }
          },
          {
            props: { color: 'secondary' },
            style: {
              padding: '7px',
              color: 'rgba(0, 0, 0, 0.87)',
              transition: 'initial',
              '&:hover': {
                backgroundColor: `rgba(${primaryColorRgb}, 0.1)`
              },
              '&:disabled': {
                borderColor: 'rgba(0,0,0,0.12)'
              }
            }
          },
          {
            props: { color: 'error' },
            style: {
              border: `1px solid #d32f2f`,
              padding: '7px',
              '&:disabled': {
                borderColor: 'rgba(0,0,0,0.12)'
              }
            }
          },
        ],
        styleOverrides: {
          root: {
            borderRadius: '4px'
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            backgroundColor: secondaryColor,
          },
        }
      }
    },
  })
};

export default theme;
