import React, { Fragment, useState } from 'react';
import dayjs from 'dayjs';
// Hooks
import { useAppSelector } from 'hooks/redux';
import useDialog from 'hooks/useDialog';
// Models
import IReservation from 'models/Reservation';
// Types
import ReservationStatus from 'types/ReservationStatus';
import UserRoles from 'types/UserRoles';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
import { selectAccountsSettings } from 'store/accounts/accountsSelectors';
// MUI
import { makeStyles } from '@mui/styles';
import {
  Box, Card, Chip, Collapse, Divider, Grid, Button,
  IconButton, Tooltip, Typography
} from '@mui/material';
import {
  DoneAll as DoneAllIcon,
  Done as DoneIcon,
  Close as CloseIcon,
  PaidOutlined as PaidOutlinedIcon
} from '@mui/icons-material';
// Components
import Collapsable from 'components/Collapsable';
import PaymentForms from 'components/PaymentForms';
import { GridBlock } from 'components/Utilities';
// Utilities
import { formatStartEndDate } from 'utilities/DateTimeFormatter';
import { formatCash } from 'utilities/Utilities';

type Props = {
  reservation: IReservation,
};

const ReservationCard:React.FC<Props> = ({ reservation }) => {
  const classes = useStyles();

  const currentUser = useAppSelector(selectCurrentUser);
  const accountsSettings = useAppSelector(selectAccountsSettings);

  // handle extended
  const [ extended, setExtended ] = useState<boolean>(false);

  const toggleExtended = () => {
    setExtended(!extended);
  };

  const { Dialog, openDialog, closeDialog } = useDialog();

  const paymentEnabled = accountsSettings.paymentEnabled && !!accountsSettings.paymentProvider;

  return (
    <React.Fragment>
      <Dialog maxWidth="sm">
        <PaymentForms reservation={reservation} onClose={closeDialog} />
      </Dialog>
      <Card sx={{ p: 2, borderRadius: 0, backgroundColor: extended ? 'rgba(0, 0, 0, 0.04)' : '#fff', borderBottom: '1px solid #eee', width: '100%' }}>
        <Collapsable onClick={toggleExtended}>
          <Grid container spacing={2}>
            <GridBlock
              xs={9} md={3}
              sx={{ order: -1 }}
              text={reservation.propertyName}
              title={`Created: ${dayjs(reservation.creationDate).format('MMMM DD YYYY')}`}
              titleReverse={true}
              rowEnabled={false}
            />
            <Grid item xs={12} sm={6} md={3} alignSelf="center">
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.title} sx={{ display: {xs: 'block', sm: 'none'} }}>Date range</Typography>
                <Typography className={classes.text}>
                  {formatStartEndDate(reservation.start, reservation.end)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: {xs: 2, sm: 0} }}>
                <Typography className={classes.title} sx={{ display: {xs: 'block', sm: 'none'} }}>Status</Typography>
                <Box>
                  {reservation.status === ReservationStatus.Completed && <Chip color={'success'} size="small" label="Completed" icon={<DoneAllIcon />} />}
                  {reservation.status === ReservationStatus.Pending && <Chip color={'info'} size="small" label="Pending" icon={<DoneIcon />} />}
                  {reservation.status === ReservationStatus.Cancelled && <Chip color={'error'} size="small" label="Rejected" icon={<CloseIcon />} />}
                </Box>
              </Box>
            </Grid>
            <GridBlock
              xs={12} sm={6} md={2}
              title="Price"
              text={formatCash(reservation.price)}
            />
            <GridBlock
              xs={12} sm={6} md={2}
              title="Reservation number"
              text={reservation.number || '-'}
            />
            <Grid item xs={3} md={2} alignSelf="center" sx={{ display: 'flex', justifyContent: 'flex-end', alignSelf: 'flex-start', order: { xs: -1, md: 0 } }}>
              {paymentEnabled && reservation.status !== ReservationStatus.Cancelled && currentUser?.activeRole === UserRoles.Guest && !reservation.guestPaymentDate && reservation.guestPaymentEnabled && (
                <Fragment>
                  <Button
                    sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                    size="small"
                    onClick={openDialog}
                    variant="contained"
                    startIcon={<PaidOutlinedIcon />}
                  >
                    {`Pay ${formatCash(reservation.price)}`}
                  </Button>
                  <Tooltip title={`Pay ${formatCash(reservation.price)}`} sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
                    <IconButton onClick={openDialog} color="primary">
                      <PaidOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Fragment>
              )}
              {reservation.guestPaymentDate && currentUser?.activeRole === UserRoles.Guest && (
                <Chip label="Paid" color="info" />
              )}
            </Grid>
          </Grid>
        </Collapsable>
        <Collapse in={extended}>
          <Divider sx={{ p: 1 }} />
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <GridBlock
              xs={12}
              title="Notes"
              text={reservation.notes || '-'}
              rowEnabled={false}
            />
          </Grid>
        </Collapse>
      </Card>
    </React.Fragment>
  );
}

export default ReservationCard;

const useStyles = makeStyles({
  title: {
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  }
});
