import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpClient from 'utilities/HttpClient';

export default class AccountsAsync {
  private static _url:string = '/client/accounts';
  private static _http:HttpClient = new HttpClient();

  // Fetch accounts settings
  public static fetchAccountsSetting = createAsyncThunk('accounts/fetchAccountsSetting', async (_:any, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/current/settings`);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return await response.json();
  });

   // Get by Domain
   public static getByDomain = createAsyncThunk('accounts/getByDomain', async (domain:string, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/getByDomain?domain=${domain}`);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return await response.json();
  });
}
