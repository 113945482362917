import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import INotification from "models/Notification";
// Models
// Async
import NotificationsAsync from "./notificationsAsync";

interface IState {
  notifications: INotification[] | null;
  total: number;
  selectedNotificationsIds: number[];
}

const initialState:IState = {
  notifications: null,
  total: 0,
  selectedNotificationsIds: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action:PayloadAction<INotification>) => {
      state.notifications = state.notifications
        ? state.notifications.length > 50 && state.notifications.length < state.total
          ? [action.payload, ...state.notifications.slice(1)]
          : [action.payload, ...state.notifications]
        : [action.payload]
      state.total = state.total + 1;
    },
    selectNotification: (state, action:PayloadAction<number>) => {
      state.selectedNotificationsIds = state.selectedNotificationsIds.includes(action.payload)
        ? state.selectedNotificationsIds.filter((notificationId: number) => notificationId !== action.payload)
        : [...state.selectedNotificationsIds, action.payload];
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch notifications
      .addCase(NotificationsAsync.fetchNotifications.pending, (state) => {
        state.notifications = null;
        state.total = 0;
      })
      .addCase(NotificationsAsync.fetchNotifications.fulfilled, (state, action:PayloadAction<any>) => {
        state.notifications = action.payload.data;
        state.total = action.payload.total;
      })
      // Refetch notifications
      .addCase(NotificationsAsync.refetchNotifications.fulfilled, (state, action:PayloadAction<any>) => {
        state.notifications = state.notifications ? [...state.notifications, ...action.payload.data, ] : action.payload.data;
      })
      // Delete notifications
      .addCase(NotificationsAsync.deleteNotifications.fulfilled, (state, action:PayloadAction<any>) => {
        state.notifications = state.notifications
          ? !!action.payload.length
            ? state.notifications.filter((notification: INotification) => !action.payload.includes(notification.id))
            : []
          : state.notifications;
        state.total = !!action.payload.length ? state.total - action.payload.length : 0;
      })
      // Mark As Read
      .addCase(NotificationsAsync.markAsReadNotifications.fulfilled, (state, action:PayloadAction<any>) => {
        state.notifications = state.notifications
          ? !!action.payload.length
            ? state.notifications.map((notification: INotification) => action.payload.includes(notification.id) ? { ...notification, read: true } : notification)
            : state.notifications.map((notification: INotification) => ({ ...notification, read: true }))
          : state.notifications;
      })
  }
});

export const NotificationsActions = notificationsSlice.actions;

export default notificationsSlice.reducer;
