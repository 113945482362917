import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
// Hooks
import { useAppDispatch } from 'hooks/redux';
// Async
import SubscriptionsAsync from 'store/subscriptions/subscriptionsAsync';
import UsersAsync from 'store/users/usersAsync';
// Actions
import { UiActions } from 'store/ui/uiSlice';
import { AuthActions } from 'store/auth/authSlice';
// Services
import StorageService from 'services/StorageService';
// MUI
import { IconButton, Paper, FormHelperText } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

type Props = {
  onClose: () => void;
  applicationId: string;
  locationId: string;
  selectedSubscriptionPackageId: number | null;
  expired?: boolean;
}

const SquarePayment:React.FC<Props> = ({ onClose, applicationId, locationId, selectedSubscriptionPackageId, expired = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const tokenize = (token: any) => {
    setLoading(true);
    const data: any = { cardNonce: token.token };
    if (selectedSubscriptionPackageId) data['subscriptionPackageId'] = selectedSubscriptionPackageId;
    dispatch(SubscriptionsAsync.createSubscription(data))
      .unwrap()
      .then(() => {
        dispatch(UsersAsync.fetchMe({}))
          .unwrap()
          .then(() => {
            dispatch(AuthActions.setAuthenticated(true))
            dispatch(UiActions.enqueueSnackbar({ message: 'Subscription was successfully activated' }))
            navigate('/subscriptions');
          })
        onClose();
      })
      .finally(() => setLoading(false))
  }

  const handleOnClose = () => {
    StorageService.removeCredential();
    onClose();
  }

  return (
    <Paper variant="elevation" elevation={6} sx={{ position: 'relative', padding: '70px 40px 40px' }}>
      <IconButton onClick={handleOnClose} sx={{ position: 'absolute', top: '16px', right: '16px' }}>
        <CloseIcon />
      </IconButton>
      <FormHelperText sx={{ pb: 2, textAlign: 'center' }}>
        {`${expired ? 'Your subscription trial period has been expired. ' : ''}Please provide your billing information`} 
      </FormHelperText>
      <PaymentForm
        applicationId={applicationId}
        cardTokenizeResponseReceived={tokenize}
        locationId={locationId}
      >
        <CreditCard
          buttonProps={{
            isLoading: loading,
          }}
        >Proceed</CreditCard>
      </PaymentForm>
    </Paper>
  );
}

export default SquarePayment;
