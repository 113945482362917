import React from 'react';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Actions
import { PaymentsActions } from 'store/payments/paymentsSlice';
// Selectors
import { selectErrorMessage } from 'store/payments/paymentsSelectors';
// MUI
import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

type Props = {
  onClose: () => void;
}

const PaymentRejected:React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const errorMessage = useAppSelector(selectErrorMessage);

  const backToPayment = () => {
    dispatch(PaymentsActions.setInitialStatus());
  }

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', p: { xs: 2, md: 4 }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <ErrorOutlineIcon sx={{ fontSize: '30px', color: '#ff3333' }} />
        <Typography className={classes.title}>Payment rejected!</Typography>
      </Box>
      <Typography className={classes.subtitle}>{errorMessage}</Typography>
      <Box sx={{ pt: 4, display: 'flex', gap: 1.5 }}>
        <Button
          variant="outlined"
          onClick={onClose}
        >Close</Button>
        <Button
          variant="contained"
          onClick={backToPayment}
        >Try again</Button>
      </Box>
    </Box>

  );
}

export default PaymentRejected;

const useStyles = makeStyles({
  title: {
    fontSize: '24px',
    color: '#ff3333',
  },
  subtitle: {
    paddingTop: '24px',
    fontSize: '18px',
    fontWeight: 500,
  },
});
