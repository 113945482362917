import { FC, Fragment } from 'react';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Models
import { RootState } from 'store';
// Types
import AssetType from 'types/AssetType';
import AssetContractType from 'types/AssetContractType';
// Actions
import { AssetsActions, IFile } from 'store/assets/assetsSlice';
// Selectors
import { selectFiles } from 'store/assets/assetsSelectors';
// Mui
import { Box, IconButton, Tooltip } from '@mui/material';
// Icons
import { Delete as DeleteIcon } from '@mui/icons-material';


type Props = {
  file: IFile;
  type: AssetType;
  contractType?: AssetContractType;
}

const AssetsListFileItem:FC<Props> = ({ file, type, contractType }) => {
  const dispatch = useAppDispatch();

  const isImage = file.file.type.includes('image');
  const href:string = URL.createObjectURL(file.file);

  const files = useAppSelector((state:RootState) => selectFiles(state, { type, contractType }));

  const handleClick = () => {
    const nextFiles = files.filter((f:IFile) => f.uuid !== file.uuid);
    dispatch(AssetsActions.setFiles({ type, contractType, files: nextFiles }));
  }

  const deleteButton = (
    <Tooltip title="Delete">
      <IconButton
        sx={{
          p: 0.5,
          bgcolor: type === AssetType.Images ? 'rgba(211, 47, 47, 0.5)' : 'none',
          color: type === AssetType.Images ? '#fff' : '#d32f2f',
          '&:hover': {
            bgcolor: type === AssetType.Images ? '#d32f2f' : 'none',
          }
        }}
        onClick={handleClick}
      ><DeleteIcon /></IconButton>
    </Tooltip>
  );

  return (
    <Fragment>
      {isImage && type !== AssetType.Contracts ? (
        <Box sx={{ position: 'relative', width: 160, height: 'max-content', bgcolor: '#ededed' }}>
          <img src={href} alt={file.file.name} style={{ width: '100%', height: '160px', objectFit: 'contain' }} />
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            {deleteButton}
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <a href={href} target="_blank" rel="noreferrer" style={{ color: '#48A8D0', textDecoration: 'none' }}>{file.file.name}</a>
          </Box>
          <Box>
            {deleteButton}
          </Box>
        </Box>
      )}
    </Fragment>
  )
}

export default AssetsListFileItem;
