import { FC, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
// Hooks
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useMenu from 'hooks/useMenu';
// Async
import UsersAsync from 'store/users/usersAsync';
// Actions
import { AuthActions } from 'store/auth/authSlice';
import { UsersActions } from 'store/users/usersSlice';
// Selectors
import { selectCurrentUser } from 'store/users/usersSelectors';
import { selectIsUnreadMessages } from 'store/messages/messagesSelectors';
import { selectIsUnreadNotifications } from 'store/notifications/notificationsSelectors';
import { selectAccountsSettings, selectGuestModuleEnabled, selectSubscriptionModuleEnabled } from 'store/accounts/accountsSelectors';
// Types
import UserRoles from 'types/UserRoles';
// Services
import StorageService from 'services/StorageService';
// Mui
import { Box, IconButton, ListItemIcon, Divider, Badge, Avatar, Chip } from '@mui/material';
import {
  Logout as LogoutIcon,
  PersonOutline as PersonOutlineIcon,
  ArrowDropDown as ArrowDropdownIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
  HolidayVillageOutlined as HolidayVillageOutlinedIcon,
  ForumOutlined as ForumOutlinedIcon,
  ScheduleOutlined as ScheduleOutlinedIcon,
  MessageOutlined as MessageOutlinedIcon,
  CompareArrowsOutlined as CompareArrowsOutlinedIcon,
  SupportAgentOutlined as SupportAgentOutlinedIcon,
  PaymentsOutlined as PaymentsOutlinedIcon,
  PollOutlined as PollOutlinedIcon,
  LoyaltyOutlined as LoyaltyOutlinedIcon,
  PendingActionsOutlined as PendingActionsOutlinedIcon,
} from '@mui/icons-material';
// Utilities
import { getContent } from 'utilities/Utilities';

const UserMenu:FC = () => {
  const dispatch = useAppDispatch();

  const accountsSettings = useAppSelector(selectAccountsSettings);
  const subscriptionModuleEnabled = useAppSelector(selectSubscriptionModuleEnabled);
  const guestModuleEnabled = useAppSelector(selectGuestModuleEnabled);
  const currentUser = useAppSelector(selectCurrentUser);
  const unreadMessages = useAppSelector(selectIsUnreadMessages);
  const unreadNotifications = useAppSelector(selectIsUnreadNotifications);

  const nextRole = currentUser?.activeRole === UserRoles.Owner ? UserRoles.Guest : UserRoles.Owner;
  const nextRoleLabel = currentUser?.activeRole === UserRoles.Owner ? getContent('labels').labelGuestSingularText : getContent('labels').labelOwnerSingularText;

  const signOut = () => {
    StorageService.removeCredential();
    dispatch(AuthActions.setAuthenticated(false));
    dispatch(UsersActions.removeCurrentUser());
  }

  const switchRole = () => {
    dispatch(UsersAsync.switchRole(nextRole));
  }

  const { Menu, MenuItem, openMenu } = useMenu();

  if ( !currentUser ) return null;

  return (
    <Fragment>
      <Box>
        <IconButton color="secondary" component={NavLink} to="notifications">
          <Badge color="secondary" variant={unreadNotifications ? 'dot' : 'standard'}>
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
        {accountsSettings.chatModuleEnabled && (
          <IconButton color="secondary" component={NavLink} to="chats">
            {/* <Badge color="secondary" variant={unreadMessages ? 'dot' : 'standard'}> */}
            <MessageOutlinedIcon />
          </IconButton>
        )}
        <IconButton
          color="secondary"
          sx={{
            display: {
              md: 'none'
            },
          }}
          onClick={openMenu}
        >
          <Badge color="secondary" variant={unreadMessages ? 'dot' : 'standard'}>
            <PersonOutlineIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Box>
      <Chip
        variant="outlined"
        color="secondary"
        sx={{ cursor: 'pointer', display: { xs: 'none', md: 'inline-flex' } }}
        avatar={(
          <Avatar
            src={currentUser.image ? currentUser.image.url : ''}
          >
            {currentUser.image ? '' : currentUser.firstName[0]}
          </Avatar>
        )}
        label={currentUser.firstName}
        deleteIcon={<ArrowDropdownIcon />}
        onClick={openMenu}
        onDelete={openMenu}
      />
      <Menu
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: '160px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem component={Link} to={`/profile`}>
          <ListItemIcon>
            <PersonOutlineIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <Divider />
        {currentUser.activeRole === UserRoles.Owner && subscriptionModuleEnabled && [
          <MenuItem component={Link} to={`/subscriptions`} key="subscriptions">
            <ListItemIcon>
              <PaymentsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Subscriptions
          </MenuItem>,
          <MenuItem component={Link} to={`/benefits`}  key="benefits">
            <ListItemIcon>
              <LoyaltyOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Benefits
          </MenuItem>,
          <MenuItem component={Link} to={`/benefit-claims`}  key="benefit-claims">
            <ListItemIcon>
              <PendingActionsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Claimed benefits
          </MenuItem>
        ]}
        {accountsSettings.pollModuleEnabled && (
          <MenuItem component={Link} to={`/polls`}>
            <ListItemIcon>
              <PollOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Polls
          </MenuItem>
        )}
        {currentUser.activeRole === UserRoles.Owner ? (
          [
            <MenuItem key="my-rent-timeshares" component={Link} to={`/my-rent-timeshares`}>
              <ListItemIcon>
                <ScheduleOutlinedIcon fontSize="small" />
              </ListItemIcon>
              {`Rent ${getContent('labels').labelTimesharePluralText}`}
            </MenuItem>,
            <MenuItem key="my-sale-timeshares" component={Link} to={`/my-sale-timeshares`}>
              <ListItemIcon>
                <ScheduleOutlinedIcon fontSize="small" />
              </ListItemIcon>
              {`Sale ${getContent('labels').labelTimesharePluralText}`}
            </MenuItem>
          ]
        ) : (
          [
            <MenuItem key="reservation-requests" component={Link} to={`/reservation-requests`}>
              <ListItemIcon>
                <ForumOutlinedIcon fontSize="small" />
              </ListItemIcon>
              Reservation requests
            </MenuItem>,
            <MenuItem key="purchase-inquiries" component={Link} to={`/purchase-inquiries`}>
              <ListItemIcon>
                <ForumOutlinedIcon fontSize="small" />
              </ListItemIcon>
              Purchase inquiries
            </MenuItem>,
          ]
        )}
        {guestModuleEnabled && (
          <MenuItem component={Link} to={`/reservations`}>
            <ListItemIcon>
              <HolidayVillageOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Reservations
          </MenuItem>
        )}
        <MenuItem component={Link} to={`/helpdesk`}>
          <ListItemIcon>
            <Badge color="secondary" variant={unreadMessages ? 'dot' : 'standard'}>
              <SupportAgentOutlinedIcon fontSize="small" />
            </Badge>
          </ListItemIcon>
          Helpdesk
        </MenuItem>
        <Divider />
        {currentUser.roles.length > 1 && (
          <MenuItem onClick={switchRole}>
            <ListItemIcon>
              <CompareArrowsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            {`Switch to ${nextRoleLabel}`}
          </MenuItem>
        )}
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserMenu;
