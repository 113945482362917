import React from 'react';
import { Box, Menu, IconButton, Tooltip } from '@mui/material';

type Props = {
  addSmile: (smile: string) => void;
}

const Smiles: React.FC<Props> = ({ addSmile }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Smiles">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'smiles' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            &#128578;
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="smiles"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '600px',
            maxWidth: '60%',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mb: 2,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(50%) rotate(-45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >

        {smiles.map(smile => (
          <IconButton key={`smile-${smile}`} size="small" onClick={() => addSmile(smile)}>{smile}</IconButton>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default Smiles;

const smiles = [
  '😀', '😁', '😂', '😃', '😄', '😅', '😆', '😇', '😈', '😉', '😊', '😋', '😌', '😍', '😎', '😏',
  '😐', '😑', '😒', '😓', '😔', '😕', '😖', '😛', '😜', '😝', '😞', '😟', '😠', '😡', '😢', '😣',
  '😤', '😥', '😦', '😧', '😨', '😩', '😪', '😫', '😬', '😭', '😮', '😯', '😰', '😱', '😲', '😳',
  '😴', '😵', '😷', '🤒', '🙁', '🙂', '🙃', '🙄', '🤑', '🤔', '🤣', '🤤', '🤨', '🤩', '🤪', '🤭'
];
