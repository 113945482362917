import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';
import { getContent } from "utilities/Utilities";

const { accountId } = getContent('settings');

export default class CountriesAsync {
  private static _url:string = '/client/countries';
  private static _http:HttpClient = new HttpClient();

  // Fetch countries
  public static fetchCountries = createAsyncThunk('countries/fetchCountries', async (_, thunkApi) => {
    const response:Response = await this._http.get(this._url);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch country by slug
  public static fetchCountryBySlug = createAsyncThunk('countries/fetchCountryBySlug', async (params:any, thunkApi) => {
    const nextParams = { ...params, accountId };
    const response:Response = await this._http.get(`${this._url}/getByNameSlug`, nextParams);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });
}
