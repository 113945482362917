import React from 'react';
// Hooks
import { useAppSelector } from 'hooks/redux';
// Types
import { RootState } from 'store';
import AssetType from 'types/AssetType';
import AssetContractType from 'types/AssetContractType';
// Models
import IAsset from 'models/Asset';
import { IFile } from 'store/assets/assetsSlice';
// Selectors
import { selectAssets, selectFiles } from 'store/assets/assetsSelectors';
// Mui
import { Box } from '@mui/material';
// Components
import AssetsListAssetItem from './AssetsListAssetItem';
import AssetsListFileItem from './AssetsListFileItem';

type Props = {
  type: AssetType;
  contractType?: AssetContractType;
}

const AssetsList:React.FC<Props> = ({ type, contractType }) => {
  const files = useAppSelector((state:RootState) => selectFiles(state, { type, contractType }));
  const assets = useAppSelector((state:RootState) => selectAssets(state, { type, contractType }));

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: type === AssetType.Contracts ? 'column' : 'row', flexWrap: 'wrap', gap: type === AssetType.Contracts ? 0 : 2, mt: 1 }}>
        {files.map((file:IFile) => (
          <AssetsListFileItem
            key={`file-item-${file.uuid}`}
            file={file}
            type={type}
            contractType={contractType}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: type === AssetType.Contracts ? 'column' : 'row', flexWrap: 'wrap', gap: type === AssetType.Contracts ? 0 : 2, mt: 1 }}>
        {assets?.map((asset:IAsset) => (
          <AssetsListAssetItem
            key={`asset-item-${asset.id}`}
            asset={asset}
            type={type}
          />
        ))}
      </Box>
    </Box>
  )
}

export default AssetsList;
