import React, { Fragment, useState } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/redux';
import useDialog from 'hooks/useDialog';
import useMenu from 'hooks/useMenu';
// Async
import TimesharesAsync from 'store/timeshares/timesharesAsync';
import AssetsAsync from 'store/assets/assetsAsync';
// Models
import ITimeshare from 'models/Timeshare';
// Types
import TimeshareStatus from 'types/TimeshareStatus';
import TimeshareTypes from 'types/TimeshareTypes';
// MUI
import { makeStyles } from '@mui/styles';
import {
  Box, Card, Chip, Collapse, Divider, Grid, Tooltip,
  IconButton, Typography
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  EventAvailableOutlined as EventAvailableOutlinedIcon,
  EventBusyOutlined as EventBusyOutlinedIcon,
  LoopOutlined as LoopOutlinedIcon,
  ReportGmailerrorred as ReportGmailerrorredIcon,
  FilePresentOutlined as FilePresentOutlinedIcon,
} from '@mui/icons-material';
// Components
import Collapsable from 'components/Collapsable';
import MyTimesharesForm from './MyTimeshares.form';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import { GridBlock } from 'components/Utilities';
// Utilities
import { formatStartEndDate } from 'utilities/DateTimeFormatter';
import { formatCash, getContent, textFromCamelToNormalCase } from 'utilities/Utilities';

type Props = {
  timeshare: ITimeshare,
};

const MyTimeshareCard:React.FC<Props> = ({ timeshare }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const urlPhoto = timeshare.images ? timeshare.images[0].url : '/img/noPropertyFound.png';

  const { Menu, MenuItem, openMenu } = useMenu();
  const { Dialog, openDialog, closeDialog } = useDialog();

  // handle remove modal
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const handleOpenRemoveModal = () => setOpenRemoveModal(prev => !prev);

  // handle extended
  const [ extended, setExtended ] = useState<boolean>(false);
  const toggleExtended = () => setExtended(prev => !prev);

  const handleRemoveTimeshare = () => {
    dispatch(TimesharesAsync.deleteTimeshares(timeshare.id));
  }

  const openDocument = (assetId: number) => {
    dispatch(AssetsAsync.getAssetUrl(assetId));
  }

  const isRentTimeshare = timeshare.type === TimeshareTypes.Rent;

  return (
    <React.Fragment>
      <ConfirmDeleteModal
        open={openRemoveModal}
        onClose={handleOpenRemoveModal}
        handleRemove={handleRemoveTimeshare}
        title={getContent('labels').labelTimeshareSingularText}
      />

      <Dialog maxWidth="md">
        <MyTimesharesForm onClose={closeDialog} timeshare={timeshare} type={timeshare.type} />
      </Dialog>

      <Card sx={{ p: 2, borderRadius: 0, backgroundColor: extended ? 'rgba(0, 0, 0, 0.04)' : '#fff', borderBottom: '1px solid #eee', width: '100%' }}>
        <Collapsable onClick={toggleExtended}>
          <Grid container spacing={2}>
            <GridBlock
              xs={10} md={4}
              sx={{ order: { xs: -2, md: 0 } }}
              text={(
                <Fragment>
                  {!timeshare.verified && (
                    <Tooltip title="Is being checked">
                      <ReportGmailerrorredIcon />
                    </Tooltip>
                  )}
                  <Typography className={classes.text}>
                    {timeshare.property.name}
                  </Typography>
                </Fragment>
              )}
              textSx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              rowEnabled={false}
            />
            <Grid item xs={12} sm={6} md={5} alignSelf="center">
              {isRentTimeshare ? (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: {xs:2, sm:0} }}>
                  <Typography className={classes.title} sx={{ display: {xs: 'block', sm: 'none' } }}>
                    Date
                  </Typography>
                  <Typography className={classes.text}>
                    {timeshare.start && timeshare.end ? formatStartEndDate(timeshare.start, timeshare.end) : 'Flexible dates'}
                  </Typography>
                </Box>
              ) : null}
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.title} sx={{ display: {xs: 'block', sm: 'none' } }}>
                  Status
                </Typography>
                {timeshare.status === TimeshareStatus.Available && (
                  <Chip color={'success'} size="small" label="Available" icon={<EventAvailableOutlinedIcon />} />
                )}
                {timeshare.status === TimeshareStatus.Pending && (
                  <Chip color={'info'} size="small" label="Pending" icon={<LoopOutlinedIcon />} />
                )}
                {[TimeshareStatus.Unavailable, TimeshareStatus.Sold].includes(timeshare.status) && (
                  <Chip color={'error'} size="small" label={textFromCamelToNormalCase(timeshare.status)} icon={<EventBusyOutlinedIcon />} />
                )}
              </Box>
            </Grid>
            <GridBlock
              xs={12} sm={6} md={2}
              title="Price"
              text={formatCash(timeshare.netPrice)}
            />
            {timeshare.status === TimeshareStatus.Available && (
              <Grid item xs={2} md={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignSelf: 'center', order: { xs: -2, md: 0 } }}>
                <Tooltip title="Actions">
                  <IconButton onClick={openMenu}>
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
                <Menu>
                  <MenuItem onClick={openDialog}>{`Edit ${getContent('labels').labelTimeshareSingularText}`}</MenuItem>
                  <MenuItem onClick={handleOpenRemoveModal} sx={{ color: '#D32F2F' }}>{`Delete ${getContent('labels').labelTimeshareSingularText}`}</MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
        </Collapsable>
        <Collapse in={extended}>
          <Divider sx={{ p: 1 }} />
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <Grid item xs={12} sm={6} md={3}>
              <img src={urlPhoto} alt={'timeshare'} className={classes.img} />
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              <Grid container spacing={2}>
                <GridBlock
                  xs={12} sm={6} md={4}
                  title="Country / Region"
                  text={`${timeshare.property.country?.name || '-'} / ${timeshare.property.region?.name || '-'}`}
                />
                <GridBlock
                  xs={12} sm={6} md={4}
                  title="View"
                  text={timeshare.view}
                />
                <GridBlock
                  xs={12} sm={6} md={4}
                  title="Bedrooms / Bathrooms"
                  text={`${timeshare.bedrooms || '-'} / ${timeshare.bathrooms || '-'}`}
                />
                {!isRentTimeshare && (
                  <Fragment>
                    <GridBlock
                      xs={12} sm={6} md={4}
                      title="Week"
                      text={timeshare.week || 'Floating'}
                    />
                    <GridBlock
                      xs={12} sm={6} md={4}
                      title="Season"
                      text={timeshare.season ? textFromCamelToNormalCase(timeshare.season) : '-'}
                    />
                    <GridBlock
                      xs={12} sm={6} md={4}
                      title="Ownership type"
                      text={timeshare.ownershipType || '-'}
                    />
                    <GridBlock
                      xs={12} sm={6} md={4}
                      title="Usage"
                      text={timeshare.usage ? textFromCamelToNormalCase(timeshare.usage) : '-'}
                    />
                    <GridBlock
                      xs={12} sm={6} md={4}
                      title="Next usage year"
                      text={timeshare.nextUsageYear || '-'}
                    />
                    <GridBlock
                      xs={12} sm={6} md={4}
                      title="Maintenance fees"
                      text={timeshare.maintenanceFees || '-'}
                    />
                    <GridBlock
                      xs={12} sm={4}
                      title="Documents"
                      text={timeshare.ownerDocuments ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          {timeshare.ownerDocuments.map(document => (
                            <Tooltip key={document.id} title={document.name || ''} sx={{ width: 'max-content'}}>
                              <IconButton
                                aria-label={document.name}
                                onClick={() => openDocument(document.id)}
                              >
                                <FilePresentOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          ))}
                        </Box>
                      ) : '-'}
                      sx={{ alignSelf: 'flex-start' }}
                    />
                  </Fragment>
                )}
                <GridBlock
                  xs={12} md={isRentTimeshare ? 12 : 8}
                  sx={{ alignSelf: 'flex-start' }}
                  title="Notes"
                  text={timeshare.ownerNotes || '-'}
                  rowEnabled={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Card>
    </React.Fragment>
  );
}

export default MyTimeshareCard;

const useStyles = makeStyles({
  title: {
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }
});
