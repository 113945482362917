import React from 'react';
import { Card, Typography, Button } from '@mui/material';

type Props = {
  onClose: () => void,
  children: React.ReactNode,
};

const ConfirmCard:React.FC<Props> = ({ onClose, children }) => {
  return (
    <Card sx={{ padding: '32px 16px 40px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography sx={{ fontSize: '24px' }}>Check your email</Typography>
      <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>{children}</Typography>
      <Button
        sx={{ mt: 4 }}
        variant="contained"
        onClick={onClose}
      >
        OK
      </Button>
    </Card>
  );
}

export default ConfirmCard;
