import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Async
import UsersAsync from 'store/users/usersAsync';
// hooks
import { useAppDispatch } from 'hooks/redux';
// components
import ConfirmCard from './ConfirmCard';
// Mui
import {
  Button, Paper, TextField, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Validation
import { isFieldRequired, isEmailValid } from '../utilities/Validation';

type Props = {
  onClose: () => void,
}
interface IFormData {
  email: string;
}

const RecoveryPasswordRequestForm:React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { control, handleSubmit, formState: { errors } } = useForm<IFormData>({
    mode: "onChange",
    defaultValues: {
      email: '',
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    setIsLoading(true);
    dispatch(UsersAsync.passwordRecoveryRequest(data))
      .unwrap()
      .then(() => {
        setSentEmail(true);
      })
      .finally(() => {
        setIsLoading(false);
      })
  });

  return (
    <React.Fragment>
      {sentEmail ? (
        <ConfirmCard onClose={onClose}>Reset link has been sent to your email</ConfirmCard>
      ) : (
        <Paper elevation={6} variant="elevation" sx={{ p: 3 }}>
          <Typography
            display="block"
            variant="h5"
            textAlign="center"
            sx={{ pb: 2 }}
          >Reset Password</Typography>

          <form onSubmit={onSubmit} noValidate style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {/* E-mail */}
            <Controller
              control={control} name="email"
              rules={{ required: isFieldRequired, pattern: isEmailValid }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoComplete="email"
                  label="E-mail" type="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email ? errors.email.message : ''}
                  required
                />
              )}
            />
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              loading={isLoading}
              sx={{ p: 1 }}
            >
              Get reset link
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
          </form>
        </Paper>
      )}
    </React.Fragment>
  );
}

export default RecoveryPasswordRequestForm;
