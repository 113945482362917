import { createAsyncThunk } from "@reduxjs/toolkit";
import UsersAsync from "store/users/usersAsync";
// Services
import StorageService from "services/StorageService";
// Actions
import { AuthActions } from "./authSlice";
// Utilities
import HttpClient from 'utilities/HttpClient'
import { getContent } from "utilities/Utilities";

export default class AuthAsync {
  private static _url:string = '/client/auth';
  private static _http:HttpClient = new HttpClient();

  // Login
  public static signIn = createAsyncThunk('auth/login', async (data:any, thunkApi) => {
    const nextData = { accountId: getContent('settings').accountId, ...data };
    const response:Response = await this._http.post(`${this._url}/login`, nextData);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    const credential = await response.json();
    StorageService.setCredential(credential);
    thunkApi.dispatch(UsersAsync.fetchMe({}));
  });

  // Check authenticated
  public static checkIsUserAuthenticated = createAsyncThunk('auth/checkIsUserAuthenticated', async (_:any, thunkApi) => {
    thunkApi.dispatch(AuthActions.setAuthenticated(null));
    if ( StorageService.getRefreshToken() ){
      thunkApi.dispatch(UsersAsync.fetchMe({}));
    } else {
      StorageService.removeCredential();
      thunkApi.dispatch(AuthActions.setAuthenticated(false));
    }
  });
}
