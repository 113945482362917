import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";
import config from "config";
// Services
import StorageService from "services/StorageService";
// Acctions
import { MessagesActions } from "store/messages/messagesSlice";
import { NotificationsActions } from "store/notifications/notificationsSlice";
import { ChatsActions } from "store/chats/chatsSlice";
import ChatEvents from "types/ChatEvents";
import ChatsAsync from "store/chats/chatsAsync";
import { UiActions } from "store/ui/uiSlice";

const messageMiddleware = ({ dispatch }:any) => {
  let stomp:any;

  return (next:any) => (action:any) => {
    const { type, payload } = action;
  
    if (type === 'messages/connectMessages') {
      const socket = new SockJS(`${config.apiUrl}/websocket/sockjs`);
      stomp = Stomp.over(socket);
      stomp.debug = () => {};
      stomp.connect({"Authorization" : `Bearer ${StorageService.getAccessToken()}`},
      (frame: any) => {
        stomp.subscribe('/user/queue/helpdesk', (messageOutput: any) => {
          dispatch(MessagesActions.addMessage(JSON.parse(messageOutput.body)));
        });
        stomp.subscribe('/user/queue/notification', (messageOutput: any) => {
          dispatch(NotificationsActions.addNotification(JSON.parse(messageOutput.body)));
        });
        stomp.subscribe('/user/queue/chat', (messageOutput: any) => {
          const { event, ...nextData } = JSON.parse(messageOutput.body);

          if (event === ChatEvents.participantRemovedFromChat) {
            dispatch(ChatsActions.removeUserFromChat(nextData.chatId));
            dispatch(UiActions.enqueueSnackbar({ message: `You have been removed from ${nextData.chatName} chat.` }));
          }

          if (event === ChatEvents.participantAddedToChat) {
            dispatch(ChatsAsync.fetchChatById(nextData.chatId));
            dispatch(UiActions.enqueueSnackbar({ message: `You have been added to ${nextData.chatName} chat.` }));
          }
          
          if (event === ChatEvents.messageReceived) {
            const message = {
              id: nextData.messageId,
              message: nextData.message,
              createdOn: nextData.messageCreatedOn,
              sender: nextData.messageSender,
              chatId: nextData.chatId,
            };
            dispatch(ChatsActions.addMessage(message));
            dispatch(UiActions.enqueueSnackbar({ message: `You have a new message in ${nextData.chatName} chat.` }));
          } 
        });
      });
    }

    if (type === 'messages/disconnectMessages' && stomp) {
      stomp.disconnect();
    }
  
    if (type === 'messages/sendMessage') {
      const { id, message } = payload;
  
      stomp.send("/api/websocket/destination/helpdesk", {"receipt" : `message-${id}`}, JSON.stringify({message}));
  
      stomp.onreceipt = (frame: any) => {
        const messageId = frame.headers['receipt-id'].replace('message-', '');
        dispatch(MessagesActions.sendMessageSuccess(messageId));
      }
      stomp.onerror = (frame: any) => {
        console.log('error frame', frame);
      }
    }

    if (type === 'chats/sendMessage') {
      const { id, message, chatId } = payload;
  
      stomp.send("/api/websocket/destination/chat", {"receipt" : `message-${id}`}, JSON.stringify({message, chatId}));
  
      stomp.onreceipt = (frame: any) => {
        const messageId = frame.headers['receipt-id'].replace('message-', '');
        dispatch(ChatsActions.sendMessageSuccess(messageId));
      }
      stomp.onerror = (frame: any) => {
        console.log('error frame', frame);
      }
    }
    
    return next(action);
  }
}

export default messageMiddleware;
