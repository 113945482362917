import React, { useCallback, useEffect, useRef } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from "hooks/useDialog";
// Store
import { RootState } from "store";
// Async
import ChatsAsync from "store/chats/chatsAsync";
// Actions
import { ChatsActions } from "store/chats/chatsSlice";
// Selectors
import { selectChatById, selectChatsMessages } from "store/chats/chatsSelectors";
// Components
import Title from "components/Title";
import SendMessage from "./SendMessage";
import MessagesList from "./MessagesList";
import ChatInfo from "./ChatInfo";
// Mui
import { makeStyles } from "@mui/styles";
import { Box, IconButton, LinearProgress } from "@mui/material";
import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import { selectAccountsSettings } from "store/accounts/accountsSelectors";

const ChatsMessagesPage:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const chatId = useParams()['chatId'];

  const messageEndRef = useRef<null | HTMLDivElement>(null);

  const accountsSettings = useAppSelector(selectAccountsSettings);
  const messages = useAppSelector(selectChatsMessages);
  const chat = useAppSelector((state: RootState) => selectChatById(state, chatId));

  const scrollToEnd = useCallback(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'auto' });
    // eslint-disable-next-line
  }, [messageEndRef]);

  useEffect(() => {
    if (!accountsSettings.chatModuleEnabled) return;

    if(chatId) {
      dispatch(ChatsAsync.fetchChatsMessages({ chatId }))
        .unwrap()
        .then(() => { window.scrollTo({ top: 0 }) })
        .catch(() => navigate('/chats'))
      dispatch(ChatsActions.setSelectedChatId(+chatId));
    }

    return () => {
      dispatch(ChatsActions.setInitialField('chatsMessages'));
      dispatch(ChatsActions.setInitialField('selectedChatId'));
    }
    // eslint-disable-next-line
  }, [chatId, accountsSettings.chatModuleEnabled]);

  useEffect(() => {
    if (!chat) navigate('/chats');
    // eslint-disable-next-line
  }, [chat]);

  const { Dialog, openDialog, closeDialog } = useDialog();

  if ( accountsSettings.chatModuleEnabled === false ) return <Navigate to="/" replace />;

  return (
    <React.Fragment>
      {chat && (
        <Dialog maxWidth="sm">
          <ChatInfo onClose={closeDialog} chat={chat} />
        </Dialog>
      )}

      <Box className={classes.page}>
        <div className="container">
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <IconButton onClick={() => navigate('/chats')}>
              <ArrowBackOutlinedIcon />
            </IconButton>
            <Title>{chat?.name || 'Chat'}</Title>
            <IconButton onClick={openDialog}>
              <InfoOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={classes.messages}>
            <Box className={classes.messagesContent}>
              {messages ? (
                  <MessagesList scrollToEnd={scrollToEnd} />
              ) : <LinearProgress />}
              <div style={{ height: '1px' }} ref={messageEndRef} />
            </Box>
            <SendMessage scrollToEnd={scrollToEnd} />
          </Box>
        </div>
      </Box>
    </React.Fragment>
  )
}

export default ChatsMessagesPage;

const useStyles = makeStyles({
  page: {
    backgroundColor: 'rgba(245, 246, 247, 1)',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
    },
  },
  messages: {
    marginTop: '16px',
    border: '1px solid #eee',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: '4px',
    '@media (min-width: 600px)': {
      marginTop: '24px',
    }
  },
  messagesContent: {
    position: 'relative',
    height: 'calc(100vh - 383px)',
    overflowY: 'scroll',
    backgroundColor: 'rgba(250, 250, 250, 1)',
    padding: '16px',
    display: 'flex',
    flexBasis: '100%',
    flexDirection: 'column',
    gap: '8px',
    '@media (min-width: 600px)': {
      height: 'calc(100vh - 428px)',
    },
    '@media (min-width: 1240px)': {
      height: 'calc(100vh - 432px)',
    },
  },
});
