import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
// Async
import UsersAsync from 'store/users/usersAsync';
// Hooks
import { useAppDispatch, useAppSelector } from 'hooks/redux';
// Models
import IUser from 'models/User';
import { IAdditionalAttribut } from 'models/UserAdditionalAttributes';
// Types
import SubscriptionStatus from 'types/SubscriptionStatus';
import UserAdditionalAttributTypes from 'types/UserAdditionalAttributTypes';
// Selectors
import { selectCurrentUser, selectRemoveImage } from 'store/users/usersSelectors';
import { selectAccountsSettings, selectGuestModuleEnabled, selectSubscriptionModuleEnabled } from 'store/accounts/accountsSelectors';
// Mui
import { LoadingButton } from '@mui/lab';
import {
  FormControlLabel, FormGroup, Paper, Switch,
  FormHelperText, Box, Grid, ButtonGroup, Button,
} from '@mui/material';
import {
  Circle as CircleIcon,
  CancelOutlined as CancelOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
} from '@mui/icons-material';
// Components
import Phone, { getCallingCode, getPhoneWithoutCallingCode, setPhoneWithCallingCode } from 'components/Phone';
import { Input } from 'components/Controls';
import ImageUploader from './ImageUploader';
// Utitliies
import {
  isFieldRequired, isEmailValid, isMatch, isMinValue,
  isUpperCase, isLowerCase, isSpecial
} from 'utilities/Validation';
import Title from 'components/Title';
import UserRoles from 'types/UserRoles';
import { UiActions } from 'store/ui/uiSlice';
// Utilities
import { getContent } from 'utilities/Utilities';

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  secondaryEmail: string;
  phone: string;
  callingCode: string
  secondaryPhone: string;
  secondaryCallingCode: string
  notificationPreferences: {
    sms: boolean;
    email: boolean
  },
  newPassword?: string;
  reNewPassword?: string;
  roles: UserRoles[];
  additionalAttributes: IAdditionalAttribut[];
};

const ProfilePage:React.FC = () => {
  const dispatch = useAppDispatch();
  
  const accountsSettings = useAppSelector(selectAccountsSettings);
  const guestModuleEnabled = useAppSelector(selectGuestModuleEnabled);
  const currentUser:IUser | null = useAppSelector(selectCurrentUser);
  const subscriptionModuleEnabled:boolean | null = useAppSelector(selectSubscriptionModuleEnabled);
  const removeImage = useAppSelector(selectRemoveImage);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const [roles, setRoles] = useState<UserRoles[]>(currentUser?.roles || []);

  const handleChangeRoles = (role: UserRoles) => {
    setRoles(prev => prev?.includes(role) ? prev.filter(r => r !== role) : [...prev, role]);
  }

  const { register, control, handleSubmit, formState:{ errors }, setValue, watch } = useForm<IFormData>({
    mode: 'onChange',
    defaultValues: {
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      email: currentUser?.email || '',
      secondaryEmail: currentUser?.secondaryEmail || '',
      callingCode: currentUser?.callingCode || '',
      phone: setPhoneWithCallingCode(currentUser?.callingCode || '', currentUser?.phone || ''),
      secondaryCallingCode: currentUser?.secondaryCallingCode || '',
      secondaryPhone: setPhoneWithCallingCode(currentUser?.secondaryCallingCode || '', currentUser?.secondaryPhone || ''),
      notificationPreferences: {
        sms: currentUser?.notificationPreferences.sms,
        email: currentUser?.notificationPreferences.email,
      },
      newPassword: '',
      reNewPassword: '',
      roles: currentUser?.roles,
      additionalAttributes: [],
    }
  });

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "additionalAttributes",
  });

  useEffect(() => {
    if (!currentUser || !accountsSettings.userAdditionalAttributes?.length || loaded) return;
    accountsSettings.userAdditionalAttributes.forEach((attribut) => {
      append(({ ...attribut, value: currentUser.additionalAttributes?.find(attr => attr.name === attribut.name)?.value || '' }))
    })
    // cancel focus from the last input
    append({ value: '', name: '', displayName: '', type: 'number', mandatory: false });
    remove(accountsSettings.userAdditionalAttributes.length);
    //
    setLoaded(true);
    // eslint-disable-next-line
  }, [currentUser, accountsSettings.userAdditionalAttributes]);

  const onSubmit = handleSubmit((data:IFormData) => {
    setIsLoading(true);

    const { additionalAttributes, newPassword, reNewPassword, phone, callingCode, secondaryPhone, secondaryCallingCode, secondaryEmail, ...nextData } = data as any;
    if ( newPassword ) nextData['password'] = newPassword;

    nextData['additionalAttributes'] = additionalAttributes.reduce((acc:any, cur: any) => {
      if (`${cur.value}`.trim()) {
        acc.push({ name: cur.name, value: cur.type === UserAdditionalAttributTypes.Number ? Number(cur.value) : cur.value });
      }
      return acc;
    }, []);
  
    dispatch(UsersAsync.updateUser({
      ...nextData,
      callingCode: getCallingCode(callingCode),
      phone: getPhoneWithoutCallingCode(callingCode, phone),
      secondaryCallingCode: secondaryPhone ? getCallingCode(secondaryCallingCode) : null,
      secondaryPhone: secondaryPhone ? getPhoneWithoutCallingCode(secondaryCallingCode, secondaryPhone) : null,
      secondaryEmail: secondaryEmail ? secondaryEmail : null,
      roles,
      imageId: currentUser?.image && !removeImage ? currentUser.image.id : null,
    }))
      .unwrap()
      .then(() => {
        dispatch(UiActions.enqueueSnackbar({ message: 'Profile was updated' }));
      })
      .finally(() => {
        setIsLoading(false);
        setValue('newPassword', '');
        setValue('reNewPassword', '');
      });
  });

  const watchNewPassword = watch('newPassword');
  const watchReNewPassword = watch('reNewPassword');

  const subscriptionStatusActive = currentUser?.subscriptions?.some((subscription) => subscription.status === SubscriptionStatus.Active);
  const subscriptionStatusTrial = currentUser?.subscriptions?.some((subscription) => subscription.status === SubscriptionStatus.Trial);

  if ( !currentUser ) return null;
  return (
    <Box sx={{
      minHeight: '100%', padding: { xs: '16px', sm: '32px', lg: '64px 0' }, backgroundColor: '#F5F6F7',
      display: 'flex', flexDirection: 'column', alignItems: 'center'
    }}>
      <Title>Profile</Title>
      <Paper variant="outlined" sx={{ maxWidth: '600px', p: { xs: 2, md: 4 }, mt: 3, height: 'max-content' }}>
        <form onSubmit={onSubmit} noValidate autoComplete="off">
          <ImageUploader image={currentUser.image} />

          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <Controller
                control={control} name="firstName"
                rules={{ required: isFieldRequired }}
                render={({ field }) => (
                  <Input
                    {...field}
                    margin="none"
                    label="First name"
                    required
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName ? errors.firstName.message : ''}
                  />
                )}
              />
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <Controller
                control={control} name="lastName"
                rules={{ required: isFieldRequired }}
                render={({ field }) => (
                  <Input
                    {...field}
                    margin="none"
                    label="Last name"
                    required
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName ? errors.lastName.message : ''}
                  />
                )}
              />
            </Grid>

            {/* E-mail */}
            <Grid item xs={12}>
              <Controller
                control={control} name="email"
                rules={{ required: isFieldRequired, pattern: isEmailValid }}
                render={({ field }) => (
                  <Input
                    {...field}
                    margin="none"
                    label="E-mail" type="email"
                    autoComplete="email"
                    required
                    error={Boolean(errors.email)}
                    helperText={errors.email ? errors.email.message : ''}
                  />
                )}
              />
            </Grid>

            {/* Secondary E-mail */}
            <Grid item xs={12}>
              <Controller
                control={control} name="secondaryEmail"
                rules={{ pattern: isEmailValid }}
                render={({ field }) => (
                  <Input
                    {...field}
                    margin="none"
                    autoComplete="off"
                    label="Secondary e-mail" type="email"
                    error={Boolean(errors.secondaryEmail)}
                    helperText={errors.secondaryEmail ? errors.secondaryEmail.message : ''}
                  />
                )}
              />
            </Grid>

            {/* Phone */}
            <Grid item xs={12}>
              <Controller
                control={control} name="phone"
                rules={{ required: isFieldRequired }}
                render={({ field:{ value, onChange } }) => (
                  <Phone
                    label="Cell phone" value={value}
                    margin="none"
                    onChange={([ callingCode, phone ]:string[]) => {
                      setValue('callingCode', callingCode);
                      onChange(phone);
                    }}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone ? (errors.phone as any).message : ''}
                    required={true}
                  />
                )}
              />
            </Grid>

            {/* Secondary Phone */}
            <Grid item xs={12}>
              <Controller
                control={control} name="secondaryPhone"
                render={({ field:{ value, onChange } }) => (
                  <Phone
                    label="Secondary cell phone" value={value}
                    margin="none"
                    onChange={([ callingCode, phone ]:string[]) => {
                      setValue('secondaryCallingCode', callingCode);
                      onChange(phone);
                    }}
                  />
                )}
              />
            </Grid>
            
            {/* additionalAttributes */}
            {fields.map((field:any, index) => (
              <Grid item xs={12} key={field.id}>
                <Input
                  margin="none"
                  {...register(`additionalAttributes.${index}.value`, { required: field.mandatory ? isFieldRequired : false })}
                  value={watch(`additionalAttributes.${index}.value`)}
                  label={field.displayName}
                  type={field.type === UserAdditionalAttributTypes.Number ? 'number' : 'text'}
                  multiline={field.type === UserAdditionalAttributTypes.LongText}
                  rows={field.type === UserAdditionalAttributTypes.LongText ? 3 : 1}
                  required={field.mandatory}
                  error={errors.additionalAttributes ? Boolean(errors.additionalAttributes[index]) : false}
                  helperText={errors.additionalAttributes && errors.additionalAttributes[index] ? errors.additionalAttributes[index].value?.message : ''}
                />
              </Grid>
            ))}

            {guestModuleEnabled && (
              <Grid item xs={12}>
                <ButtonGroup aria-label="roles button group" sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }} size="small">
                  {roles.includes(UserRoles.Guest) && roles.length  > 1 && (
                    <Button
                      sx={{ flexGrow: 1 }}
                      variant="contained"
                      onClick={() => handleChangeRoles(UserRoles.Guest)}
                    >
                      {`Revoke ${getContent('labels').labelGuestSingularText} capabilities`}
                    </Button>
                  )}
                  {!roles.includes(UserRoles.Guest) && (
                    <Button
                      sx={{ flexGrow: 1 }}
                      variant="outlined"
                      onClick={() => handleChangeRoles(UserRoles.Guest)}
                    >
                      {`Add ${getContent('labels').labelGuestSingularText} capabilities`}
                    </Button>
                  )}
                  {roles.includes(UserRoles.Owner) && roles.length > 1 && (!subscriptionModuleEnabled || !subscriptionStatusActive) && (
                    <React.Fragment>
                      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => handleChangeRoles(UserRoles.Owner)}
                        >
                        {`Revoke ${getContent('labels').labelOwnerSingularText} capabilities`}
                        </Button>
                        {subscriptionModuleEnabled && subscriptionStatusTrial && (
                          <FormHelperText sx={{ color: '#c62828' }}>* Your trial subscription will be terminated</FormHelperText>
                        )}
                      </Box>
                    </React.Fragment>
                  )}
                  {!roles.includes(UserRoles.Owner) && (
                    <Button
                      sx={{ flexGrow: 1 }}
                      variant="outlined"
                      onClick={() => handleChangeRoles(UserRoles.Owner)}
                    >
                      {`Add ${getContent('labels').labelOwnerSingularText} capabilities`}
                    </Button>
                  )}
                </ButtonGroup>
              </Grid>

            )}

            <Grid item xs={12}>
              <FormGroup sx={{ display: 'flex', gap: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                <Controller
                  control={control} name="notificationPreferences.sms"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          color="secondary"
                          checked={value}
                          onChange={(event:React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
                        />
                      }
                      label="SMS notification"
                    />
                  )}
                />
                <Controller
                  control={control} name="notificationPreferences.email"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          color="secondary"
                          checked={value}
                          onChange={(event:React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
                        />
                      }
                      label="E-mail notification"
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            {/* Password */}
            <Grid item xs={12}>
              <Controller
                control={control} name="newPassword"
                rules={{
                  required: {
                    ...isFieldRequired,
                    value: watchReNewPassword !== ''
                  },
                  validate: {
                    isMinValue: (value:string | undefined) => isMinValue(value, 8),
                    isLowerCase,
                    isUpperCase,
                    isSpecial
                  }
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    margin="none"
                    label="New password" type="password" autoComplete="off"
                    required={watchReNewPassword !== ''}
                    error={Boolean(errors.newPassword && watchReNewPassword !== '')}
                    helperText={errors.newPassword && watchReNewPassword !== '' && errors.newPassword.type === 'required' ? errors.newPassword.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control} name="reNewPassword"
                rules={{
                  required: {
                    ...isFieldRequired,
                    value: watchNewPassword !== ''
                  },
                  validate: {
                    isPasswordMatch: (value:string | undefined) => isMatch(value, watchNewPassword, 'Password doesn`t match')
                  }
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    margin="none"
                    label="Confirm new password" type="password" autoComplete="off"
                    required={watchNewPassword !== ''}
                    error={Boolean(errors.reNewPassword && watchNewPassword !== '')}
                    helperText={errors.reNewPassword && watchNewPassword !== '' ? errors.reNewPassword.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormHelperText sx={{
                  display: 'flex', gap: 0.5, alignItems: 'center',
                  color: !watchNewPassword ? 'gray' : isMinValue(watchNewPassword, 8) === true ? '#2E7D32' : '#C62828'
                }}>
                  
                  {!watchNewPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isMinValue(watchNewPassword, 8) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
                  Your password must be 8 or more characters in length.
                </FormHelperText>
                <FormHelperText sx={{
                  display: 'flex', gap: 0.5, alignItems: 'center',
                  color: !watchNewPassword ? 'gray' : isUpperCase(watchNewPassword) === true && isLowerCase(watchNewPassword) === true ? '#2E7D32' : '#C62828'
                }}>
                  {!watchNewPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isUpperCase(watchNewPassword) === true && isLowerCase(watchNewPassword) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
                  Your password must contain 1 or more uppercase and lowercase characters.
                </FormHelperText>
                <FormHelperText sx={{
                  display: 'flex', gap: 0.5, alignItems: 'center',
                  color: !watchNewPassword ? 'gray' : isSpecial(watchNewPassword) === true ? '#2E7D32' : '#C62828'
                }}>
                  {!watchNewPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isSpecial(watchNewPassword) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
                  Your password must contain 1 or more special characters.
                </FormHelperText>
              </FormGroup>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex' }}>
              <Box flexGrow="1" />
              <LoadingButton
                type="submit"
                loading={isLoading}
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}

export default ProfilePage;
