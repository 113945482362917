import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';

export default class PaymentsAsync {
  private static _url:string = '/client/payments';
  private static _http:HttpClient = new HttpClient();

  // Create property
  public static createPayment = createAsyncThunk('payments/createPayment', async (data:any, thunkApi) => {
    const response:Response = await this._http.post(this._url, data);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
  });
}
