import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import PurchaseInquiriesAsync from "./purchaseInquiriesAsync";
import IPurchaseInquiry from "models/PurchaseInquiry";

interface IState {
  purchaseInquiries: IPurchaseInquiry[] | null;
  total: number;
}

const initialState:IState = {
  purchaseInquiries: null,
  total: 0,
};

const purchaseInquiriesSlice = createSlice({
  name: 'purchaseInquiries',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      // Fetch purchase inquiries
      .addCase(PurchaseInquiriesAsync.fetchPurchaseInquiries.pending, (state) => {
        state.purchaseInquiries = null;
      })
      .addCase(PurchaseInquiriesAsync.fetchPurchaseInquiries.fulfilled, (state, action:PayloadAction<any>) => {
        state.purchaseInquiries = action.payload.data;
        state.total = action.payload.total;
      })
  }
});

export const purchaseInquiriesActions = purchaseInquiriesSlice.actions;

export default purchaseInquiriesSlice.reducer;
