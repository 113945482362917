import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Async
import ChatsAsync from "store/chats/chatsAsync";
// Selectors
import { selectChats } from "store/chats/chatsSelectors";
import { selectAccountsSettings } from "store/accounts/accountsSelectors";
// mui
import { makeStyles } from '@mui/styles';
import { Box, LinearProgress } from "@mui/material";
// components
import Title from "components/Title";
import ChatsList from "./ChatsList";
import { NoChats } from "components/Utilities";

const ChatsPage:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const accountsSettings = useAppSelector(selectAccountsSettings);
  const chats = useAppSelector(selectChats);

  useEffect(() => {
    if (!accountsSettings.chatModuleEnabled) return;

    dispatch(ChatsAsync.fetchChats({ size: 100 }));
    // eslint-disable-next-line
  }, [accountsSettings.chatModuleEnabled]);

  if ( accountsSettings.chatModuleEnabled === false ) return <Navigate to="/" replace />;

  return (
    <Box className={classes.page}>
      <div className="container">
        <Title>Chats</Title>
        <Box>
          {chats === null ? <LinearProgress /> : !!chats?.length ? <ChatsList /> :  <NoChats />}
        </Box>
      </div>
    </Box>
  )
}

export default ChatsPage;

const useStyles = makeStyles({
  page: {
    minHeight: 'calc(100vh - 266px)',
    backgroundColor: 'rgba(245, 246, 247, 1)',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
      minHeight: 'calc(100vh - 236px)',
    },
  },
});
