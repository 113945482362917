import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISubscription } from "models/Subscriptions";
// Models
import IUser from "models/User";
// Async
import UsersAsync from './usersAsync';

interface IState {
  currentUser: IUser | null;
  removeImage: boolean;
}

const initialState:IState = {
  currentUser: null,
  removeImage: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    removeCurrentUser: (state) => {
      state.currentUser = null;
    },
    updateUserSubscriptions: (state, action:PayloadAction<ISubscription>) => {
      state.currentUser = state.currentUser
        ? {
          ...state.currentUser,
          subscriptions: state.currentUser.subscriptions?.map((subscription: ISubscription) => action.payload.id === subscription.id ? action.payload : subscription)
        }
        : state.currentUser
    },
    toggleBenefitAvailable: (state, action:PayloadAction<number>) => {
      state.currentUser = state.currentUser
        ? {...state.currentUser, benefits: state.currentUser.benefits?.map(benefit => benefit.id === action.payload ? {...benefit, available: !benefit.available} : benefit) }
        : state.currentUser
    },
    setRemoveImage: (state, action:PayloadAction<boolean>) => {
      state.removeImage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch me
      .addCase(UsersAsync.fetchMe.pending, (state) => {
        state.currentUser = null;
      })
      .addCase(UsersAsync.fetchMe.fulfilled, (state, action: PayloadAction<IUser>) => {
        state.currentUser = action.payload;
      })
      // Update user
      .addCase(UsersAsync.updateUser.fulfilled, (state, action: PayloadAction<IUser>) => {
        state.currentUser = action.payload;
        state.removeImage = false;
      })
      // Unsubscribe
      .addCase(UsersAsync.unsubscribe.fulfilled, (state) => {
        state.currentUser = state.currentUser ? {...state.currentUser, notificationPreferences: { ...state.currentUser?.notificationPreferences, email: false }} : state.currentUser;
      })
  }
});

export const UsersActions = usersSlice.actions;

export default usersSlice.reducer;
