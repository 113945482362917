import dayjs, { Dayjs } from "dayjs";

export const isFieldRequired = {
  value: true,
  message: 'This field is required'
};

export const isEmailValid = {
  // eslint-disable-next-line
  value: /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
  message: 'E-mail is not valid'
};

export const isMatch = (value:string | undefined, matchField:string | undefined, message = 'Field not match') => {
  if ( !value || !matchField ) return true;
  return value === matchField || message;
}

export const isValueLength = (value:string | undefined, count:number) => {
  if ( !value ) return true;
  return value.length === count || `Must be ${count} characters in length`;
};

export const isMinValue = (value:string | undefined, min:number) => {
  if ( !value ) return true;
  return value.length >= min || `Must be ${min} or more characters in length`;
};

export const isLowerCase = (value:string | undefined) => {
  if ( !value ) return true;
  return /[a-z]/.test(value) || 'Field must contain 1 or more lowercase characters';
};

export const isUpperCase = (value:string | undefined) => {
  if ( !value ) return true;
  return /[A-Z]/.test(value) || 'Field must contain 1 or more uppercase characters';
};

export const isSpecial = (value:string | undefined) => {
  if ( !value ) return true;
  // eslint-disable-next-line
  return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value) || 'Field must contain 1 or more special characters';
};

export const isValidDate = (date: Dayjs | null) => {
  if (!date) return true;
  return dayjs(date).isValid() || 'Date is invalid!';
}
