import { FC, useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';

const MarqueeWrapper:FC<{title: string}> = ({ title }) => {
  const marqueeRef = useRef<HTMLDivElement | null>(null);

  const [rendered, setRendered] = useState<boolean>(false);
  const [marqueePlay, setMarqueePlay] = useState<boolean>(false);

  const handleMarqueePlay = () => {
    const elem = marqueeRef.current?.children[0];
    const overlay = elem?.children[0];
    const marquee = elem?.children[2];
    const marqueeLabel = marquee?.children[0];

    if (!overlay || !marqueeLabel) return;

    if ((marqueeLabel?.clientWidth + 75) >= overlay?.clientWidth) {
      setMarqueePlay(true);
    } else {
      setMarqueePlay(false);
    }
  }

  useEffect(() => {
    handleMarqueePlay();
  }, [rendered]);

  return (
    <div ref={marqueeRef}>
      <Marquee
        style={{
        position: 'absolute', bottom: '16px', left: 0,
        backgroundColor: 'rgba(255, 234, 0, 0.9)',
        fontWeight: 500, fontSize: '18px',
        lineHeight: '160%', letterSpacing: '-0.35px',
      }}
        gradient
        gradientColor={[255,191,0]}
        gradientWidth={50}
        play={marqueePlay}
        onMount={() => setRendered(true)}
      >
        {title}
      </Marquee>
    </div>
  )
}

export default MarqueeWrapper;