import React, { Fragment, useEffect, useState } from 'react';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Async
import SubscriptionsAsync from 'store/subscriptions/subscriptionsAsync';
import UsersAsync from 'store/users/usersAsync';
// Selectors
import { selectSubscriptionPackages } from 'store/subscriptions/subscriptionsSelectors';
// Models
import { ISubscriptionPackage } from 'models/Subscriptions';
// Services
import StorageService from 'services/StorageService';
// MUI
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import {
  Typography, Box, Button, IconButton, FormHelperText,
  DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// Utilities
import { formatCash, getContent } from 'utilities/Utilities';

const { primaryColor, secondaryColor } = getContent('theme');

type Props = {
  setSubscriptionPackageId: ((subscriptionPackageId: number) => void) | null;
  onClose: () => void;
  expired?: boolean;
}

const SubscriptionPackages:React.FC<Props> = ({ setSubscriptionPackageId, onClose, expired = false }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const subscriptionPackages = useAppSelector(selectSubscriptionPackages);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSubscriptionPackage, setSelectedSubscriptionPackage] = useState<null | number>(null);

  const handleOnClose = () => {
    StorageService.removeCredential();
    onClose();
  }

  useEffect(() => {
    dispatch(SubscriptionsAsync.fetchSubscriptionPackages({}));
    // eslint-disable-next-line
  }, []);

  const onSubmit = () => {
    if (!selectedSubscriptionPackage) return;
    if (setSubscriptionPackageId) {
      setSubscriptionPackageId(selectedSubscriptionPackage)
    } else {
      setLoading(true);
      dispatch(SubscriptionsAsync.createSubscription({ subscriptionPackageId: selectedSubscriptionPackage }))
        .unwrap()
        .then(() => {
          onClose();
          dispatch(UsersAsync.fetchMe({}))
        })
        .finally(() => setLoading(false))
    }
  }

  const SubscriptionPackageCard:React.FC<{ subscriptionPackage: ISubscriptionPackage }> = ({ subscriptionPackage }) => (
    <Box className={classes.card}>
      {selectedSubscriptionPackage === subscriptionPackage.id && (
        <React.Fragment>
          <span className={classes.border} /><span className={classes.border} />
          <span className={classes.border} /><span className={classes.border} />
        </React.Fragment>
      )}
      <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{subscriptionPackage.name}</Typography>
      <Typography sx={{ fontSize: '13px' }}>{`Trial period: ${subscriptionPackage.trialPeriodDaysNumber} days`}</Typography>
      <ul style={{ paddingTop: '16px' }}>
        {subscriptionPackage.benefits?.map(benefit => (
          <li key={benefit.id}>{benefit.name}</li>
        ))}
      </ul>
      <Button
        sx={{ mt: 2, mb: 2 }}
        variant="contained"
        fullWidth
        onClick={() => setSelectedSubscriptionPackage(subscriptionPackage.id)}
      >
        {selectedSubscriptionPackage === subscriptionPackage.id ? 'Selected' : 'Select'}
      </Button>
      <Typography>{`${formatCash(subscriptionPackage.price)} / month`}</Typography>
    </Box>
  )

  return (
    <Fragment>
      <DialogTitle sx={{ display:  'flex', alignItems: 'center', gap: 1 }}>
        <Typography sx={{ flexGrow: 1, fontSize: '20px', textAlign: 'center' }}>Choose subscription package</Typography>
        <IconButton onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormHelperText sx={{ textAlign: 'center' }}>
          {expired
            ? 'Your subscription has been expired. Please select subscription package and provide your billing information' 
            : 'You have to subscribe to proceed'
          }
        </FormHelperText>
        <Box sx={{ pt: 2, display: 'flex', gap: '40px', flexWrap: 'wrap', justifyContent: 'center' }}>
          {subscriptionPackages?.map((subscriptionPackage: ISubscriptionPackage) => (
            <SubscriptionPackageCard
              key={subscriptionPackage.id}
              subscriptionPackage={subscriptionPackage}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={!selectedSubscriptionPackage}
          loading={loading}
          variant="contained"
          onClick={onSubmit}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
}

export default SubscriptionPackages;

const useStyles = makeStyles({
  card: {
    position: 'relative',
    flexBasis: '100%',
    padding: '40px 20px',
    border: '1px solid #eee',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '@media (min-width: 600px)': {
      flexBasis: 'calc(50% - 20px)',
    },
  },
  border: {
    transition: 'all 0.3s',
    '&:nth-child(1)': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '3px',
      background: `linear-gradient(to right, transparent, ${primaryColor})`,
      animation: '$border1 2s linear infinite'
    },
    '&:nth-child(2)': {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '3px',
      height: '100%',
      background: `linear-gradient(to bottom, transparent, ${secondaryColor})`,
      animation: '$border2 2s linear 1s infinite',
    },
    '&:nth-child(3)': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '3px',
      background: `linear-gradient(to left, transparent, ${secondaryColor})`,
      animation: '$border3 2s linear infinite'
    },
    '&:nth-child(4)': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '3px',
      height: '100%',
      background: `linear-gradient(to top, transparent, ${primaryColor})`,
      animation: '$border4 2s linear 1s infinite',
    },
  },
  '@keyframes border1': {
    "0%": {
      transform: 'translateX(-100%)',
    },
    "100%": {
      transform: 'translateX(100%)',
    }
  },
  '@keyframes border2': {
    "0%": {
      transform: 'translateY(-100%)',
    },
    "100%": {
      transform: 'translateY(100%)',
    }
  },
  '@keyframes border3': {
    "0%": {
      transform: 'translateX(100%)',
    },
    "100%": {
      transform: 'translateX(-100%)',
    }
  },
  '@keyframes border4': {
    "0%": {
      transform: 'translateY(100%)',
    },
    "100%": {
      transform: 'translateY(-100%)',
    }
  },
})