import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';

export default class PollsAsync {
  private static _url:string = '/client/polls';
  private static _http:HttpClient = new HttpClient();

  // Fetch polls
  public static fetchPolls = createAsyncThunk('polls/fetchPolls', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(this._url, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch poll by id
  public static fetchPoll = createAsyncThunk('polls/fetchPoll', async (pollId:number, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/${pollId}`);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Vote
  public static vote = createAsyncThunk('polls/vote', async (params:any, thunkApi) => {
    const { id, answerId } = params;
    const response:Response = await this._http.put(`${this._url}/${id}/actions/vote?answerId=${answerId}`, null);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
  });
}
