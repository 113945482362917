import { FC, Fragment, useEffect, useState } from "react";
// Hooks
import { useAppSelector } from "hooks/redux";
// Models
import { ISubscriptionBenefit } from "models/Subscriptions";
// Types
import FrequencyType from "types/FrequencyType";
// Selectors
import { selectBenefits } from "store/subscriptions/subscriptionsSelectors";
// Components
import Title from 'components/Title';
import BenefitCard from "./BenefitCard";
// MUI
import { makeStyles } from "@mui/styles";
import { Box, Skeleton, Typography } from "@mui/material";
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";

const Benefits:FC = () => {
  const classes = useStyles();

  const benefits:ISubscriptionBenefit[] | null = useAppSelector(selectBenefits);
  const monthlyBenefits = benefits?.filter(benefit => benefit.frequency === FrequencyType.Monthly) || [];
  const permanentBenefits = benefits?.filter(benefit => benefit.frequency === FrequencyType.Permanent) || [];

  const [countCard, setCountCard] = useState<number>(window.innerWidth >= 1900 ? 4 : window.innerWidth >= 1240 ? 3 :  window.innerWidth >= 720 ? 2.2 : window.innerWidth >= 600 ? 1.6 : 1.2);

  const handlerScreenWidth = () => {
    setCountCard(window.innerWidth >= 1900 ? 4 : window.innerWidth >= 1240 ? 3 :  window.innerWidth >= 720 ? 2.2 : window.innerWidth >= 600 ? 1.6 : 1.2);
  }

  useEffect(() => {
    window.addEventListener('resize', handlerScreenWidth);

    return () => {
      window.removeEventListener('resize', handlerScreenWidth);
    }
  }, []);

  const isBenefits = !!monthlyBenefits.concat(permanentBenefits).length;

  return (
    <Box className={classes.block}>
      <div className="big-container">
        <Title>Benefits</Title>
        {isBenefits ? (
          <Fragment>
            {!!monthlyBenefits.length && (
              <Box>
                <Typography sx={{ mt: 2.5, ml: 0.5, fontSize: '20px', color: 'rgba(0,0,0,0.6)' }}>Recurring</Typography>
                <Swiper
                  style={{ padding: '12px 5px 35px' }}
                  spaceBetween={24}
                  slidesPerView={countCard}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                >
                  {monthlyBenefits.map(benefit => (
                    <SwiperSlide key={benefit.id}>
                      <BenefitCard benefit={benefit} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            )}
            {!!permanentBenefits.length && (
              <Box>
                <Typography sx={{ mt: 2.5, ml: 0.5, fontSize: '20px', color: 'rgba(0,0,0,0.6)' }}>Permanent</Typography>
                <Swiper
                  style={{ padding: '12px 5px 35px' }}
                  spaceBetween={24}
                  slidesPerView={countCard}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                >
                  {permanentBenefits.map(benefit => (
                    <SwiperSlide key={benefit.id}>
                      <BenefitCard benefit={benefit} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            )}
          </Fragment>
        ) : (
          <Swiper
            style={{ padding: '12px 5px 35px' }}
            spaceBetween={24}
            slidesPerView={countCard}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            {[1,2,3,4].map(item => (
              <SwiperSlide key={item}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Skeleton variant="rectangular" width="100%" height="260px" sx={{ borderRadius: '8px' }} />
                  <Skeleton animation="wave" width="100%"  height="50px" />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </Box>
  )
}

export default Benefits;

const useStyles = makeStyles({
  block: {
    padding: '21px 0 16px 16px',
    '@media (min-width: 600px)': {
      padding: '37px 0 32px 32px',
    },
    '@media (min-width: 1240px)': {
      padding: '64px 0',
    },
  },
});
