import React from 'react';
import dayjs from 'dayjs';
// Models
import IReservationRequest from 'models/ReservationRequest';
// Types
import ReservationRequestStatus from 'types/ReservationRequestStatus';
// MUI
import { makeStyles } from '@mui/styles';
import { Box, Card, Chip, Grid, Typography } from '@mui/material';
import {
  DoneAll as DoneAllIcon,
  Done as DoneIcon,
  Close as CloseIcon,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material';
// Components
import { GridBlock } from 'components/Utilities';
// Utilities
import { formatStartEndDate } from 'utilities/DateTimeFormatter';

type Props = {
  reservationRequest: IReservationRequest,
};

const ReservationRequestCard:React.FC<Props> = ({ reservationRequest }) => {
  const classes = useStyles();

  return (
    <Card sx={{ p: 2, borderRadius: 0, borderBottom: '1px solid #eee', width: '100%' }}>
      <Grid container spacing={2}>
        <GridBlock
          xs={12} md={3}
          text={reservationRequest.property?.name || 'Property not set'}
          textSx={{ color: reservationRequest.property ? 'initial' : '#d32f2f' }}
          title={`Created: ${dayjs(reservationRequest.createdOn).format('MMMM DD YYYY')}`}
          titleReverse={true}
          rowEnabled={false}
        />
        <Grid item xs={12} sm={6} md={3} alignSelf="center">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className={classes.title} sx={{ display: {xs: 'block', sm: 'none'} }}>Date range</Typography>
            <Typography className={classes.text}>
              {formatStartEndDate(reservationRequest.start, reservationRequest.end)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: {xs: 2, sm: 0} }}>
            <Typography className={classes.title} sx={{ display: {xs: 'block', sm: 'none'} }}>Status</Typography>
            <Box>
              {reservationRequest.status === ReservationRequestStatus.Confirmed && <Chip color={'success'} size="small" label="Confirmed" icon={<DoneAllIcon />} />}
              {reservationRequest.status === ReservationRequestStatus.Submitted && <Chip color={'info'} size="small" label="Submitted" icon={<DoneIcon />} />}
              {reservationRequest.status === ReservationRequestStatus.Rejected && <Chip color={'error'} size="small" label="Rejected" icon={<CloseIcon />} />}
              {reservationRequest.status === ReservationRequestStatus.Processing && <Chip color={'warning'} size="small" label="Processing" icon={<AccessTimeIcon />} />}
            </Box>
          </Box>
        </Grid>
        <GridBlock
          xs={12} sm={6}
          title="Notes"
          text={reservationRequest.guestNotes || '-'}
          rowEnabled={false}
        />
      </Grid>
    </Card>
  );
}

export default ReservationRequestCard;

const useStyles = makeStyles({
  title: {
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});
