import { FC, useEffect, useMemo, useState } from 'react';
// Redux
import { useAppSelector } from 'hooks/redux';
import { selectTimesharesByProperty } from 'store/timeshares/timesharesSelectors';
import { ITimeshareSimpleData } from 'models/Timeshare';
// MUI
import { Box, Button, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { EventAvailableOutlined } from '@mui/icons-material';
// Utilities
import { formatStartEndDate } from 'utilities/DateTimeFormatter';
import { openWindow, textFromCamelToNormalCase } from 'utilities/Utilities';
// DayJS
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

type Props = {
  title: string;
  timeshareId?: number;
  isRentType: boolean;
}

const TimesharesList:FC<Props> = ({ title, timeshareId, isRentType }) => {
  const timeshares = useAppSelector(selectTimesharesByProperty)?.filter(timeshare => timeshare.id !== timeshareId );

  const [maxItems, setMaxItems] = useState(20);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const filteredTimeshareByDates = useMemo(() => {
    if (!timeshares) return [];
    if (!startDate && !endDate) return [];
  
    return timeshares.filter(timeshare => {
      const isAfter = !!startDate && startDate.isValid() ? !!timeshare.start && dayjs(timeshare.start).isSameOrAfter(startDate) : true;
      const isBefore = !!endDate && endDate.isValid() ? !!timeshare.start && dayjs(timeshare.start).isSameOrBefore(endDate) : true;

      return isAfter && isBefore;
    })
  }, [timeshares, startDate, endDate]);

  const otherTimeshares = useMemo(() => {
    if (!timeshares) return [];
    if (filteredTimeshareByDates.length === 0) return timeshares;
    return timeshares.filter(timeshare => !filteredTimeshareByDates.some(t => t.id === timeshare.id))
  }, [timeshares, filteredTimeshareByDates])

  const handleMaxItems = () => {
    if (maxItems === 20) {
      setMaxItems(otherTimeshares.length);
    } else {
      setMaxItems(20);
    }
  }

  const getLabel = (timeshare:ITimeshareSimpleData):string => {
    if (timeshare.start && timeshare.end) return formatStartEndDate(timeshare.start, timeshare.end);
    if (timeshare.week) return `Week: ${timeshare.week}`;
    if (timeshare.season) return `${textFromCamelToNormalCase(timeshare.season)} season`;
    return isRentType ? 'Flexible dates' : 'Floating';
  }

  useEffect(() => {
    setMaxItems(20);
  }, [startDate, endDate]);

  if (!timeshares || !timeshares.length) return null;
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography sx={{ fontWeight: 500, fontSize: { xs: '16px', sm: '20px', lg: '24px' } }}>{title}</Typography>
        {isRentType ? (
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <DesktopDatePicker
              minDate={dayjs()}
              value={startDate}
              onChange={(date: Dayjs | null) => {
                setStartDate(date);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: "small",
                  sx: { backgroundColor: '#fff', borderRadius: '4px' },
                  placeholder: 'Start date',
                },
                actionBar: {
                  actions: ['clear', 'accept'],
                },
              }}
            />
            <DesktopDatePicker
              minDate={dayjs()}
              value={endDate}
              onChange={(date: Dayjs | null) => {
                setEndDate(date);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: "small",
                  sx: { backgroundColor: '#fff', borderRadius: '4px' },
                  placeholder: 'End date',
                },
                actionBar: {
                  actions: ['clear', 'accept'],
                },
              }}
            />
          </Box>
        ) : null}
      </Box>
      {!!filteredTimeshareByDates.length ? (
        <Box sx={{ pt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {filteredTimeshareByDates.map(timeshare => (
            <Button
              sx={{
                width: { xs: '100%', sm: 'max-content' },
                backgroundColor: '#fff',
                boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
                borderRadius: '4px'
              }}
              key={timeshare.id}
              startIcon={<EventAvailableOutlined />}
              onClick={() => openWindow(`/timeshares/id/${timeshare.id}`)}
            >
              {getLabel(timeshare)}
            </Button>
          ))}
        </Box>
      ) : (
        (!!startDate || !!endDate) ? (
          <Typography sx={{ pt: 2, fontWeight: 500, fontSize: { xs: '14px', sm: '16px', lg: '18px' } }}>
            No timeshares for selected date(s)
          </Typography>
        ) : null
      )}
      <Box sx={{ pt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {otherTimeshares.slice(0, maxItems).map(timeshare => (
          <Button
            sx={{
              width: { xs: '100%', sm: 'max-content' },
              backgroundColor: '#fff',
              boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
              borderRadius: '4px'
            }}
            key={timeshare.id}
            startIcon={<EventAvailableOutlined />}
            onClick={() => openWindow(`/timeshares/id/${timeshare.id}`)}
          >
            {getLabel(timeshare)}
          </Button>
        ))}
        {otherTimeshares.length > 20 ? (
          <Button
            sx={{ width: { xs: '100%', sm: 'max-content' } }}
            onClick={handleMaxItems}
          >
            {maxItems === 20 ? 'Show more' : 'Show less'}
          </Button>
        ) : null}
      </Box>
    </Box>
  )
}

export default TimesharesList;
