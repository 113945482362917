import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import reservationRequestsAsync from "./reservationRequestsAsync";
import IReservationRequest from "models/ReservationRequest";

interface IState {
  reservationRequests: IReservationRequest[] | null;
  total: number;
  isCreatedUser: boolean;
}

const initialState:IState = {
  reservationRequests: null,
  total: 0,
  isCreatedUser: false,
};

const reservationRequestsSlice = createSlice({
  name: 'reservationRequests',
  initialState,
  reducers: {
    setIsCreatedUser: (state, action:PayloadAction<boolean>) => {
      state.isCreatedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch reservation requests
      .addCase(reservationRequestsAsync.fetchReservationRequests.pending, (state) => {
        state.reservationRequests = null;
      })
      .addCase(reservationRequestsAsync.fetchReservationRequests.fulfilled, (state, action:PayloadAction<any>) => {
        state.reservationRequests = action.payload.data;
        state.total = action.payload.total;
      })
  }
});

export const reservationRequestsActions = reservationRequestsSlice.actions;

export default reservationRequestsSlice.reducer;
