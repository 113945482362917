import { FC, Fragment } from 'react';
// Hooks
import useToggle from 'hooks/useToggle';
// Models
import { ISubscriptionBenefit } from 'models/Subscriptions';
// Types
import FrequencyType from 'types/FrequencyType';
// MUI
import { makeStyles } from '@mui/styles';
import {
  Box, Button, Card, Collapse, Divider, Drawer, Grid,
  IconButton, Tooltip, Typography
} from '@mui/material';
import { LoyaltyOutlined, ExpandLess, ExpandMore } from '@mui/icons-material';
// Components
import Collapsable from 'components/Collapsable';
import BenefitRequestsForm from 'components/BenefitRequests.form';
import { GridBlock } from 'components/Utilities';

type Props = {
  benefit: ISubscriptionBenefit,
};

const BenefitCard:FC<Props> = ({ benefit }) => {
  const classes = useStyles();

  const urlPhoto: string = benefit.image ? benefit.image.url : '/img/noImageAvailable.png';

  const { open:extended, toggle:toggleExtended } = useToggle(true);
  const { open, toggle } = useToggle();

  return (
    <Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggle}
        sx={{ zIndex: 1300 }}
        PaperProps={{
          sx: { maxWidth: '100%', width: '420px' }
        }}
      >
        <BenefitRequestsForm onClose={toggle} benefitId={benefit.id} />
      </Drawer>

      <Card sx={{ p: 2, borderRadius: 0, backgroundColor: extended ? 'rgba(0, 0, 0, 0.04)' : '#fff', borderBottom: '1px solid #eee', width: '100%' }}>
        <Collapsable onClick={toggleExtended}>
          <Grid container spacing={2} sx={{ cursor: 'pointer' }}>
            <Grid item xs={10} md={3} alignSelf="center" sx={{ order: -1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {extended ? <ExpandLess /> : <ExpandMore />}
                <Typography className={classes.text}>
                  {benefit.name}
                </Typography>
              </Box>
            </Grid>
            <GridBlock
              xs={12} sm={6} md={2}
              title="Type"
              text={benefit.frequency}
            />
            <GridBlock
              xs={12} sm={6} md={2}
              title="Offer"
              text={benefit.offer}
            />
            <GridBlock
              xs={12} sm={6} md={2}
              title="Value"
              text={benefit.value}
            />
            <GridBlock
              xs={12} sm={6} md={1}
              title="Available"
              text={benefit.available ? 'Yes' : 'No'}
            />
            {benefit.available && benefit.frequency === FrequencyType.Monthly && (
              <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignSelf: 'center', order: { xs: -1, md: 0 } }}>
                <Fragment>
                  <Button
                    sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                    size="small"
                    onClick={toggle}
                    variant="contained"
                    startIcon={<LoyaltyOutlined />}
                  >
                    Claim
                  </Button>
                  <Tooltip title="Send request" sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
                    <IconButton onClick={toggle} color="primary">
                      <LoyaltyOutlined />
                    </IconButton>
                  </Tooltip>
                </Fragment>
              </Grid>
            )}
          </Grid>
        </Collapsable>
        <Collapse in={extended}>
          <Divider sx={{ p: 1 }} />
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <Grid item xs={12} sm={6} md={3}>
              <img src={urlPhoto} alt={benefit.name} className={classes.img} />
            </Grid>
            <GridBlock
              xs={12} sm={6} md={9}
              title="Description"
              text={benefit.description || '-'}
              sx={{ alignSelf: 'flex-start' }}
              rowEnabled={false}
            />
          </Grid>
        </Collapse>
      </Card>
    </Fragment>
  );
}

export default BenefitCard;

const useStyles = makeStyles({
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  img: {
    width: '100%',
    height: '100%',
    maxHeight: '200px',
    objectFit: 'contain',
  },
});
