import { FC, Fragment } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import classNames from 'classnames';
// Hooks
import { useAppSelector } from 'hooks/redux';
import useToggle from 'hooks/useToggle';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';
import { selectGuestModuleEnabled } from 'store/accounts/accountsSelectors';
// Mui
import { makeStyles } from '@mui/styles';
import {
  AppBar, Toolbar, Box, IconButton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// Components
import Menu from './Menu';
import UserMenu from './UserMenu';
import PublicMenu from './PublicMenu';
// Utilities
import { getContent } from 'utilities/Utilities';

const Header:FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const guestModuleEnabled = useAppSelector(selectGuestModuleEnabled);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const { open:openMenu, toggle:toggleMenu } = useToggle();

  const isBigContainer = pathname === '/' || pathname.startsWith('/timeshares') || pathname.startsWith('/property');

  return (
    <Fragment>
      <Menu open={openMenu} onClose={toggleMenu} />
      <AppBar position="relative" elevation={0} sx={{ background: '#fff', boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%)' }}>
        <div className={isBigContainer ? 'big-container' : 'container'}>
          <Toolbar disableGutters className={classes.toolbar}>
            <Box sx={{ display: 'flex',  alignItems: 'center', gap: '10px' }}>
              <IconButton
                onClick={toggleMenu}
                size="small"
                sx={{ '@media (min-width: 1240px)': { display: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <NavLink to="/">
                <img src={getContent('common').logoSrc} alt={getContent('common').name} className={classes.logo} />
              </NavLink>
              <Box sx={{ display: 'none',  alignItems: 'center', gap: '10px', '@media (min-width: 1240px)': { display: 'flex' } }}>
                <NavLink
                  className={classNames(classes.link, {
                    [classes.activeLink]: pathname === '/about-us',
                  })}
                  to="/about-us"
                >
                  About
                </NavLink>
                {guestModuleEnabled && (
                  <NavLink
                    className={classNames(classes.link, {
                      [classes.activeLink]: pathname === '/contact-us',
                    })}
                    to="/contact-us"
                  >
                    Contact us
                  </NavLink>
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {isAuthenticated ? <UserMenu /> : <PublicMenu />}
            </Box>
          </Toolbar>
        </div>
      </AppBar>
    </Fragment>
  );
}

export default Header;

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 32px',
    '@media (min-width: 1240px)': {
      padding: 0,
    }
  },
  logo: {
    paddingTop: '5px',
    height: '35px',
    '@media (min-width: 600px)': {
      height: '40px',
    }
  },
  link: {
    padding: '0 8px',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': {
      color: getContent('theme').primaryColor,
    }
  },
  activeLink: {
    color: getContent('theme').primaryColor,
  },
});
