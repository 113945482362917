import React from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useDialog from 'hooks/useDialog';
// Actions
import { TimesharesActions } from 'store/timeshares/timesharesSlice';
// Selectors
import { selectSelectedType } from 'store/ui/uiSelectors';
// Models
import ITimeshare from 'models/Timeshare';
import { IDestinationTypeGroups } from 'models/TimesharesGroup';
// Types
import TimeshareTypes from 'types/TimeshareTypes';
// Components
import ReservationRequestForm from 'components/ReservationRequest.form';
// mui
import { makeStyles } from '@mui/styles';
import {
  Typography, Chip, Box, IconButton, Tooltip,
} from '@mui/material';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
// utilities
import { formatStartEndDate } from 'utilities/DateTimeFormatter';
import { textFromCamelToNormalCase } from 'utilities/Utilities';

type Props = {
  destinationTypeGroup: IDestinationTypeGroups,
}

const DestinationTypeCard:React.FC<Props> = ({ destinationTypeGroup }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedType = useAppSelector(selectSelectedType);
  const isRentType = selectedType === TimeshareTypes.Rent;
  
  const imgUrl = destinationTypeGroup.destinationType?.image ? destinationTypeGroup.destinationType?.image.url : '/img/noPropertyFound.png';

  const showTimeshares = () => {
    dispatch(TimesharesActions.setParams({ destinationTypesIds: [`${destinationTypeGroup.destinationType.id}`] }));
    navigate('timeshares');
  }

  const { Dialog, openDialog, closeDialog } = useDialog();

  const getLabel = (timeshare:ITimeshare):string => {
    if (timeshare.start && timeshare.end) return formatStartEndDate(timeshare.start, timeshare.end);
    if (timeshare.week) return `Week: ${timeshare.week}`;
    if (timeshare.season) return `${textFromCamelToNormalCase(timeshare.season)} season`;
    return isRentType ? 'Flexible dates' : 'Floating';
  }
  
  return (
    <Box className={classes.card}>
      <Box sx={{ height: '178px' }} onClick={showTimeshares}>
        <div className={classes.gradient} />
        <img src={imgUrl} alt={destinationTypeGroup.destinationType.name} className={classes.img} />
        <Typography className={classes.title}>{destinationTypeGroup.destinationType.name}</Typography>
      </Box>
      {destinationTypeGroup.timeshares && (
        destinationTypeGroup.timeshares.slice(0,3).map((timeshare: ITimeshare) => (
          <React.Fragment key={timeshare.id}>
            <Dialog maxWidth="md">
              <ReservationRequestForm onClose={closeDialog} isRent={isRentType} />
            </Dialog>
            <Box
              onClick={() => {
                navigate(`timeshares/id/${timeshare.id}`);
              }}
              sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1,
                mt: 1, p: 1, backgroundColor: 'rgba(232, 236, 237, 1)', borderRadius: '8px', cursor: 'pointer',
              }}
            >
              <Box>
                <Tooltip title={timeshare.property.name} arrow placement="top">
                  <Typography className={classes.subtitle} gutterBottom>{timeshare.property.name}</Typography>
                </Tooltip>
                <Chip
                  color={timeshare.start ? 'default' : 'success'} size="small"
                  icon={<EventAvailableOutlinedIcon />}
                  label={getLabel(timeshare)}
                  sx={{ backgroundColor: timeshare.start ? '#fff' : "auto" }}
                />
              </Box>
              <IconButton
                color="primary"
                onClick={(event: any) => {
                  event.stopPropagation();
                  dispatch(TimesharesActions.setTimeshareDetails(timeshare));
                  openDialog();
                }}
              >
                <RequestQuoteOutlinedIcon />
              </IconButton>
            </Box>
          </React.Fragment>
        ))
      )}
    </Box>
  );
}

export default DestinationTypeCard;

const useStyles = makeStyles({
  card: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    '@media (min-width: 1900px)': {
      width: '267px',
    }
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '-0.35px',
    color: '#fff',
    position: 'absolute',
    top: 8,
    left: 8,
    textShadow: '0 0 2px #000',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '157%',
    letterSpacing: '-0.4px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '186px',
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: '100px',
    borderRadius: '8px 8px 0 0',
    left: 0,
    top: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
  },
});
