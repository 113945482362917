import React from 'react';
import { Navigate } from "react-router-dom";
// Hooks
import { useAppSelector } from 'hooks/redux';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';
import { selectCurrentUser } from 'store/users/usersSelectors';
// Types
import UserRoles from 'types/UserRoles';
import { selectSubscriptionModuleEnabled } from 'store/accounts/accountsSelectors';

type Props = {
  children: JSX.Element;
}

const PublicRoute:React.FC<Props> = ({ children }) => {
  const subscriptionModuleEnabled = useAppSelector(selectSubscriptionModuleEnabled);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const currentUser = useAppSelector(selectCurrentUser);

  if ( isAuthenticated ) return <Navigate to={currentUser?.activeRole === UserRoles.Owner ? subscriptionModuleEnabled ? '/benefits' :'/my-rent-timeshares' : '/reservations'} replace />;
  return children;
};

export default PublicRoute;
