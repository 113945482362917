import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Models
import IMessage from "models/Message";
// Async
import MessagesAsync from "./messagesAsync";

interface IState {
  messages: IMessage[] | null,
  total: number,
  isConnected: boolean,
}

const initialState:IState = {
  messages: null,
  total: 0,
  isConnected: false,
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    connectMessages: (state) => {
      state.isConnected = true;
    },
    disconnectMessages: (state) => {
      state.isConnected = false;
    },
    addMessage: (state, action:PayloadAction<IMessage>) => {
      state.messages = state.messages
        ? state.messages.length > 50 && state.messages.length < state.total
          ? [...state.messages.slice(1), action.payload]
          : [...state.messages, action.payload]
        : [action.payload]
      state.total = state.total + 1;
    },
    sendMessage: (state, action:PayloadAction<any>) => {
      state.messages = state.messages ? [...state.messages, action.payload] : [action.payload];
    },
    sendMessageSuccess: (state, action:PayloadAction<number>) => {
      state.messages = state.messages
      ? state.messages.length > 50 && state.messages.length < state.total
        ? [...state.messages.slice(1).map((message: IMessage) => message.id === action.payload ? {...message, read: false} : message)]
        : [...state.messages.map((message: IMessage) => message.id === action.payload ? {...message, read: false} : message)]
      : []
      state.total = state.total + 1;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch messages
      .addCase(MessagesAsync.fetchMessages.pending, (state) => {
        state.messages = null;
        state.total = 0;
      })
      .addCase(MessagesAsync.fetchMessages.fulfilled, (state, action:PayloadAction<any>) => {
        state.messages = action.payload.data ? action.payload.data.reverse() : [];
        state.total = action.payload.total;
      })
      // Refetch messages
      .addCase(MessagesAsync.refetchMessages.fulfilled, (state, action:PayloadAction<any>) => {
        state.messages = state.messages ? [...action.payload.data.reverse(), ...state.messages] : action.payload.data.reverse();
      })
      // Mark as read
      .addCase(MessagesAsync.markAsReadMessages.fulfilled, (state, action:any) => {
        state.messages = state.messages?.map((message:IMessage) => {
          return message.fromUser.id === action.payload ? message : message.read ? message : {...message, read: true}
        }) || [];
      })
  }
});

export const MessagesActions = messagesSlice.actions;

export default messagesSlice.reducer;
