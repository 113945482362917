import React from 'react';
// Mui
import { Box, Skeleton } from '@mui/material';

const TimeshareCardSkeleton:React.FC = () => {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Skeleton variant="rectangular" width="100%" height="260px" sx={{ borderRadius: '8px' }} />
      <Skeleton width="60%" height="35px" animation="wave" sx={{ mt: 1 }} />
      <Skeleton animation="wave" width="100%"  height="50px" />
    </Box>
  )
}

export default TimeshareCardSkeleton;
