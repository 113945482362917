import React from 'react';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogActions, DialogTitle, Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean,
  onClose: () => void,
  handleRemove: () => void,
  title: string,
};

const ConfirmDeleteModal:React.FC<Props> = ({ open, onClose, handleRemove, title }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      aria-describedby="confirm-delete-dialog-slide-description"
    >
      <DialogTitle id="confirm-delete-dialog-title" sx={{ fontWeight: 600 }}>
        {`Delete ${title}?`}
      </DialogTitle>
      <DialogContent sx={{ minWidth: '450px', maxWidth: '100%' }}>
        <Typography>
          This action cannot be undone
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pt: 4 }}>
        <Button variant="text" onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="error" onClick={handleRemove}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeleteModal;
