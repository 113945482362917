import React, { useEffect, useState } from 'react';
// Models
import { IPollAnswer } from 'models/Poll';
// Mui
import { makeStyles } from '@mui/styles';
import { Box, Typography, Tooltip, Button } from '@mui/material';
// Utilities
import { getContent } from 'utilities/Utilities';

type Props = {
  answer: IPollAnswer;
  allVotes: number;
  vote: (answerId: number) => void;
  voted: boolean;
}

const PollAnswerItem:React.FC<Props> = ({ answer, allVotes, vote, voted }) => {
  const classes = useStyles();

  let [percent, setPercent] = useState<number>(0);

  const maxPercent = React.useMemo(() => Number(((100 * (answer.votes || 0) / allVotes) || 0).toFixed(1)), [answer, allVotes]);

  const updatePercent = () => {
    const interval = setInterval(() => {
      setPercent(prev => {
        if (prev + 1 > maxPercent) {
          clearInterval(interval);
          return maxPercent;
        } else {
          return prev + 1;
        }
      })
    }, 20);
  }

  useEffect(() => {
    updatePercent();
    // eslint-disable-next-line
  }, [voted])

  return (
    <React.Fragment>
      {voted && (
        <Tooltip title={answer.answer}>
          <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{answer.answer}</Typography>
        </Tooltip>
      )}
      <Box className={classes.item_wrapper}>
        <Box
          className={classes.item}
          sx={{
            width: voted ? '100%' : '90%',
            backgroundColor: voted ? 'rgba(0, 0, 0, 0.4)' : getContent('theme').primaryColor,
          }}
        >
          <Box className={classes.item_percent} sx={{ width: `${percent}%` }} />
          <Typography>{voted ? `${answer.votes || 0}` : answer.answer}</Typography>
          {voted && <Typography>{`${percent}%`}</Typography>}
        </Box>
        {!voted && (
          <Button
            variant="contained"
            onClick={() => vote(answer.id)}
          >
            Vote
          </Button>
        )}
      </Box>
    </React.Fragment>
  )
}

export default PollAnswerItem;

const useStyles = makeStyles({
  item_wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
  },
  item: {
    position: 'relative',
    border: '1px solid #eee',
    borderRadius: '4px',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
    fontWeight: 500,
    color: '#fff',
  },
  item_percent: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    borderRadius: '4px',
    backgroundColor: getContent('theme').primaryColor,
    zIndex: -1,
    transition: 'width 0.1s',
  },
});
