import dayjs from 'dayjs';
import ICalendarSlot from 'models/CalendarSlot';
import { RootState } from 'store';

export const selectCalendarsSlots = (state:RootState) => state.calendars.calendarsSlots;
export const selectDailyBenefits = (state:RootState) => state.calendars.dailyBenefits;

export const selectCalendarsSlotsByDate = (state:RootState) => state.calendars.calendarsSlots
  ?.reduce((acc:any, cur:ICalendarSlot) => {
    const date = dayjs(cur.start).format('YYYY-MM-DD');
    acc[date] = acc[date] ? [...acc[date], cur] : [cur];
    return acc;
  }, {}) || {};

export const selectLastCalendarsSlot = (state:RootState) => state.calendars.calendarsSlots?.slice(-1)[0];
