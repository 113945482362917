import { FC, Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Redux
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from "hooks/useDialog";
import TimesharesAsync from "store/timeshares/timesharesAsync";
import { TimesharesActions } from "store/timeshares/timesharesSlice";
import { selectTimeshareDetails } from "store/timeshares/timesharesSelectors";
import TimeshareTypes from "types/TimeshareTypes";
// MUI
import { makeStyles } from '@mui/styles';
import { Box, Button, Chip, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import {
  EventAvailableOutlined as EventAvailableOutlinedIcon,
  RequestQuoteOutlined as RequestQuoteOutlinedIcon,
  PublicOutlined as PublicOutlinedIcon,
  WavesOutlined as WavesOutlinedIcon,
  PlaceOutlined as PlaceOutlinedIcon,
  BedOutlined as BedOutlinedIcon,
  TravelExploreOutlined as TravelExploreOutlinedIcon,
  BathroomOutlined as BathroomOutlinedIcon,
  ImageOutlined as ImageOutlinedIcon,
  EventOutlined as EventOutlinedIcon,
  DateRangeOutlined as DateRangeOutlinedIcon,
  BeachAccessOutlined as BeachAccessOutlinedIcon,
  MonetizationOnOutlined as MonetizationOnOutlinedIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  HomeWorkOutlined as HomeWorkOutlinedIcon
} from '@mui/icons-material';
// Components
import MarqueeWrapper from "components/MarqueeWrapper";
import Title from "components/Title";
import PropertyImages from "components/PropertyImages";
import ReservationRequestForm from "components/ReservationRequest.form";
import { InfoBlock } from "components/Utilities";
import TimesharesList from "components/TimesharesList";
// Utilities
import { formatStartEndDate } from "utilities/DateTimeFormatter";
import { formatCash, getContent, openWindow, textFromCamelToNormalCase } from 'utilities/Utilities';
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const TimeshareDetails:FC = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const timeshareId = useParams()['timeshareId'];

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const timeshare = useAppSelector(selectTimeshareDetails);

  const isRentTimeshare = timeshare?.type === TimeshareTypes.Rent;

  const allImages = [...timeshare?.property.images || [], ...timeshare?.images || []];
  const [mainImage, setMainImage] = useState(!!allImages.length ? allImages[0].url : '/img/noPropertyFound.png');

  useEffect(() => {
    setMainImage(!!allImages.length ? allImages[0].url : '/img/noPropertyFound.png');
    if (timeshare) {
      dispatch(TimesharesAsync.fetchTimesharesByProperty({ propertyId: timeshare.property.id, type: timeshare.type  }));
    }
    // eslint-disable-next-line
  }, [timeshare]);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo({ top: 0 });
    if (timeshareId) {
      dispatch(TimesharesAsync.fetchTimeshare(Number(timeshareId)))
        .unwrap()
        .catch(() => navigate('/timeshares'))
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line
  }, [timeshareId]);

  useEffect(() => {
    return () => {
      dispatch(TimesharesActions.setTimeshareDetails(null));
    }
    // eslint-disable-next-line
  }, []);

  const { Dialog, openDialog, closeDialog } = useDialog();

  const { Dialog:ReservationRequestDialog, openDialog:openReservationRequestDialog, closeDialog:closeReservationRequestDialog } = useDialog();

  if (isLoading) return <LinearProgress />;
  if (!timeshare) return null;
  return (
    <Fragment>
      <Dialog maxWidth="lg">
        <PropertyImages onClose={closeDialog} images={allImages} />
      </Dialog>
      <ReservationRequestDialog maxWidth="md">
        <ReservationRequestForm onClose={closeReservationRequestDialog} isRent={isRentTimeshare} />
      </ReservationRequestDialog>
      <Box sx={{ padding: '32px 32px 48px', minHeight: 'calc(100vh - 236px)', backgroundColor: '#f5f6f7' }}>
        <div className="container">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
            <Box>
              <Box onClick={() => openWindow(`/property/${timeshare.property.nameSlug}`)}>
                <Title style={{
                  transition: 'color 0.3s',
                  cursor: 'pointer',
                  '&:hover': {
                    color: '#53B8E0'
                  }
                }}>
                  {timeshare.property.name}
                </Title>
              </Box>
              {isRentTimeshare ? (
                <Chip
                  label={timeshare.start && timeshare.end ? formatStartEndDate(timeshare.start, timeshare.end) : 'Flexible dates'}
                  icon={<EventAvailableOutlinedIcon />}
                  color={timeshare.start ? 'default' : 'success'}
                  size="small" sx={{ mt: 2, p:1 }}
                />
              ) : null}
            </Box>
            <Button
              sx={{ display: { xs: 'none', sm: 'inline-flex' }, minWidth: 'max-content' }}
              variant="contained"
              startIcon={<RequestQuoteOutlinedIcon />}
              size="large"
              onClick={openReservationRequestDialog}
            >
              {timeshare.displaySalePrice ? `${isRentTimeshare ? 'Reserve for' : 'Offered at'} ${formatCash(timeshare.salePrice)}` : 'Request a quote'}
            </Button>
          </Box>

          <Box sx={{
            display: 'flex', mt: 3, flexDirection: { xs: 'column', md: 'row' },
            backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)'
          }}>
            <Box sx={{ position: 'relative', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              {allImages.length > 1 && (
                <Button
                  sx={{
                    position: 'absolute', top: '16px', right: '16px', backgroundColor: '#fff', color: '#222', zIndex: 2,
                    '&:hover': { backgroundColor: '#fafafa', boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.3)' }
                  }}
                  size="small"
                  startIcon={<ImageOutlinedIcon />}
                  onClick={openDialog}
                >
                  View all photos
                </Button>
              )}
              <Box
                className={classes.mainImageWrapper}
                sx={{ borderRadius: { xs: '8px 8px 0 0', md: allImages.length > 1 ? '8px 0 0 0' : '8px 0 0 8px' } }}
              >
                <img src={mainImage} className={classes.mainImage} alt={timeshare.property.name} />
                {timeshare.specialOffer && (
                  <MarqueeWrapper title={timeshare.specialOfferLabel || 'Special offer'} />
                )}
              </Box>
              {allImages.length > 1 && (
                <Box sx={{
                  width: '100%', height: '100px', '@media (min-width: 900px)': { maxWidth: '516px' },
                  margin: '0 auto', padding: '8px 0',
                }}>
                  <Swiper
                    spaceBetween={8}
                    slidesPerView={4}
                  >
                      
                    {allImages.slice(0,4).map((img) => (
                      <SwiperSlide key={img.url}>
                        <img src={img.url} className={classes.image}  alt={timeshare.property.name} onClick={() => setMainImage(img.url)} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Box>
              )}
            </Box>
            <Box sx={{
              boxShadow: '0px 0px 0px 1px #E0E0E0', flexGrow: 1, p: 2, borderRadius: '0 0 8px 8px',
              maxWidth: { xs: '100%', md: '500px' }, '@media (min-width: 1240px)': { borderRadius: '0 8px 8px 0' } 
            }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                    <PublicOutlinedIcon />
                    <InfoBlock
                      title="Region"
                        text={(
                          <Typography
                            onClick={() => openWindow(`/timeshares/${timeshare.property.country?.nameSlug}/${timeshare.property.region?.nameSlug}`)}
                            className={classes.link}
                          >
                            {timeshare.property.region?.name}
                          </Typography>
                        )}
                      disablePadding={true}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                    <WavesOutlinedIcon />
                    <InfoBlock title="View" text={timeshare.view} disablePadding={true} />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                    <PlaceOutlinedIcon />
                    <InfoBlock
                      title="Country"
                        text={(
                          <Typography
                            onClick={() => openWindow(`/timeshares/${timeshare.property.country?.nameSlug}`)}
                            className={classes.link}
                          >
                            {timeshare.property.country?.name}
                          </Typography>
                        )}
                      disablePadding={true}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                    <BedOutlinedIcon />
                    <InfoBlock title="Bedrooms" text={timeshare.bedrooms || '-'} disablePadding={true} />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                    <TravelExploreOutlinedIcon />
                    <InfoBlock
                      title={`${getContent('labels').labelDestinationTypeSingularText}(s)`}
                      text={timeshare.property.destinationTypes.map(destinationType => destinationType.name).join(', ')}
                      disablePadding={true}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                    <BathroomOutlinedIcon />
                    <InfoBlock title="Bathrooms" text={timeshare.bathrooms || '-'} disablePadding={true} />
                  </Box>
                </Grid>
                {timeshare.type === TimeshareTypes.Sale && (
                  <Fragment>
                    <Grid item xs={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                        <EventOutlinedIcon />
                        <InfoBlock title="Week" text={timeshare.week || 'Floating'} disablePadding={true} />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                        <BeachAccessOutlinedIcon />
                        <InfoBlock title="Season" text={timeshare.season ? textFromCamelToNormalCase(timeshare.season) : '-'} disablePadding={true} />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                        <HomeWorkOutlinedIcon />
                        <InfoBlock title="Ownership type" text={timeshare.ownershipType || '-'} disablePadding={true} />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                        <CalendarMonthOutlinedIcon />
                        <InfoBlock title="Usage" text={timeshare.usage ? textFromCamelToNormalCase(timeshare.usage) : '-'} disablePadding={true} />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                        <DateRangeOutlinedIcon />
                        <InfoBlock title="Next year usage" text={timeshare.nextUsageYear || '-'} disablePadding={true} />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                        <MonetizationOnOutlinedIcon />
                        <InfoBlock title="Maintenance fees" text={timeshare.maintenanceFees || '-'} disablePadding={true} />
                      </Box>
                    </Grid>
                  </Fragment>
                )}
                <Grid item xs={12}><Divider sx={{ pt: 2 }} /></Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <InfoBlock title="Description" text={timeshare.property.description || '-'} disablePadding={!timeshare.publicNotes} textSx={{ whiteSpace: 'pre-line', textAlign: 'justify' }} />
                  <InfoBlock title="" text={timeshare.publicNotes || ''} disablePadding={true} textSx={{ whiteSpace: 'pre-line', textAlign: 'justify' }} />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Button
            fullWidth
            sx={{ display: { sm: 'none' }, mt: 2 }}
            variant="contained"
            startIcon={<RequestQuoteOutlinedIcon />}
            size="large"
            onClick={openReservationRequestDialog}
          >
            {timeshare.displaySalePrice ? `${isRentTimeshare ? 'Reserve for' : 'Offered at'} ${formatCash(timeshare.salePrice)}` : 'Request a quote'}
          </Button>

          <Box sx={{ py: '40px' }}>
            <TimesharesList title={isRentTimeshare ? 'Other rentals from the same property' : 'Other timeshares from the same property'} timeshareId={timeshare.id} isRentType={isRentTimeshare} />
          </Box>

          <Box sx={{ pt: 5 }}>
            <Divider sx={{ mb: 4 }} />
            <ReservationRequestForm isRent={isRentTimeshare} />
          </Box>
        </div>
      </Box>
    </Fragment>
  )
}

export default TimeshareDetails;

const useStyles = makeStyles({
  mainImageWrapper: {
    position: 'relative',
    flexGrow: 1,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '@media (min-width: 900px)': {
      height: '280px',
    }
  },
  mainImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  image: {
    flexGrow: 1,
    height: '100px',
    objectFit: 'cover',
    cursor: 'pointer',
    '@media (min-width: 900px)': {
      maxWidth: '123px',
    }
  },
  link: {
    color: 'initial',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': {
      color: '#53B8E0'
    }
  }
})
