import { createSlice } from "@reduxjs/toolkit";

interface IState {
  isAuthenticated: null | boolean;
}

const initialState:IState = {
  isAuthenticated: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action: any) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const AuthActions = authSlice.actions;

export default authSlice.reducer;
