import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectAccountsSettings = (state: RootState) => state.accounts.settings;
export const selectAccountsSettingsByDomain = (state: RootState) => state.accounts.byDomain;

export const selectSubscriptionModuleEnabled = createSelector(
  [
    selectAccountsSettingsByDomain,
  ],
  (settings:any) => {
    const subscriptionModuleEnabled = settings?.settings?.find((setting: any) => setting.name === 'subscriptionModuleEnabled')?.value;
    return typeof subscriptionModuleEnabled === 'boolean' ? subscriptionModuleEnabled : null;
  }
);

export const selectGuestModuleEnabled = createSelector(
  [
    selectAccountsSettingsByDomain,
  ],
  (settings:any) => {
    const guestModuleEnabled = settings?.settings?.find((setting: any) => setting.name === 'guestModuleEnabled')?.value;
    return typeof guestModuleEnabled === 'boolean' ? guestModuleEnabled : null;
  }
);
