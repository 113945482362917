import React, { useEffect, useState } from "react";
import classNames from "classnames";
// components
import Title from 'components/Title';
import DestinationTypeCard from "./DestinationTypeCard";
import DestinationTypeCardSkeleton from "./DestinationTypeCard.skeleton";
// models
import { IDestinationTypeGroups } from "models/TimesharesGroup";
// mui
import { makeStyles } from "@mui/styles";
import { Box, IconButton } from "@mui/material";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// Utilities
import { getContent } from 'utilities/Utilities';

type Props = {
  destinationTypeGroups?: IDestinationTypeGroups[],
}

const TopProperties:React.FC<Props> = ({ destinationTypeGroups }) => {
  const classes = useStyles();

  const maxElem = destinationTypeGroups?.length || 5;
  const [elem, setElem] = useState<number>(0);
  const [countElem, setCountElem] = useState<number>(window.innerWidth >= 1900 ? 5 : 4);


  const [countCard, setCountCard] = useState<number>(window.innerWidth >= 900 ? 3.2 : window.innerWidth >= 720 ? 2.6 : window.innerWidth >= 600 ? 2.2 : 1.4);

  const handlerScreenWidth = () => {
    setCountCard(window.innerWidth >= 900 ? 3.2 : window.innerWidth >= 720 ? 2.6 : window.innerWidth >= 600 ? 2.2 : 1.4);
  }

  const onChange = (value: number) => {
    if (value < 0 || value + countElem > maxElem) return;
    setElem(value);
  }
  
  useEffect(() => {
    setElem(0);
  }, [countElem]);

  const handlerCount = () => {
    setCountElem(window.innerWidth >= 1900 ? 5 : 4);
  }

  useEffect(() => {
    window.addEventListener('resize', handlerScreenWidth);
    window.addEventListener('resize', handlerCount);

    return () => {
      window.removeEventListener('resize', handlerScreenWidth);
      window.removeEventListener('resize', handlerCount);
    }
  }, []);
  
  return (
    <Box className={classes.block}>
      <IconButton
        sx={{ display: 'none', '@media (min-width: 1240px)': { display: maxElem <= countElem || !destinationTypeGroups ? 'none' : 'inline-flex' } }}
        className={classNames(classes.button, {
          [classes.buttonDisabled]: elem === 0,
        })}
        disabled={elem === 0}
        onClick={() => onChange(elem - 1)}
      >
        <ArrowBackIosOutlinedIcon />
      </IconButton>
      <div className="big-container" style={{ margin: 0 }}>
        <Title>{`Browse by ${getContent('labels').labelDestinationTypeSingularText}`}</Title>
        {destinationTypeGroups ? (
          <React.Fragment>
            <Box sx={{  display: 'none', padding: '35px 0', gap: '15px', '@media (min-width: 1240px)': { display: 'flex' } }}>
              {destinationTypeGroups?.slice(elem, elem + countElem).map((destinationTypeGroup: any) => (
                <DestinationTypeCard key={destinationTypeGroup.destinationType.id} destinationTypeGroup={destinationTypeGroup} />
              ))}
            </Box>
            
            <Box sx={{ '@media (min-width: 1240px)': { display: 'none' } }}>
              <Swiper
                style={{ padding: '35px 5px' }}
                spaceBetween={15}
                slidesPerView={countCard}
              >
                {destinationTypeGroups?.map((destinationTypeGroup: any) => (
                  <SwiperSlide key={destinationTypeGroup.destinationType.id}>
                    <DestinationTypeCard destinationTypeGroup={destinationTypeGroup} />
                  </SwiperSlide>
    
                ))}
              </Swiper>
            </Box>
          </React.Fragment>
        ) : (
            
          <Swiper
            style={{ padding: '35px 5px' }}
            spaceBetween={15}
            slidesPerView={countCard}
          >
            {[1,2,3,4,5].map(item => (
              <SwiperSlide key={item}>
                <DestinationTypeCardSkeleton />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <IconButton
        sx={{ display: 'none', '@media (min-width: 1240px)': { display: maxElem <= countElem || !destinationTypeGroups ? 'none' : 'inline-flex' } }}
        className={classNames(classes.button, {
          [classes.buttonDisabled]: elem + countElem === maxElem,
        })}
        disabled={elem + countElem === maxElem}
        onClick={() => onChange(elem + 1)}
      >
        <ArrowForwardIosOutlinedIcon />
      </IconButton>
    </Box>
  )
}

export default TopProperties;

const useStyles = makeStyles({
  block: {
    padding: '16px 0 0 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    '@media (min-width: 600px)': {
      padding: '24px 0 0 24px',
    },
    '@media (min-width: 1240px)': {
      padding: '32px 0 0',
    },
  },
  button: {
    width: '48px',
    height: '48px',
    border: '1px solid rgba(0, 0, 0, 0.6)',
    color: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '50%',
  },
  buttonDisabled: {
    border: '1px solid rgba(0, 0, 0, 0.26)',
    color: 'rgba(0, 0, 0, 0.26)',
  },
});
