import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
// Models
import INotification from 'models/Notiifcation';
import TimeshareTypes from "types/TimeshareTypes";
// Services
import StorageService from "services/StorageService";

interface IState {
  notifications: INotification[];
  error: boolean;
  selectedType: TimeshareTypes;
}

const initialState:IState = {
  notifications: [],
  error: false,
  selectedType: StorageService.getSelectedType() || TimeshareTypes.Rent
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setError: (state, action:PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setSelectedType: (state, action:PayloadAction<TimeshareTypes>) => {
      state.selectedType = action.payload;
      StorageService.setSelectedType(action.payload);
    },
    enqueueSnackbar: (state, action:PayloadAction<Omit<INotification, 'key'>>) => {
      state.notifications = [ ...state.notifications, { key: uuid(), ...action.payload } ]
    },
    closeSnackbar: (state, action:PayloadAction<{ key:string, dismissAll:boolean }>) => {
      state.notifications = state.notifications.map((notification:INotification) =>
        (action.payload.dismissAll || notification.key === action.payload.key)
          ? { ...notification, dismissed: true }
          : notification
      )
    },
    removeSnackbar: (state, action:PayloadAction<string>) => {
      state.notifications = state.notifications.filter((notification:INotification) =>
        notification.key !== action.payload
      )
    }
  }
});

export const UiActions = uiSlice.actions;

export default uiSlice.reducer;
