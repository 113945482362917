import { UiActions } from "store/ui/uiSlice";
   
const errorMiddleware = ({ dispatch }:any) => (next:any) => (action:any) => {
  const { type, payload, error } = action;

  if ( type.includes('/rejected') && type !== 'payments/createPayment/rejected' ){
    if (payload.status === 402 ) {
      dispatch(UiActions.setError(true))
    } else {
      dispatch(UiActions.enqueueSnackbar({
        message: payload?.message || error?.message || 'Server error'
      }));
    }
  }

  return next(action);
}

export default errorMiddleware;
