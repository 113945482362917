import React, { useEffect } from 'react';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Actions
import { PaymentsActions } from 'store/payments/paymentsSlice';
// Selectors
import { selectAccountsSettings } from 'store/accounts/accountsSelectors';
import { selectStatus } from 'store/payments/paymentsSelectors';
// Models
import IReservation from 'models/Reservation';
// Types
import Statuses from 'types/Statuses';
// Components
import PaymentSuccess from './PaymentSuccess';
import PaymentRejected from './PaymentRejected';

type Props = {
  onClose: () => void;
  reservation: IReservation;
}

const PaymentForms:React.FC<Props> = ({ onClose, reservation }) => {
  const dispatch = useAppDispatch();

  const accountsSettings = useAppSelector(selectAccountsSettings);
  const status = useAppSelector(selectStatus);

  useEffect(() => {
    return () => {
      dispatch(PaymentsActions.setInitialStatus());
    }
    // eslint-disable-next-line
  }, []);

  if (status === Statuses.Success) return <PaymentSuccess onClose={onClose} reservation={reservation} />;
  if (status === Statuses.Error) return <PaymentRejected onClose={onClose} />;

  if (!accountsSettings.paymentEnabled || !accountsSettings.paymentProvider) return null;
  return (
    <React.Fragment>
      {/* {accountsSettings.paymentProvider === PaymentProviders.Heartland && <HeartlandPaymentForms onClose={onClose} reservationId={reservation.id} />} */}
      {/* {accountsSettings.paymentProvider === PaymentProviders.Iroquois && <IroquoisPaymentForms onClose={onClose} reservationId={reservation.id} />} */}
    </React.Fragment>
  );
}

export default PaymentForms;
