import { useState, Fragment, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
// Hooks
import { useAppDispatch, useAppSelector } from 'hooks/redux';
// Models
import { ISubscriptionBenefitRequest } from 'models/Subscriptions';
import ICalendarSlot from 'models/CalendarSlot';
// Async
import SubscriptionsAsync from 'store/subscriptions/subscriptionsAsync';
import CalendarsAsync from 'store/calendars/calendarsAsync';
// Actions
import { UiActions } from 'store/ui/uiSlice';
// Selectors
import { selectBenefitById } from 'store/users/usersSelectors';
import { selectCalendarsSlotsByDate, selectLastCalendarsSlot } from 'store/calendars/calendarsSelectors';
// Mui
import { StaticDatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import {
  DialogTitle, DialogContent, DialogActions,
  TextField, Button, Chip, Box, Typography, Divider
} from '@mui/material';

interface IFormData {
  ownerNotes: string;
}

type Props = {
  onClose: () => void;
  benefitId?: number;
  benefitRequest?: ISubscriptionBenefitRequest;
}

const dateFormat = 'YYYY-MM-DD';

const BenefitRequestsForm:FC<Props> = ({ onClose, benefitId:propsBenefitId, benefitRequest }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const benefitId = propsBenefitId || benefitRequest?.benefit.id;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>(dayjs(benefitRequest?.scheduledDate).format(dateFormat) || dayjs().format(dateFormat));
  const [selectedSlotId, setSelectedSlotId] = useState<number | null>(benefitRequest?.calendarSlotId || null);
  const handleSelectedDate = (date: string) => setSelectedDate(date);

  const benefit = useAppSelector((state) => benefitId ? selectBenefitById(state, benefitId) : null);
  const calendarSlots = useAppSelector(selectCalendarsSlotsByDate);

  const lastCalendarSlot = useAppSelector(selectLastCalendarsSlot);

  const { control, handleSubmit } = useForm<IFormData>({
    defaultValues: {
      ownerNotes: benefitRequest?.ownerNotes || '',
    }
  });


  const onSubmit = handleSubmit((data:IFormData) => {
    setIsLoading(true);

    const nextData:any = {...data};
    if (selectedSlotId) nextData['calendarSlotId'] = selectedSlotId;

    if (benefitRequest) {
      const newData = { id: benefitRequest.id, ...nextData };
      dispatch(SubscriptionsAsync.updateBenefitRequest(newData))
        .unwrap()
        .then(() => {
          dispatch(UiActions.enqueueSnackbar({ message: 'Benefit claim was updated' }));
          onClose();
        })
        .finally(() => setIsLoading(false));
    } else {
      dispatch(SubscriptionsAsync.createBenefitRequest({ benefitId, ...nextData }))
        .unwrap()
        .then(() => {
          dispatch(UiActions.enqueueSnackbar({ message: 'Benefit claim was created' }));
          navigate('/benefit-claims');
          onClose();
        })
        .finally(() => setIsLoading(false));
    }
  });

  useEffect(() => {
    if (benefitId && benefit?.calendarAvailable) {
      const params:any = { benefitId };
      if (benefitRequest) params['benefitRequestId'] = benefitRequest.id;
      dispatch(CalendarsAsync.fetchCalendarsSlots(params));
    }
    // eslint-disable-next-line
  }, [benefitId]);

  return (
    <Fragment>
      <DialogTitle>{`${benefitRequest ? 'Edit claim' : 'Claim benefit'} `}</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, mb: 2 }}>
          <img
            style={{ width: '80px', height: '80px', objectFit: 'cover', border: '1px solid #eee', borderRadius: '100%' }}
            src={benefit?.image ? benefit.image.url : '/img/noImageAvailable.png'}
            alt={benefit?.name}
          />
          <Typography sx={{ fontSize: '18px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.87)' }}>{benefit?.name}</Typography>
        </Box>
        <Divider sx={{ mb: 2 }} />
        {!Object.keys(calendarSlots).length && benefit?.calendarAvailable ? (
          <Typography textAlign="center">{`No free slot(s) for claim`}</Typography>
        ) : (
          <>
            {benefit?.calendarAvailable && selectedDate && !!Object.keys(calendarSlots).length && (
              <Fragment>
                <StaticDatePicker
                  value={dayjs(selectedDate)}
                  onChange={(date: Dayjs | null) => handleSelectedDate(dayjs(date).format(dateFormat))}
                  disablePast={true}
                  shouldDisableDate={(date: Dayjs) => !calendarSlots[dayjs(date).format(dateFormat)] }
                  maxDate={dayjs(lastCalendarSlot?.start)}
                  displayStaticWrapperAs="desktop"
                />
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
                  {calendarSlots[selectedDate]?.map((slot:ICalendarSlot) => {
                    const startTime:string = dayjs(slot.start).format('hh:mm A');
                    return (
                      <Chip
                        key={startTime}
                        label={startTime}
                        color="secondary"
                        variant={selectedSlotId === slot.id ? 'filled' : 'outlined'}
                        onClick={() => setSelectedSlotId(slot.id)}
                      />
                    )
                  })}
                </Box>
                <Divider sx={{ mb: 2 }} />
              </Fragment>
            )}
            <form onSubmit={onSubmit} noValidate>
                {/* ownerNotes */}
                <Controller
                  control={control} name="ownerNotes"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Notes"
                      type="text"
                      multiline
                      rows={3}
                      helperText="Specify preferred dates and times or any other details"
                    />
                  )}
                />
            </form>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ width: '100%' }}>
        <Button
          variant="text"
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={benefit?.calendarAvailable ? !selectedSlotId : false}
        >
          {benefitRequest ? 'Save' : 'Claim' }
        </LoadingButton>
      </DialogActions>
    </Fragment>
  )
}

export default BenefitRequestsForm;
