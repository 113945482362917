import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import CountriesAsync from "./countriesAsync";
// Models
import ICountry from "models/Country";

interface IState {
  countries: ICountry[] | null;
  country: ICountry | null;
}

const initialState:IState = {
  countries: null,
  country: null,
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch countries
      .addCase(CountriesAsync.fetchCountries.fulfilled, (state, action:PayloadAction<ICountry[]>) => {
        state.countries = action.payload;
      })
      // Fetch country by slug
      .addCase(CountriesAsync.fetchCountryBySlug.fulfilled, (state, action:PayloadAction<ICountry>) => {
        state.country = action.payload;
      })
  }
});

export const countriesActions = countriesSlice.actions;

export default countriesSlice.reducer;
