import { createAsyncThunk } from "@reduxjs/toolkit";
import AssetsAsync from "store/assets/assetsAsync";
import { UiActions } from "store/ui/uiSlice";
import AssetContractType from "types/AssetContractType";
import AssetType from "types/AssetType";
// Utilities
import HttpClient from 'utilities/HttpClient';
import { getContent } from "utilities/Utilities";

const { accountId } = getContent('settings');

export default class TimesharesAsync {
  private static _url:string = '/client/timeshares';
  private static _http:HttpClient = new HttpClient();

  // Fetch timeshares (search)
  public static fetchTimeshares = createAsyncThunk('timeshares/fetchTimeshares', async (params:any, thunkApi) => {
    const nextParams = { accountId, ...params };
    const response:Response = await this._http.get(`${this._url}/search`, nextParams);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch timeshares by property id
  public static fetchTimesharesByProperty = createAsyncThunk('timeshares/fetchTimesharesByProperty', async (params:any, thunkApi) => {
    const nextParams = { accountId, ...params };
    const response:Response = await this._http.get(`${this._url}/searchByProperty`, nextParams);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch timeshare (by id)
  public static fetchTimeshare = createAsyncThunk('timeshares/fetchTimeshare', async (timeshareId:number, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/${timeshareId}`);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch my timeshares
  public static fetchMyTimeshares = createAsyncThunk('timeshares/fetchMyTimeshares', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(this._url, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Create timeshare
  public static createTimeshares = createAsyncThunk('timeshares/createTimeshares', async (data:any, thunkApi) => {
    const nextData = { ...data };
    // Validate contracts
    const { payload:ownerDocumentIds}:any = await thunkApi.dispatch(AssetsAsync.validateAssets({ type: AssetType.Contracts, contractType: AssetContractType.Owner, publiclyAccessible: false }));
    if ( ownerDocumentIds && ownerDocumentIds.length ) nextData['ownerDocumentsIds'] = ownerDocumentIds.map((document: any) => document.id);
  
    const response:Response = await this._http.post(this._url, nextData);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    thunkApi.dispatch(UiActions.enqueueSnackbar({ message: 'Timeshare has been created' }));
    return response.json();
  });

  // Update timeshare
  public static updateTimeshares = createAsyncThunk('timeshares/updateTimeshares', async (timeshareData:any, thunkApi) => {
    const { id, ...data } = timeshareData;
    const nextData = { ...data };
    // // Validate contracts
    const { payload:ownerDocumentIds}:any = await thunkApi.dispatch(AssetsAsync.validateAssets({ type: AssetType.Contracts, contractType: AssetContractType.Owner, publiclyAccessible: false }));
    if ( ownerDocumentIds && ownerDocumentIds.length ) nextData['ownerDocumentsIds'] = ownerDocumentIds.map((document: any) => document.id);
  
    const response:Response = await this._http.put(`${this._url}/${id}`, nextData);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    thunkApi.dispatch(UiActions.enqueueSnackbar({ message: 'Timeshare has been updated' }));
    return response.json();
  });


  // Delete timeshare
  public static deleteTimeshares = createAsyncThunk('timeshares/deleteTimeshares', async (timeshareId:number, thunkApi) => {
    const response:Response = await this._http.delete(`${this._url}/${timeshareId}`);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    thunkApi.dispatch(UiActions.enqueueSnackbar({ message: 'Timeshare has been deleted' }));
    // return response.json();
 
  });

  // Fetch timeshares / groups
  public static fetchTimesharesGroups = createAsyncThunk('timeshares/fetchTimeshares/groups', async (params:any, thunkApi) => {
    const nextParams = { accountId, ...params };
    const response:Response = await this._http.get(`${this._url}/groups`, nextParams);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });
}
