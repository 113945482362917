import React, { useState } from 'react';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Async
import NotificationsAsync from 'store/notifications/notificationsAsync';
// Actions
import { NotificationsActions } from 'store/notifications/notificationsSlice';
// Selectors
import { selectSelectedNotificationsIds } from 'store/notifications/notificationsSelectors';
// Models
import INotification from 'models/Notification';
// Types
import NotificationSentimentType from 'types/NotificationSentimentType';
// MUI
import { makeStyles } from '@mui/styles';
import {
  Card, Grid, IconButton, Typography, Box, Tooltip, Checkbox
} from '@mui/material';
import {
  Close as CloseIcon,
  Circle as CircleIcon,
  InfoOutlined as InfoOutlinedIcon,
  TaskAltOutlined as TaskAltOutlinedIcon,
  HighlightOffOutlined as HighlightOffOutlinedIcon,
} from '@mui/icons-material';
// Components
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
// Utilities
import { getContent } from 'utilities/Utilities';

type Props = {
  notification: INotification,
};

const NotificationCard:React.FC<Props> = ({ notification }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const selectedNotificationsIds = useAppSelector(selectSelectedNotificationsIds);

  const selectNotification = (notificationId: number) => {
    dispatch(NotificationsActions.selectNotification(notificationId));
  }

  // handle remove modal
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const handleOpenRemoveModal = (event: any) => {
    event.stopPropagation();
    setOpenRemoveModal(true);
  }

  const handleCloseRemoveModal = () => {
    setOpenRemoveModal(false);
  }

  const handleRemoveNotification = () => {
    dispatch(NotificationsAsync.deleteNotifications([notification.id]))
      .unwrap()
      .then(() => handleCloseRemoveModal())
  }

  const markAsRead = () => {
    dispatch(NotificationsAsync.markAsReadNotifications([notification.id]));
  }

  return (
    <React.Fragment>
      <ConfirmDeleteModal open={openRemoveModal} onClose={handleCloseRemoveModal} handleRemove={handleRemoveNotification} title="notification" />

      <Card className={classes.card} sx={{ background: notification.read ? '#fff' : `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${getContent('theme').primaryColor}` }}>
        <Grid container spacing={2}>
          <Grid item xs={4} md={1}>
            <Checkbox checked={selectedNotificationsIds.includes(notification.id)} onChange={() => selectNotification(notification.id)} />
          </Grid>
          <Grid item xs={12} md={9} sx={{ display: 'flex', alignItems: 'center', gap: '18px', order: { xs: 1, md: 0 } }}>
            {notification.sentimentType === NotificationSentimentType.Positive && <TaskAltOutlinedIcon color="success" />}
            {notification.sentimentType === NotificationSentimentType.Neutral && <InfoOutlinedIcon color="secondary" />}
            {notification.sentimentType === NotificationSentimentType.Negative && <HighlightOffOutlinedIcon color="error" />}

            <Typography className={classes.text}>{notification.text}</Typography>
          </Grid>
          <Grid item xs={8} md={2}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 0.5 }}>
              {!notification.read && (
                <Tooltip title="Mark as read" onClick={markAsRead} sx={{ cursor: 'pointer' }}>
                  <CircleIcon fontSize="small" color="secondary" />
                </Tooltip>
              )}
              <Tooltip title="Delete notification">
                <IconButton
                  onClick={handleOpenRemoveModal}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}

export default NotificationCard;

const useStyles = makeStyles({
  card: {
    width: '100%',
    padding: '16px',
    borderRadius: 0,
    borderBottom: '1px solid #eee',
  },
  text: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    color: 'rgba(0, 0, 0, 0.87)',
  }
});
