import React from 'react';
import { Navigate } from "react-router-dom";
// Hooks
import { useAppSelector } from 'hooks/redux';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';

type Props = {
  children: JSX.Element;
}

const PrivateRoute:React.FC<Props> = ({ children }) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  if ( !isAuthenticated ) return <Navigate to="/" replace />;
  return children;
}

export default PrivateRoute;
