
import React from 'react';
// components
import Title from 'components/Title';
// mui
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const ListYourPropertyHowWorks:React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.block} data-container="how-it-works">
      <div className="container">
        <Title>How it works</Title>
        <Box sx={{ pt: { xs: 2, sm: 4, lg: 8 }, display: 'flex', flexDirection: {xs:'column', md: 'row'}, gap: { xs: 2, md: 5 } }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 2, lg: 5 } }}>
            <Box className={classes.card}>
              <img src="/img/icons/listYourProperty/howItWorks/1.svg" alt="1" className={classes.icon} />
              <Box>
                <Typography className={classes.title}>Send us your rental information</Typography>
              </Box>
            </Box>
            <Box className={classes.card}>
              <img src="/img/icons/listYourProperty/howItWorks/2.svg" alt="2" className={classes.icon} />
              <Box>
                <Typography className={classes.title}>We will confirm your rental with the resort</Typography>
              </Box>
            </Box>
            <Box className={classes.card}>
              <img src="/img/icons/listYourProperty/howItWorks/3.svg" alt="3" className={classes.icon} />
              <Box>
                <Typography className={classes.title}>We will advertise your rental at the competitive market value</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 2, lg: 5 } }}>
            <Box className={classes.card}>
              <img src="/img/icons/listYourProperty/howItWorks/4.svg" alt="4" className={classes.icon} />
              <Box>
                <Typography className={classes.title}>Once we have a committed guest, we will prepare the rental agreements for both parties</Typography>
              </Box>
            </Box>
            <Box className={classes.card}>
              <img src="/img/icons/listYourProperty/howItWorks/5.svg" alt="5" className={classes.icon} />
              <Box>
                <Typography className={classes.title}>We collect payment from the guest in full, and forward the guest information on to you to add to the reservation</Typography>
              </Box>
            </Box>
            <Box className={classes.card}>
              <img src="/img/icons/listYourProperty/howItWorks/6.svg" alt="6" className={classes.icon} />
              <Box>
                <Typography className={classes.title}>Payment in full is sent</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
  )
}

export default ListYourPropertyHowWorks;

const useStyles = makeStyles({
  block: {
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '64px 0',
    },
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    width: '100%',
    '@media (min-width: 1104px)': {
      width: '500px',
    }
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
  },
  icon: {
    width: '48px',
    height: '48px',
    '@media (min-width: 600px)': {
      width: '72px',
      height: '72px',
    },
    '@media (min-width: 1240px)': {
      width: '88px',
      height: '88px',
    },
  },
});
