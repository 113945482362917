import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
import LandingPagesAsync from "store/landingPages/landingPagesAsync";
import TimesharesAsync from "store/timeshares/timesharesAsync";
import PropertiesAsync from "store/properties/propertiesAsync";
import { landingPagesActions } from "store/landingPages/landingPagesSlice";
import { TimesharesActions } from "store/timeshares/timesharesSlice";
import { selectProperty } from "store/properties/propertiesSelectors";
import { selectSelectedType } from "store/ui/uiSelectors";
import IProperty from "models/Property";
import TimeshareTypes from "types/TimeshareTypes";
// MUI
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
// Components
import SearchBanner from "components/SearchBanner";
import ReservationRequestForm from "components/ReservationRequest.form";
import Title from "components/Title";
import { InfoBlock, Loader } from "components/Utilities";
import LandingPage from "components/LandingPage";
import { ImageOutlined, PlaceOutlined, PublicOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import useDialog from "hooks/useDialog";
import PropertyImages from "components/PropertyImages";
import TimesharesList from "components/TimesharesList";
// Utilities
import { openWindow } from "utilities/Utilities";
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const PropertyPage:FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { propertyNameSlug } = useParams();

  const property = useAppSelector(selectProperty);
  const selectedType = useAppSelector(selectSelectedType);

  const isRentType = selectedType === TimeshareTypes.Rent;

  const propertyImages = useMemo(() => {
    return property?.images || [];
  }, [property]);

  const [mainImage, setMainImage] = useState('');

  const fetchProperty = () => {
    if (!propertyNameSlug) {
      navigate(`/`);
      return;
    }
    dispatch(PropertiesAsync.fetchPropertyBySlug(propertyNameSlug)).unwrap()
      .then((property:IProperty) => {
        dispatch(TimesharesAsync.fetchTimesharesByProperty({ propertyId: property.id, type: selectedType }));
        if (property.landingPageId) dispatch(LandingPagesAsync.fetchLandingPage(property.landingPageId));
      })
      .catch(() => {
        navigate(`/`);
      });
  }

  useEffect(() => {
    if (!property) return;
    const propertyImage = property.images ? property.images[0].url : '/img/noPropertyFound.png';
    setMainImage(propertyImage);
  }, [property]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    fetchProperty();

    return () => {
      dispatch(TimesharesActions.setParams({}));
      dispatch(landingPagesActions.setInitialField('landingPage'));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!property) return;
    dispatch(TimesharesAsync.fetchTimesharesByProperty({ propertyId: property.id, type: selectedType }));
    // eslint-disable-next-line
  }, [selectedType])

  const { Dialog, openDialog, closeDialog } = useDialog();

  if (!property) return <Loader />;
  return (
    <Fragment>
      <Dialog maxWidth="lg">
        <PropertyImages onClose={closeDialog} images={propertyImages} />
      </Dialog>

      <SearchBanner />
      <Box sx={{ backgroundColor: '#f5f6f7' }}>
        <div className="container">
          <Box sx={{
            padding: '20px 16px',
            '@media (min-width: 600px)': {
              padding: '40px 32px 56px'
            },
            '@media (min-width: 1240px)': {
              padding: '40px 0 56px'
            }
          }}>
            <Title>{property.name}</Title>
            
            <Box sx={{
              display: 'flex', mt: 3, flexDirection: { xs: 'column', md: 'row' },
              backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)'
            }}>
              <Box sx={{ position: 'relative', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                {propertyImages.length > 1 && (
                  <Button
                    sx={{
                      position: 'absolute', top: '16px', right: '16px', backgroundColor: '#fff', color: '#222', zIndex: 2,
                      '&:hover': { backgroundColor: '#fafafa', boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.3)' }
                    }}
                    size="small"
                    startIcon={<ImageOutlined />}
                    onClick={openDialog}
                  >
                    View all photos
                  </Button>
                )}
                <Box
                  className={classes.mainImageWrapper}
                  sx={{ borderRadius: { xs: '8px 8px 0 0', md: propertyImages.length > 1 ? '8px 0 0 0' : '8px 0 0 8px' } }}
                >
                  <img src={mainImage} className={classes.mainImage} alt={property.name} />
                </Box>
                {propertyImages.length > 1 && (
                  <Box sx={{
                    width: '100%', height: '100px', '@media (min-width: 900px)': { maxWidth: '516px' },
                    margin: '0 auto', padding: '8px 0',
                  }}>
                    <Swiper
                      spaceBetween={8}
                      slidesPerView={4}
                    >
                        
                      {propertyImages.slice(0,4).map((img) => (
                        <SwiperSlide key={img.url}>
                          <img src={img.url} className={classes.image} alt="" onClick={() => setMainImage(img.url)} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                )}
              </Box>
              <Box sx={{
                boxShadow: '0px 0px 0px 1px #E0E0E0', flexGrow: 1, p: 2, borderRadius: '0 0 8px 8px',
                maxWidth: { xs: '100%', md: '500px' }, '@media (min-width: 1240px)': { borderRadius: '0 8px 8px 0' } 
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                      <PublicOutlined />
                      <InfoBlock
                        title="Region"
                        disablePadding={true}
                        text={(
                          <Typography
                            onClick={() => openWindow(`/timeshares/${property.country?.nameSlug}/${property.region?.nameSlug}`)}
                            className={classes.link}
                          >
                            {property.region?.name}
                          </Typography>
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                      <PlaceOutlined />
                      <InfoBlock
                        title="Country"
                        disablePadding={true}
                        text={(
                          <Typography
                            onClick={() => openWindow(`/timeshares/${property.country?.nameSlug}`)}
                            className={classes.link}
                          >
                            {property.country?.name}
                          </Typography>
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}><Divider sx={{ pt: 2 }} /></Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <InfoBlock title="Description" text={property.description} disablePadding={true} textSx={{ whiteSpace: 'pre-line', textAlign: 'justify' }} />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box sx={{ py: '40px' }}>
              <TimesharesList title={isRentType ? 'Available rentals' : 'Now for sale'} isRentType={isRentType} />
            </Box>
            <LandingPage />
            <ReservationRequestForm />
          </Box>
        </div>
      </Box>
    </Fragment>
  );
}
 
export default PropertyPage;

const useStyles = makeStyles({
  mainImageWrapper: {
    position: 'relative',
    flexGrow: 1,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '@media (min-width: 900px)': {
      height: '280px',
    }
  },
  mainImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  image: {
    flexGrow: 1,
    height: '100px',
    objectFit: 'cover',
    cursor: 'pointer',
    '@media (min-width: 900px)': {
      maxWidth: '123px',
    }
  },
  link: {
    color: 'initial',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': {
      color: '#53B8E0'
    }
  }
})
