import { FC, useState, DragEvent, ReactNode, useMemo } from 'react';
// Mui
import { Box } from '@mui/material';

type Props = {
  multiple: boolean;
  onDrop: (files:FileList) => void;
  children?: ReactNode;
}

const AssetsDragAndDrop:FC<Props> = ({ multiple, onDrop, children }) => {
  const [ dragging, setDragging ] = useState(false);

  const handleDrag = (event:DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }

  const handleDragEnter = (event:DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setDragging(true);
  }

  const handleDragLeave = (event:DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setDragging(false);
  }

  const handleDrop = (event:DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setDragging(false);

    if ( event.dataTransfer.files && event.dataTransfer.files.length ){
      onDrop(event.dataTransfer.files);
      event.dataTransfer.clearData()
    }
  }

  const sx = useMemo(() => {
    let styles = {
      width: '100%',
      bgcolor: dragging ? '#ededed' : 'white',
      p: 1
    };
    if ( multiple ) return { ...styles,
      minHeight: 260,
      maxHeight: 480,
      border: '1px solid rgba(0,0,0,0.23)',
      borderRadius: '4px',
      overflowY: 'auto'
    };
    return { ...styles,
      borderBottom: '1px solid rgba(0,0,0,0.23)'
    }
    // eslint-disable-next-line
  }, [dragging]);

  return (
    <Box
      sx={sx}
      onDragOver={handleDrag}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >{children}</Box>
  )
}

export default AssetsDragAndDrop;
