import React from "react";
// Hooks
import useDialog from "hooks/useDialog";
// Models
import { ISubscriptionBenefit } from "models/Subscriptions";
// Components
import BenefitInfo from "./BenefitInfo";
// MUI
import { makeStyles } from "@mui/styles";
import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";

type Props = {
  benefit: ISubscriptionBenefit,
}

const BenefitCard:React.FC<Props> = ({ benefit }) => {
  const classes = useStyles();

  const imgUrl = benefit.image ? benefit.image.url : '/img/noImageAvailable.png';

  const { Dialog, openDialog, closeDialog } = useDialog();

  return (
    <React.Fragment>
      <Dialog maxWidth="sm">
        <BenefitInfo onClose={closeDialog} benefit={benefit} />
      </Dialog>

      <Card className={classes.card} onClick={openDialog}>
        <div className={classes.gradient} />
        <img src={imgUrl} style={{ width: '100%', height: '260px', objectFit: 'cover', borderBottom: '1px solid #eee' }} alt={benefit.name} />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Tooltip title={benefit.name} arrow>
              <Typography className={classes.title}>
                {benefit.name}
              </Typography>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default BenefitCard;

const useStyles = makeStyles({
  card: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    flexGrow: 1,
  },
  title: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '-0.35px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: '120px',
    left: 0,
    top: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
  },
});
