import INotification from 'models/Notification';
import { RootState } from 'store';

export const selectAllNotifications = (state:RootState) => state.notifications.notifications;
export const selectTotal = (state: RootState) => state.notifications.total;
export const selectSelectedNotificationsIds = (state: RootState) => state.notifications.selectedNotificationsIds;
export const selectIsUnreadNotifications = (state: RootState) => state.notifications.notifications?.some(notification => !notification.read) || false;

export const selectUnreadNotificationsIds = (state:RootState) =>
  state.notifications.notifications?.filter((notification: INotification) => !notification.read)
  .map((notification: INotification) => notification.id);
