import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import IReservation from "models/Reservation";
// Async
import reservationsAsync from "./reservationsAsync";

interface IState {
  reservations: IReservation[] | null;
  total: number;
}

const initialState:IState = {
  reservations: null,
  total: 0,
};

const reservationsSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    markAsPaidReservation: (state, action:PayloadAction<number>) => {
      state.reservations = state.reservations?.map((reservation: IReservation) => {
        return reservation.id === action.payload ? {...reservation, guestPaymentDate: dayjs().format('YYYY-MM-DD')} : reservation;
      }) || []
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch reservations
      .addCase(reservationsAsync.fetchReservations.pending, (state) => {
        state.reservations = null;
      })
      .addCase(reservationsAsync.fetchReservations.fulfilled, (state, action:PayloadAction<any>) => {
        state.reservations = action.payload.data;
        state.total = action.payload.total;
      })
  }
});

export const reservationsActions = reservationsSlice.actions;

export default reservationsSlice.reducer;
