import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import CalendarsAsync from "./calendarsAsync";
// Models
import ICalendarSlot from "models/CalendarSlot";
import IDailyBenefits from "models/DailyBenefits";

interface IState {
  calendarsSlots: ICalendarSlot[] | null,
  dailyBenefits: IDailyBenefits | null,
}

const initialState:IState = {
  calendarsSlots: null,
  dailyBenefits: null,
};

const calendarsSlice = createSlice({
  name: 'calendars',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch calendars slots
      .addCase(CalendarsAsync.fetchCalendarsSlots.pending, (state) => {
        state.calendarsSlots = null;
      })
      .addCase(CalendarsAsync.fetchCalendarsSlots.fulfilled, (state, action:PayloadAction<any>) => {
        state.calendarsSlots = action.payload;
      })
      // Fetch calendars slots
      .addCase(CalendarsAsync.fetchDailyBenefits.pending, (state) => {
        state.dailyBenefits = null;
      })
      .addCase(CalendarsAsync.fetchDailyBenefits.fulfilled, (state, action:PayloadAction<any>) => {
        state.dailyBenefits = action.payload;
      })
  }
});

export const CalendarsActions = calendarsSlice.actions;

export default calendarsSlice.reducer;
