enum ViewTypes {
  Beach = 'Beach',
  Courtyard = 'Courtyard',
  Garden = 'Garden',
  GulfFront = 'Gulf Front',
  GulfSide = 'Gulf Side',
  Island = 'Island',
  Marina = 'Marina',
  Mountain = 'Mountain',
  Ocean = 'Ocean',
  OceanSide = 'Ocean Side',
  OceanFront = 'Ocean Front',
  Pool = 'Pool',
  Resort = 'Resort',
  Varies = 'Varies',
  Water = 'Water',
}

export default ViewTypes;
