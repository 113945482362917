import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from "dayjs";
dayjs.extend(weekOfYear);

const DATE_FORMAT:string = 'MMM D, YYYY';

export const formatStartEndDate = (startDate:string, endDate:string):string => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const isSameYear = start.isSame(end, 'year');
  const isSameMonth = start.isSame(end, 'month');
  const isSameDay = start.isSame(end, 'day');

  const startFormat = isSameMonth ? 'MMM D' : DATE_FORMAT;
  const endFormat = isSameMonth ? 'D, YYYY' : DATE_FORMAT;

  return isSameYear && isSameMonth && isSameDay
    ? start.format(DATE_FORMAT)
    : `${start.format(startFormat)} - ${end.format(endFormat)}`
  ;
}

export const formatDate = (date:string):string => {
  return date ? dayjs(date).format('MMM DD, YYYY') : '-';
}

export const formatDateTime = (date:string):string => {
  return date ? dayjs(date).format('MMM DD, YYYY hh:mm A') : '-';
}

export const formatDateForMessages = (date:string) => {
  return dayjs().isSame(dayjs(date), 'day') ? 'Today' : dayjs().week() === dayjs(date).week() ? dayjs(date).format('dddd') : dayjs(date).format('MMM DD, YYYY');
}
