import { FC } from 'react';
// Mui
import { SxProps, Typography } from '@mui/material';

type Props = {
  children: string;
  style?: SxProps;
}

const Title:FC<Props> = ({ children, style }) => {
  return (
    <Typography
      variant="h1"
      sx={{
        color: 'rgba(0,0,0,0.87)',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '116.7%',
        letterSpacing: '-0.5px',
        '@media (min-width: 600px)': {
          fontSize: '24px',
        },
        '@media (min-width: 1240px)': {
          fontSize: '40px',
        },
        ...style
      }}
    >
      {children}
    </Typography>
  )
}

export default Title;
