import React from 'react';
// Redux
import { useAppSelector } from 'hooks/redux';
import { selectSelectedType } from 'store/ui/uiSelectors';
import TimeshareTypes from 'types/TimeshareTypes';
// MUI
import { makeStyles } from '@mui/styles';
import { Paper, Typography } from '@mui/material';
import { EmailOutlined as EmailOutlinedIcon, PhoneEnabledOutlined as PhoneEnabledOutlinedIcon } from '@mui/icons-material';
// Utilities
import { getContent } from 'utilities/Utilities';

const GetInTouch:React.FC = () => {
  const classes = useStyles();
  const { phone, phoneLabel, rentEmail, saleEmail } = getContent('contacts');

  const selectedType = useAppSelector(selectSelectedType);

  const email = selectedType === TimeshareTypes.Rent ? rentEmail : saleEmail;

  return (
    <Paper className={classes.block}>
      <Typography className={classes.title}>Get in touch</Typography>
        <Typography sx={{ display: 'flex', gap: 2, alignItems: 'center', pb: 3 }}>
          <EmailOutlinedIcon />
          <Typography component="a" href={`mailto:${email}`} className={classes.link}>{email}</Typography>
        </Typography>
        <Typography sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <PhoneEnabledOutlinedIcon />
          <Typography component="a" href={`tel:${phone}`} className={classes.link}>{phoneLabel}</Typography>
        </Typography>
    </Paper>
  )
}

export default GetInTouch;

const useStyles = makeStyles({
  block: {
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    padding: '16px',
    '@media (min-width: 1240px)': {
      padding: '24px',
    },
  },
  title: {
    paddingBottom: '16px',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '-0.35px',
    '@media (min-width: 1240px)': {
      fontSize: '24px',
      lineHeight: '133.4%',
      letterSpacing: '-0.5px',
    },
  },
  link: {
    fontSize: '16px',
    color: 'rgba(0,0,0,0.87)',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': {
      color: getContent('theme').primaryColor,
    }
  },
});
