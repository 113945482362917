import { createAsyncThunk } from "@reduxjs/toolkit";
// Actions
import { TimesharesActions } from 'store/timeshares/timesharesSlice';
// Utilities
import HttpClient from 'utilities/HttpClient';
import { getContent } from "utilities/Utilities";

const { accountId } = getContent('settings');

export default class PropertiesAsync {
  private static _url:string = '/client/properties';
  private static _http:HttpClient = new HttpClient();

  // Fetch properties
  public static fetchProperties = createAsyncThunk('properties/fetchProperties', async (params:any, thunkApi) => {
    const nextParams = { accountId, ...params };
    const response:Response = await this._http.get(this._url, nextParams);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch property bu slug
  public static fetchPropertyBySlug = createAsyncThunk('properties/fetchPropertyBySlug', async (nameSlug:string, thunkApi) => {
    const params = { accountId, nameSlug };
    const response:Response = await this._http.get(`${this._url}/getByNameSlug`, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Create property
  public static createProperty = createAsyncThunk('properties/createProperty', async (data:any, thunkApi) => {
    const response:Response = await this._http.post(this._url, data);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    const property = await response.json();
    thunkApi.dispatch(TimesharesActions.setFormData(property))
  });
}
