import { FC, useEffect } from 'react';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Async
import SubscriptionsAsync from 'store/subscriptions/subscriptionsAsync';
// Selectors
import { selectSubscriptionPackages } from 'store/subscriptions/subscriptionsSelectors';
// Models
import { ISubscriptionPackage } from 'models/Subscriptions';
// MUI
import { Box } from '@mui/material';
// Components
import Title from 'components/Title';
// Utilities
import SubscriptionPackageCard from './SubscriptionPackageCard';

const SubscriptionPackages:FC = () => {
  const dispatch = useAppDispatch();

  const subscriptionPackages = useAppSelector(selectSubscriptionPackages);

  useEffect(() => {
    dispatch(SubscriptionsAsync.fetchSubscriptionPackages({}));
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ pt: 5 }}>
      <Title style={{ textAlign: 'center', color: '#fefefe' }}>Choose your plan</Title>
      <Box sx={{ pt: 2, display: 'flex', gap: '40px', flexWrap: 'wrap', justifyContent: 'center' }}>
        {subscriptionPackages?.map((subscriptionPackage: ISubscriptionPackage) => (
          <SubscriptionPackageCard
            key={subscriptionPackage.id}
            subscriptionPackage={subscriptionPackage}
          />
        ))}
      </Box>
    </Box>
  );
}

export default SubscriptionPackages;
