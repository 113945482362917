import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
// Selectors
import { selectGuestModuleEnabled } from 'store/accounts/accountsSelectors';
// MUI
import {
  Box, Drawer, List, Divider, ListItem, ListItemButton,
  ListItemText, ListItemIcon, IconButton
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
// Utilities
import { getContent } from 'utilities/Utilities';

type Props = {
  open: boolean,
  onClose: () => void,
}

const Menu:React.FC<Props> = ({ open, onClose }) => {
  const guestModuleEnabled = useAppSelector(selectGuestModuleEnabled);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1300, '@media (min-width: 1240px)': { display: 'none' } }}
    >
      <Box
        sx={{ width: '360px' }}
        role="presentation"
      >
        <Box sx={{ display: 'flex', alignItems: 'center', p: 2, pl: 3, gap: 1 }}>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
          <Box
            component={NavLink} to="/" onClick={onClose}
            sx={{ borderRight: '1px solid rgba(255,255,255,0.3)' }}
          >
            <img src={getContent('common').logoSrc} alt={getContent('common').name} height="32px" />
          </Box>
        </Box>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton sx={{ pl: 3 }} component={NavLink} to="/about-us" onClick={onClose}>
              <ListItemText primary="About" />
              <ListItemIcon>
                <ArrowForwardIosIcon fontSize="small" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        {guestModuleEnabled && [
          <List key="contact-us">
            <ListItem disablePadding>
              <ListItemButton sx={{ pl: 3 }} component={NavLink} to="/contact-us" onClick={onClose}>
                <ListItemText primary="Contact us" />
                <ListItemIcon>
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>,
          <Divider key="contact-us-divider" />
        ]}
      </Box>
    </Drawer>
  );
}

export default Menu;
