import { FC, Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from 'hooks/useDialog';
// Async
import UsersAsync from 'store/users/usersAsync';
// Selectors
import { selectSubscriptionModuleEnabled } from 'store/accounts/accountsSelectors';
// Types
import UserRoles from 'types/UserRoles';
// Components
import Phone, { getCallingCode, getPhoneWithoutCallingCode } from 'components/Phone';
import SubscriptionPackages from 'components/SubscriptionPackages';
import ConfirmCard from 'components/ConfirmCard';
// Mui
import { Box, IconButton, Paper, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close as CloseIcon } from '@mui/icons-material';
// Validation
import { isFieldRequired, isEmailValid } from '../../utilities/Validation';
// Utilities
import { getContent } from 'utilities/Utilities';

interface IFormData {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  callingCode: string;
  role: UserRoles;
  notificationsEnabled: boolean;
  subscriptionPackageId?: number | null;
}

type Props = {
  subscriptionPackageId?: number;
  onClose?: () => void;
}

const ListYourPropertyForm:FC<Props> = ({ subscriptionPackageId, onClose }) => {
  const dispatch = useAppDispatch();

  const subscriptionModuleEnabled = useAppSelector(selectSubscriptionModuleEnabled);

  const { Dialog, openDialog, closeDialog } = useDialog();
  const { Dialog:SubscriptionPackagesDialog, openDialog:openSubscriptionPackagesDialog, closeDialog:closeSubscriptionPackagesDialog } = useDialog();

  const handleCloseDialog = () => {
    closeDialog();
    if (onClose !== undefined) onClose();
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { control, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm<IFormData>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      callingCode: '',
      role: UserRoles.Owner,
      notificationsEnabled: true,
      subscriptionPackageId: subscriptionPackageId || null,
    }
  });

  const subscriptionPackageIdWatcher = watch('subscriptionPackageId');

  const setSubscriptionPackageId = (subscriptionPackageId: number) => {
    setValue('subscriptionPackageId', subscriptionPackageId);
    closeSubscriptionPackagesDialog();
  }

  const onSubmit = handleSubmit((data:IFormData) => {
    if (subscriptionModuleEnabled && !subscriptionPackageIdWatcher) {
      openSubscriptionPackagesDialog();
      return;
    }
    setIsLoading(true);

    const { callingCode, phone } = data;
    const nextData = {
      ...data,
      callingCode: getCallingCode(callingCode),
      phone: getPhoneWithoutCallingCode(callingCode, phone)
    }

    dispatch(UsersAsync.registrationRequest(nextData))
      .unwrap()
      .then(() => {
        reset();
        openDialog();
      })
      .catch(() => {
        setValue('subscriptionPackageId', null);
      })
      .finally(() => setIsLoading(false));

  });

  useEffect(() => {
    if (!subscriptionPackageId && subscriptionPackageIdWatcher) {
      onSubmit();
    }
    // eslint-disable-next-line
  }, [subscriptionPackageIdWatcher, subscriptionPackageId])

  return (
    <Fragment>
      <Dialog maxWidth="sm">
        <ConfirmCard onClose={handleCloseDialog}>Confirmation link has been sent to your email</ConfirmCard>
      </Dialog>
      
      <SubscriptionPackagesDialog maxWidth="sm">
        <SubscriptionPackages setSubscriptionPackageId={setSubscriptionPackageId} onClose={closeSubscriptionPackagesDialog} />
      </SubscriptionPackagesDialog>

      <Paper sx={{ p:3, minWidth: { xs: '100%', md: '430px' } }}>
        <Box sx={{ display: 'flex', alignItems: 'cetner', gap: 1, pb: 2 }}>
          <Typography
            display="block"
            variant="h5"
            sx={{ flexGrow: 1, textAlign: 'center' }}
          >
            Create owner account
          </Typography>
          {onClose !== undefined && (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>

        <form onSubmit={onSubmit} noValidate style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {/* E-mail */}
          <Controller
            control={control} name="email"
            rules={{ required: isFieldRequired, pattern: isEmailValid }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                autoComplete="email"
                label="E-mail" type="email"
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : ''}
                required
              />
            )}
          />
          {/* First Name */}
          <Controller
            control={control} name="firstName"
            rules={{ required: isFieldRequired }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="First name"
                error={Boolean(errors.firstName)}
                helperText={errors.firstName ? errors.firstName.message : ''}
                required
              />
            )}
          />
          {/* Last Name */}
          <Controller
            control={control} name="lastName"
            rules={{ required: isFieldRequired }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Last name"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName ? errors.lastName.message : ''}
                required
              />
            )}
          />
          {/* Phone */}
          <Controller
            control={control} name="phone"
            rules={{ required: isFieldRequired }}
            render={({ field:{ value, onChange } }) => (
              <Phone
                margin="none"
                label="Cell phone" value={value}
                onChange={([ callingCode, phone ]:string[]) => {
                  setValue('callingCode', callingCode);
                  onChange(phone);
                }}
                error={Boolean(errors.phone)}
                helperText={errors.phone ? (errors.phone as any).message : ''}
                required={true}
              />
            )}
          />

          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            loading={isLoading}
            sx={{ p: 1 }}
          >
            Sign Up
          </LoadingButton>
        </form>
        <Typography variant="body2" sx={{ pt: 2 }}>
          {`By clicking “Sign up”, you acknowledge that you have read, understood, and agree with our `}
          <NavLink to="/terms-and-conditions" target="_blank" style={{ color: getContent('theme').primaryColor, textDecoration: 'none' }}>Terms</NavLink>
          {` and `}
          <NavLink to="/privacy-policy" target="_blank" style={{ color: getContent('theme').primaryColor, textDecoration: 'none' }}>Privacy Policy</NavLink>
        </Typography>
      </Paper>
    </Fragment>
  )
}

export default ListYourPropertyForm;
