import React from 'react';
// Models
import { IPoll } from 'models/Poll';
// MUI
import { Card, Grid, Button } from '@mui/material';
// Components
import { GridBlock } from 'components/Utilities';

type Props = {
  poll: IPoll;
  openDetailsDialog: (poll: IPoll) => void;
}

const PollCard: React.FC<Props> = ({ poll, openDetailsDialog }) => {
  return (
    <React.Fragment>
      <Card sx={{ p: 2, borderRadius: 0, borderBottom: '1px solid #eee' }}>
        <Grid container spacing={2}>
          <GridBlock
            xs={12} sm={10}
            title="Question"
            text={poll.question}
            textSx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            tooltip={poll.question}
            rowEnabled={false}
          />
          <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => openDetailsDialog(poll)}>{poll.voted ? 'Details' : 'Vote'}</Button>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(PollCard);
