import config from '../config';
import { storeDispatch } from 'index';
// Async
import AuthAsync from 'store/auth/authAsync';
// Types
import RequestMethods from 'types/RequestMethods';
// Service
import StorageService from 'services/StorageService';

const excludedUrls:string[] = ['/auth/token'];

export default class HttpClient {
  public async get(
    path:string,
    params:any = {},
    args:RequestInit = { method: RequestMethods.Get },
  ):Promise<Response> {
    if ( Object.keys(params).length ){
      const queryParams = new URLSearchParams();
      Object.keys(params).forEach((key:string) => {
        if ( params[key] || typeof params[key] === 'boolean' ) queryParams.append(key, params[key]);
      });
      path = `${path}?${queryParams}`;
    }
    return await this.http(path, args);
  };

  public async post(
    path:string,
    body?:any,
    args:RequestInit = {
      method: RequestMethods.Post,
      body: body instanceof FormData ? body : JSON.stringify(body)
    }
  ):Promise<Response> {
    if ( !(body instanceof FormData) ){
      args['headers'] = { 'Content-Type': 'application/json' };
    }
    return await this.http(path, args);
  };

  public async put(
    path:string,
    body:any,
    args:RequestInit = {
      method: RequestMethods.Put,
      body: body instanceof FormData ? body : JSON.stringify(body)
    }
  ):Promise<Response> {
    if ( !(body instanceof FormData) ){
      args['headers'] = { 'Content-Type': 'application/json' };
    }
    return await this.http(path, args);
  };

  public async patch(
    path:string,
    body:any,
    args:RequestInit = {
      method: RequestMethods.Patch,
      body: JSON.stringify(body)
    }
  ):Promise<Response> {
    args['headers'] = { 'Content-Type': 'application/json' };
    return await this.http(path, args);
  };

  public async delete(
    path:string,
    args:RequestInit = { method: RequestMethods.Delete }
  ):Promise<Response> {
    return await this.http(path, args);
  };

  private async http(path:string, args:RequestInit = {}):Promise<Response> {
    const accessToken:string | null = await this.getAccessToken();

    if ( accessToken && !excludedUrls.includes(path) ){
      args.headers = {
        ...args.headers,
        Authorization: `${StorageService.getTokenType()} ${accessToken}`
      }
    };

    const url:string = `${config.apiUrl}${path}`;
    const request:RequestInfo = new Request(url, args);
    return await fetch(request);
  };

  private async getAccessToken():Promise<string | null> {
    const refreshToken:string = StorageService.getRefreshToken();
    if ( StorageService.isAccessTokenExpired() && refreshToken ){
      const response:Response = await fetch(`${config.apiUrl}/client/auth/token`, {
        method: RequestMethods.Post,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refreshToken })
      });
      const credential = await response.json();
      StorageService.setCredential(credential);
      storeDispatch(AuthAsync.checkIsUserAuthenticated({}));
    }
    return StorageService.getAccessToken() || null;
  }
}
