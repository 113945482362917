import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';

export default class ReservationsAsync {
  private static _url:string = '/client/reservations';
  private static _http:HttpClient = new HttpClient();

  // Fetch reservations
  public static fetchReservations = createAsyncThunk('reservations/fetchReservations', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(this._url, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });
}
