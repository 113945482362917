import dayjs from "dayjs";
import { IChat, IChatMessage } from "models/Chat";
import { RootState } from "..";

export const selectChatsMessages = (state:RootState) => state.chats.chatsMessages;
export const selectChatsMessagesTotal = (state: RootState) => state.chats.chatsMessagesTotal;
export const selectChats = (state:RootState) => state.chats.chats;
export const selectChatsTotal = (state: RootState) => state.chats.chatsTotal;
export const selectParamsChatsMessages = (state:RootState) => state.chats.paramsChatsMessages;
export const selectParamsChats = (state:RootState) => state.chats.paramsChats;
export const selectChatById = (state: RootState, chatId: any) => state.chats.chats?.find((chat:IChat) => chat.id === +chatId);

export const selectChatsMessagesObj = (state:RootState) => {
  const { chatsMessages } = state.chats;

  return chatsMessages?.reduce((acc: any, cur: IChatMessage) => {
    const key = dayjs(cur.createdOn).format('YYYY-MM-DD') as keyof typeof acc;
    acc[key] = acc[key] ? [...acc[key], cur] : [cur];
    return acc;
  }, {});
};
