import React, { useEffect } from "react";
// mui
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const PrivacyPolicy:React.FC = () => {
  const classes = useStyles();

  const scrollToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }) };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className={[classes.page, 'container'].join(' ')}>
      <Typography className={classes.header}>Privacy Statement and Cookie Statement</Typography>

      <Typography className={classes.title}>Privacy Statement and Cookie Statement</Typography>
      <Typography className={classes.subtitle}>Last Updated: May 4, 2022</Typography>
      <Typography className={classes.text}>
        Dash Week, a DBA of Dash Realty Group(“we” or “us”) values you as our customer and recognizes that privacy is important to you. This Privacy Statement explains how we collect, use, and disclose data when you use our platform and associated services, your rights in determining what we do with the information that we collect or hold about you and tells you how to contact us.
      </Typography>

      <Typography className={classes.title}>Privacy Statement Summary</Typography>
      <Typography className={classes.text}>This is a summary of our Privacy Statement. To review our Privacy Statement in full, please scroll down.</Typography>
      
      <Typography className={classes.subtitle}>What does this Privacy Statement cover?</Typography>
      <Typography className={classes.text}>This Privacy Statement is designed to describe:</Typography>
      <ul className={classes.text}>
        <li>How and what type of personal information we collect and use</li>
        <li>When and with whom we share your personal information</li>
        <li>What choices you can make about how we collect, use, and share your personal information</li>
        <li>How you can access and update your information.</li>
      </ul>

      <Typography className={classes.subtitle}>What personal information do we collect and use, and how do we collect it?</Typography>
      <Typography className={classes.text}>We collect personal information when:</Typography>
      <ul className={classes.text}>
        <li>You give us the information</li>
        <li>We collect it automatically</li>
        <li>We receive it from others</li>
      </ul>

      <Typography className={classes.text}>
        When you create an account on one of our sites, sign up to receive offers or information, or make a booking using our platform, you give us your personal information. We also collect such information through automated technology such as cookies placed on your browser, with your consent where applicable, when you visit our sites or download and use our Apps. We also receive information from affiliated companies within Dash Realty Group, as well as business partners and other third-parties, which help us improve our platform and associated
        tools and services, update and maintain accurate records, potentially detect and
        investigate fraud, and more effectively market our services. 
      </Typography>

      <Typography className={classes.subtitle}>
        How is your personal information shared?
      </Typography>
      <Typography className={classes.text}>
        Your personal information may be shared to help you book your travel and/or vacation, assist with your travel and/or vacation stay, communicate with you (including when we send information on products and services or enable you to communicate with travel providers and/or property owners), and comply with the law. The Privacy Statement below details how personal information is shared.
      </Typography>
      <Typography className={classes.subtitle}>
        What are your rights and choices?
      </Typography>
      <Typography className={classes.text}>
        You can exercise your data protection rights in various ways. For example, you can opt out of
        marketing by clicking the “unsubscribe” link in the emails, in your account as applicable, or contacting our customer service. Our Privacy Statement has more information about the options and data protection rights and choices available to you.
      </Typography>
      <Typography className={classes.subtitle}>
        How to contact us
      </Typography>
      <Typography className={classes.text}>
        More information about our privacy practices is outlined below in our full Privacy Statement. You can also contact us as described in the “Contact Us” section below to ask questions about how we handle your personal information or make requests about your personal information.
      </Typography>


      <Typography className={classes.title}>
        Privacy Statement
      </Typography>
      <Box className={classes.subtitle}>
        <Typography>Categories of Personal Information We Collect</Typography>
        <Typography>Mobile Apps</Typography>
        <Typography>Use of Personal Information</Typography>
        <Typography>Sharing of Personal Information</Typography>
        <Typography>Your Rights and Choices</Typography>
        <Typography>International Data Transfer</Typography>
        <Typography>Privacy Shield</Typography>
        <Typography>APEC Cross Border Privacy Rules System Participation</Typography>
        <Typography>Security</Typography>
        <Typography>Record Retention</Typography>
        <Typography>Cookie Statement</Typography>
        <Typography>Contact Us</Typography>
        <Typography>Updates to Privacy Statement</Typography>
      </Box>
      
      <Typography className={classes.title}>Categories of Personal Information We Collect</Typography>
      <Typography className={classes.text}>When you use our platform, Apps, or associated tools or services, we may collect the following kinds of personal information from you as needed:</Typography>
      <ul className={classes.text}>
        <li>Name, username, email address, telephone number, and home, business, and billing addressees (including street and postal code)</li>
        <li>Government issued Identification required for booking or identity verification, such as driver’s license, government redress numbers, and country of residence (for travel insurance purposes), and for vacation property owners, tax identification number</li>
        <li>Payment information such as payment card number, expiration date, billing address, and financial account number</li>
        <li>Travel-related preferences and requests such as favorite destination and accommodation types, and special dietary and accessibility needs, as available</li>
        <li>Loyalty program and membership information</li>
        <li>Birth date and gender</li>
        <li>Geolocation</li>
        <li>Images (including facial photographs), videos, and other recordings</li>
        <li>Social media account ID and other publicly available information</li>
        <li>Communications with us (such as recordings of calls with customer service representatives for quality assurance and training purposes)</li>
        <li>Searches you conduct, transactions, and other interactions with you on our online services and Apps</li>
        <li>Other communications that occur through the platform among partners and travelers, and in-group chat and traveler-collaboration tools</li>
        <li>The searches and transactions conducted through the platform</li>
        <li>Data you give us about other people, such as your travel companions or others for whom you are making a booking</li>
        <li>
          Information we receive about you from other Dash Realty Group companies and third parties such as our business and affiliate partners and authorized service providers which may include updated contact information, demographic information, interests, and purchase history, which we may add to your account or profile and use for market research and analysis
          When you install any of our apps or use our platform, we automatically collect the following types of information from your device:
          <ul style={{ marginLeft: '16px' }}>
            <li>IP address</li>
            <li>Device type</li>
            <li>Unique device identification numbers</li>
            <li>Internet browser-type (such as Firefox, Safari, Chrome, and Internet Explorer)</li>
            <li>Internet Service Provider</li>
            <li>Operating System</li>
            <li>Mobile carrier</li>
            <li>How your device has interacted with our online services, including the pages accessed, links clicked, trips viewed, and features used, along with associated dates and times</li>
            <li>Details of any referring website or exit pages, as well as general geographic location (such as at the country or city-level)</li>
          </ul>
        </li>
      </ul>
      <Typography className={classes.text}>Our Cookie Statement section, included below in this Privacy Statement, further explains how we use cookies and similar tracking technology.</Typography>
    
      <Typography className={classes.title}>Mobile Apps</Typography>
      <Typography className={classes.text}>When you download and use any of our mobile apps, we collect certain technical information from your device to enable the app to work properly and as otherwise described in this Privacy Statement. That technical information includes:</Typography>
      <ul className={classes.text}>
        <li>Device and telephone connectivity information such as your carrier, network type, network operator, subscriber identity module ("SIM") operator, and SIM country</li>
        <li>Operating system and version</li>
        <li>Device model</li>
        <li>Performance and data usage</li>
        <li>Usage data, such as dates and times the app accesses our servers, the features and links clicked in the app, searches, transactions, and the data and files downloaded to the app</li>
        <li>Device settings selected or enabled, such as Wi-Fi, Global Positioning System ("GPS"), and Bluetooth (which may be used for location services, subject to your permission as explained below)</li>
        <li>Mobile device settings</li>
        <li>Other technical information such as app name, type, and version as needed to provide you with services</li>
      </ul>
      <Typography className={classes.text}>Permissions for Location-Based Services:</Typography>
      <Typography className={classes.text}>Depending on your device’s settings and permissions and your choice to participate in certain programs, we may collect the location of your device by using GPS signals, cell phone towers, Wi-Fi signals, Bluetooth or other technologies. We will collect this information, if you opt in through the app or other program (either during your initial login or later) to enable certain location-based services available within the app (for example, locating available lodging closest to you). To disable location capabilities of the app, you can log off or change your mobile device’s settings.</Typography>
    
      <Typography className={classes.title}>Use of Personal Information</Typography>
      <Typography className={classes.text}>We use your personal information for various purposes described below, which depend on the site you visit or the app you use. </Typography>
      
      <Typography className={classes.subtitle}>Your Use of Online Sites, Apps, and Services:</Typography>
      <ul className={classes.text}>
        <li>Book the requested travel or enable vacation property booking</li>
        <li>Provide services related to the booking and/or account</li>
        <li>Create, maintain, and update user accounts on our platform and authenticate you as a user</li>
        <li>Maintain your search and travel history, accommodation and travel preferences, and similar information about your use of Dash Realty Group’s platform and services, and as otherwise described in this Privacy Statement</li>
        <li>Enable and facilitate acceptance and processing of payments, coupons, and other transactions</li>
        <li>Administer loyalty and rewards programs</li>
        <li>Collect and enable booking-related reviews</li>
        <li>Help you to use our services faster and easier through features like the ability to sign-in using your account within the online services and sites of some of the Dash Realty Group companies</li>
      </ul>

      <Typography className={classes.subtitle}>Communications and Marketing:</Typography>
      <ul className={classes.text}>
        <li>Respond to your questions, requests for information, and process information choices</li>
        <li>Enable communication between you and the travel supplier like hotels and vacation property owners</li>
        <li>Contact you (such as by text message, email, phone calls, mail, push notifications, or messages on other communication platforms) to provide information like travel booking confirmations and updates, for marketing purposes, or for other purposes as described in this Privacy Statement</li>
        <li>Market our products and services, optimize such marketing to be more relevant to you, and measure and analyze the effectiveness of our marketing and promotions</li>
        <li>Administer promotions like contests, sweepstakes, and similar giveaways</li>
      </ul>

      <Typography className={classes.subtitle}>Other Business Purposes and Compliance</Typography>
      <ul className={classes.text}>
        <li>Conduct surveys, market research, and data analytics</li>
        <li>Maintain, improve, research, and measure the effectiveness of our sites and apps, activities, tools, and services</li>
        <li>Monitor or record calls, chats, and other communications with our customer service team and other representatives, as well as platform communications between or among partners and travelers for quality control, training, dispute resolution, and as described in this Privacy Statement</li>
        <li>Create aggregated or otherwise anonymized or deidentified data, which we may use and disclose without restriction where permissible</li>
        <li>Promote security, verify identity of our customers, prevent and investigate fraud and unauthorized activities, defend against claims and other liabilities, and manage other risks</li>
        <li>Comply with applicable laws, protect our and our users’ rights and interest, defend ourselves, and respond to law enforcement, other legal authorities, and requests that are part of a legal process</li>
        <li>Comply with applicable security and anti-terrorism, anti-bribery, customs and immigration, and other such due diligence laws and requirements</li>
        <li>Operate our business using lawful business purposes and as permitted by law</li>
      </ul>

      <Typography className={classes.subtitle}>Lawful bases for processing:</Typography>
      <Typography className={classes.text}>
        We will collect personal information from you only (i) where the personal information is necessary to perform a contract with you (e.g., manage your booking, process payments, or create an account at your request), (ii) where the processing is in our legitimate interests and not overridden by your rights (as explained below), or (iii) where we have your consent to do so (e.g., sending you marketing communications where consent is required). In some cases, we will have a legal obligation to collect personal information from you such as where it is necessary to use your transaction history to complete our financial and tax obligations under the law.
      </Typography>
      <Typography className={classes.text}>
        If we ask you to provide personal information to comply with a legal requirement or to perform a contract with you, we will make this clear at the relevant time and advise you whether the provision of your personal information is mandatory or not (as well as of the possible consequences if you do not provide your personal information). 
      </Typography>
      <Typography className={classes.text}>
        Certain countries and regions allow us to process personal information on the basis of legitimate interests. If we collect and use your personal information in reliance on our
        legitimate interests (or the legitimate interests of any third-party), this
        interest will typically be to operate or improve our platform and communicate
        with you as necessary to provide our services to you, for security verification
        purposes when you contact us, to respond to your queries, undertaking
        marketing, or for the purposes of potentially detecting or preventing illegal
        activities. 
      </Typography>
      <Typography className={classes.text}>
        We may in some cases use automated decision-making, for example, in relation to assessing fraudulent transactions or suspicious activity on our site. As part of this processing, automated decisions may be made by putting your personal information into a system and the decision is calculated using automatic processes. If you pose a fraud risk, this may affect your ability to book on our site. You may have rights in relation to automated decision making, including the ability to request a manual decision-making process instead or contest a decision based solely on automated processing. If you want to know more about your data protection rights, please see the Your Rights and Choices section below.
      </Typography>

      <Typography className={classes.title}>Sharing of Personal Information</Typography>
      <Typography className={classes.text}>We share your personal information as described below and in this Privacy Statement, and as permitted by applicable law.</Typography>
      
      <Typography className={classes.text}>
        <strong>Third-party service providers.</strong> We share personal information with third-parties in connection with the delivery of services to you and the operation of our business (for example, to provide credit card processing, customer service, business analytics, and fraud prevention and compliance services, and to serve you with advertising tailored to your interests). These third-party service providers are required to protect personal information we share with them and may not use any directly identifying personal information other than to provide services we contracted them for. They are not allowed to use the personal information we share for purposes of their own direct marketing (unless you have separately consented with the third-party under the terms provided by the third-party).
      </Typography>
      <Typography className={classes.text}>
        <strong>Travel suppliers.</strong> We share personal information with travel-related suppliers such as hotels, airlines, car-rental companies, insurance, vacation-rental property owners and managers, and where available, activity providers, rail, or cruise lines who fulfill your booking. Please note that travel suppliers may contact you to obtain additional information if and as required to facilitate your booking or to otherwise provide the travel or associated services.
      </Typography>
      <Typography className={classes.text}>
        <strong>Business partners and offers.</strong> If we promote a program or offer a service or product in conjunction with a third-party business partner, we will share your information with that partner to assist in marketing or to provide the associated product or service. In most of those cases, the program or offer will include the name of the third-party business partner, either alone or with ours, or you will be redirected to the website of that business with notice. An example of such a business-partner relationship would be a third-party loyalty program for which you could earn points by completing a booking on our platform.
      </Typography>
      <Typography className={classes.text}>
        <strong>Other Third-Parties.</strong> When you access certain features like Facebook’s “Like” button or a single sign-on that allows you to login with your social media credentials to our online services, you will share information with the third-party, like a social media company, such as the fact that you have visited or interacted with us. In the European Economic Area (EEA), Switzerland, and United Kingdom (UK) we will not load social media sharing or sign-on buttons on our website unless and until you accept our use of cookies and similar technologies. For more information, see our Cookie Statement, included below in this Privacy Statement. The third-party provider may combine this information with other information they have about you. The personal information shared will be governed by the third-party provider’s privacy policy (including any personal information we may access via the third-party provider). The third-party providers should inform you about how you can modify your privacy settings on their site.
      </Typography>
      <Typography className={classes.text}>
        <strong>Legal rights and obligations.</strong> We may disclose your personal information to enforce our policies, or where we are permitted (or believe in good faith that we are required) to do so by applicable law, such as in response to a request by a law enforcement or governmental authority, in connection with actual or proposed litigation, or to protect and defend our property, people and other rights or interests. We may also share your personal information pursuant to a subpoena or other legal request, or as necessary to remit certain taxes in the course of processing payments as required by law or legal process.
      </Typography>
      <Typography className={classes.text}>
        <strong>Corporate transactions.</strong> We may share your personal information in connection with a corporate transaction, such as a divestiture, merger, consolidation, assignments or asset sale, or in the unlikely event of bankruptcy. In the case of any acquisition, we will inform the buyer it must use your personal information only for the purposes disclosed in this Privacy Statement.
      </Typography>

      <Typography className={classes.title}>Your Rights and Choices</Typography>
      <Typography className={classes.text}>You have certain rights and choices with respect to your personal information, as described below:</Typography>
      <ul className={classes.text}>
        <li>If you have an account with us, you may change your communication preferences by either (1) logging in and updating the information in your account (not available for all Dash Realty Group companies) or (2) contacting us via the options provided in the Contact Us section at the bottom of this Statement.</li>
        <li>You can control our use of certain cookies by following the guidance in our Cookie Statement included below in this Privacy Statement.</li>
        <li>You can access, amend or update the accuracy of your information at any time by either logging into your account or contacting us</li>
        <li>If you no longer wish to receive marketing and promotional emails, you may unsubscribe by clicking the ‘unsubscribe’ link in the email. You can also log into your account to change communication settings (not available for all Dash Realty Group companies) or contacting us here. Please note that if you choose to unsubscribe from or opt out of marketing emails, we may still send you important transactional and account-related messages from which you will not be able to unsubscribe</li>
        <li>For our mobile apps, you can view and manage notifications and preferences in the settings menus of the app and of your operating system</li>
        <li>
          If we are processing your personal information on the basis of consent, you may withdraw that consent at any time by contacting us. Withdrawing your consent will not affect the lawfulness of any processing that occurred before you withdrew consent and it will not affect our processing of your personal information that is conducted in reliance on a legal basis other than consent
          Certain countries and regions provide their residents with additional rights relating to personal information. These additional rights vary by country and region and may include the ability to:
          <ul style={{ marginLeft: '16px' }}>
            <li>Request a copy of your personal information</li>
            <li>Request information about the purpose of the processing activities</li>
            <li>Delete your personal information</li>
            <li>Object to our use or disclosure of your personal information</li>
            <li>Restrict the processing of your personal information</li>
            <li>Opt-out of the sale of your personal information</li>
            <li>Port your personal information</li>
            <li>Request information about the logic involved in our automated decision-making used in our fraud prevention practices and the result of such decisions</li>
          </ul>
        </li>
      </ul>

      <Typography className={classes.text}>
        For more information on what data subject rights may be available to you, please click <a href="https://www.expediagroup.com/who-we-are/corporate-standards/privacy-expedia-group/Data-Subject-Rights/" target="_blank" rel="noreferrer">here</a>.
        For questions about privacy, your rights and choices, and in order to make a request to amend or update your information, please contact us via the options provided in the Contact Us section at the bottom of this Statement.
      </Typography>
      <Typography className={classes.text}>
        In addition to the above rights, you may have the right to complain to a data protection authority about our collection and use of your personal information. However, we encourage you to contact us first so we can do our best to resolve your concern. You may submit your request to us using the information in the Contact Us section, below. We respond to all requests we receive from individuals wanting to exercise their personal data protection rights in accordance with applicable data protection laws.
      </Typography>
      <Typography className={classes.title}>International Data Transfer</Typography>
      <Typography className={classes.text}>
        The personal information we process may be transmitted or transferred to countries other than the country in which you reside. Those countries may have data protection laws that are different from the laws of your country.
      </Typography>
      <Typography className={classes.text}>
        The servers for our platform are located in the United States, and the Dash Realty Group companies and third-party service providers operate in many countries around the world. When we collect your personal information, we may process it in any of those countries.
      </Typography>
      <Typography className={classes.text}>
        We have taken appropriate steps and put safeguards in place to help ensure that your personal information remains protected in accordance with this Privacy Statement. For example, any data transfers between our group companies are governed by our intragroup agreements which incorporate strict data transfer terms (including the European Commission's Standard Contractual Clauses, for transfers from the EEA) and require all group companies to protect the personal information they process in accordance with applicable data protection law. In addition, certain Dash Realty Group U.S. affiliates have certified with the EU-U.S. and Swiss-U.S. Privacy Shield, as explained below in the "Privacy Shield" section.
      </Typography>
      <Typography className={classes.text}>
        We also require that third-party service providers to whom a data transfer is made has appropriate safeguards in place to protect your personal information, in compliance with applicable data protection law. The particular measures used will depend on the service provider, and our agreements with them may include European Commission approved Standard Contractual Clauses, the service provider's certification under the EU-U.S. and/or Swiss-U.S. Privacy Shield certification, or reliance on the service provider's Binding Corporate Rules, as defined by the European Commission.
      </Typography>

      <Typography className={classes.title}>Privacy Shield</Typography>
      <Typography className={classes.text}>
        Certain Dash Realty Group U.S. affiliates have certified to the EU-U.S. and Swiss-U.S. Privacy Shield frameworks and that we adhere to the Privacy Shield Principles of Notice, Choice, accountability for Onward Transfers, Security, Data Integrity and Purpose Limitation, Access, and Recourse, Enforcement, and Liability for personal information from the EU, Switzerland, and the United Kingdom. Such Dash Realty Group U.S. affiliates will continue to adhere to the Privacy Shield frameworks and Principles even though the CJEU determined in July 2020 that the EU-U.S. Privacy Shield framework is no longer an adequate transfer mechanism for the transfer of EU personal information to the U.S. In addition, Dash Realty Group maintains intra-group Standard Contractual Clauses where applicable to cover the transfer of EU personal information to the U.S.
        Our certifications can be found <a href={`https://www.privacyshield.gov/participant?id=a2zt0000000PJU6AAO&status=Active`} target="_blank" rel="noreferrer">here</a>. For more information about the Privacy Shield principles, please visit: <a href="http://www.privacyshield.gov/" target="_blank" rel="noreferrer">www.privacyshield.gov</a>.
      </Typography>

      <Typography className={classes.title}>APEC Cross Border Privacy Rules System Participation</Typography>
      <Typography className={classes.text}>
        Dash Week’s privacy practices, described in this Privacy Statement, comply with the APEC Cross Border Privacy Rules System. The APEC CBPR system provides a framework for organizations to ensure protection of personal information transferred among participating APEC economies. More information about the APEC framework can be found <a href="http://cbprs.org/" target="_blank" rel="noreferrer">here</a>.
      </Typography>

      <Typography className={classes.title}>Security</Typography>
      <Typography className={classes.text}>
        We want you to feel confident about using our platform and all associated tools and services, and we are committed to taking appropriate steps to protect the information we collect. While no company can guarantee absolute security, we do take reasonable steps to implement appropriate physical, technical, and organizational measures to protect the personal information that we collect and process.  
      </Typography>

      <Typography className={classes.title}>Record Retention</Typography>
      <Typography className={classes.text}>
        We will retain your personal information in accordance with all applicable laws, for as long as it may be relevant to fulfill the purposes set forth in this Privacy Statement, unless a longer retention period is required or permitted by law. We will deidentify, aggregate, or otherwise anonymize your personal information if we intend to use it for analytical purposes or trend analysis over longer periods of time.
      </Typography>
      <Typography className={classes.text}>The criteria we use to determine our retention periods include:</Typography>
      <ul className={classes.text}>
        <li>The duration of our relationship with you, including any open accounts you may have with Dash Realty Group companies, or recent bookings or other transactions you have made on our platform</li>
        <li>Whether we have a legal obligation related to your personal information, such as laws requiring us to keep records of your transactions with us</li>
        <li>Whether there are any current and relevant legal obligations affecting how long we will keep your personal information, including contractual obligations, litigation holds, statutes of limitations, and regulatory investigations</li>
      </ul>

      <Typography className={classes.title}>Cookie Statement</Typography>
      <Typography className={classes.subtitle}>Types of cookies and similar technologies</Typography>
      <Typography className={classes.subtitle}>Cookies</Typography>
      <Typography className={classes.text}>
        Cookies are small pieces of text sent as files to your computer or mobile device when you visit most websites. Cookies may be delivered by us (first party cookies) or delivered by a third-party partner or supplier (third-party cookies). Cookies are either session cookies or persistent cookies. Session cookies enable sites to recognize and link the actions of a user during a browsing session and expire at the end of each session. Persistent cookies help us recognize you as an existing user and these cookies are stored on your system or device until they expire, although you can delete them before the expiration date.
      </Typography>
      <Typography className={classes.subtitle}>Other similar technologies</Typography>
      <ul className={classes.text}>
        <li><strong>Web beacons, gifs, and clear gifs</strong> are tiny graphics, each with a unique identifier that are embedded invisibly on sites and in emails. Web beacons allow us to know if a certain page was visited or if ad banners on our sites and other sites are effective. We also use web beacons in our HTML-based emails to let us know whether our emails have been opened by recipients, which helps us to gauge the effectiveness of certain communications, promotions, and marketing campaigns.</li>
        <li><strong>Proximity based beacons</strong> send one-way signals over very short distances, to communicate with associated mobile apps installed on your phone. They can notify you, for example, about experiences related to your trip and can alert you to related deals or promotions. Beacons communicate with your device only when you are in close proximity and only if you have given consent within the relevant mobile application.</li>
        <li><strong>Pixels</strong> are small objects embedded into a web page that are not visible to the user. We use pixels to deliver cookies to your computer, facilitate the log-in process, monitor the activity on our sites, and deliver online advertising.</li>
        <li><strong>Tags</strong> are small pieces of HTML code that tell your browser to request certain content generated by an ad server. We use tags to show you relevant advertising and promotions.</li>
        <li><strong>Scripts</strong> are pieces of JavaScript code that launch automatically when certain webpages load, to determine whether users view associated advertisements.</li>
        <li><strong>Local Storage Objects</strong>, such as HTML 5, are used to store content and preferences. Third-parties with whom we partner to provide certain features on our site to display advertising based upon your web browsing activity use Local Storage Objects to collect and store information.</li>
      </ul>

      <Typography className={classes.text}>
        All of the technologies described above will be collectively referred to in this Cookie Statement as “cookies”.
      </Typography>

      <Typography className={classes.subtitle}>Dash Realty Group’s use of cookies</Typography>
      <Typography className={classes.text}>At Dash Realty Group, we use cookies for a number of reasons, including to:</Typography>
      <ul className={classes.text}>
        <li>Help us improve your experience when visiting our sites</li>
        <li>Fulfill transactions and ensure our sites perform as intended</li>
        <li>Remember your preferences, such as language, region, or currency</li>
        <li>Provide you with relevant advertising and analyze performance of the ads</li>
        <li>Enable you to return to previous travel searches</li>
        <li>Identify errors on our sites</li>
        <li>Help with data protection and potentially detect and investigate malicious or fraudulent activity</li>
        <li>Help us understand traffic to our site, including time and date of the visit, time and date of the last visit, and other information</li>
        <li>Analyze how well our sites are performing</li>
      </ul>

      <Typography className={classes.subtitle}>Types of information collected by cookies</Typography>
      <Typography className={classes.text}>The types of information that we collect through cookies include:</Typography>
      <ul className={classes.text}>
        <li>IP address</li>
        <li>Device ID</li>
        <li>Viewed pages</li>
        <li>Browser type</li>
        <li>Browsing information</li>
        <li>Operating system</li>
        <li>Internet Service Provider</li>
        <li>Whether you have responded to, or interacted with, an advertisement</li>
        <li>Referring or referred links or URLs</li>
        <li>Features used and activities engaged in on our sites and in our apps</li>
      </ul>
      <Typography className={classes.text}>
        See the Categories of Personal Information We Collect section above for more information on what types of information are collected automatically.
      </Typography>

      <Typography className={classes.subtitle}>Types and functions of cookies</Typography>
      <Typography className={classes.text}><strong>Essential Cookies</strong></Typography>
      <Typography className={classes.text}>
        Certain cookies are required or “essential” for our sites to function as intended. Essential cookies are necessary for you to navigate our site and use certain features like logging in to your account and managing your bookings. These cookies are also used to remember security settings that allow access to particular content. Lastly, we use essential cookies to collect information on which web pages visitors go to most, so we can improve our online services. You are not able to opt out of essential cookies.
      </Typography>

      <Typography className={classes.subtitle}>Other Types of Cookies</Typography>
      <Typography className={classes.text}>We also use other types of cookies to make our site engaging and useful to you:</Typography>
      <ul className={classes.text}>
        <li><strong>Functional Cookies.</strong> We want to make sure when you visit our site, your preferences (such as your preferred language), settings, and previous searches are remembered. Functional cookies enhance your experience on any Dash Realty Group company’s site.</li>
        <li>
          <strong>Analytics Cookies.</strong> We use analytics cookies to:
          <Typography className={classes.text}>
            Understand our site’s performance and how it is used, including the number of visitors, how long you stay on the site, and which parts of the site you visit. We can see details about how visitors interacted with the site, like the number of clicks visitors made on a given page, their mouse movements and scrolling activity, the search words visitors used, and the text visitors enter into various fields
          </Typography>
          <ul>
            <li>Test different designs and features for our site</li>
            <li>Monitor how our visitors reach our sites</li>
            <li>Determine effectiveness of our advertising</li>
            <li>Improve our services, including your experience on our site</li>
          </ul>
          <Typography className={classes.text}>
            For analytics, our third-party service providers may use cookies to perform their services and may combine the information that they collect about you on our sites with other information that they have collected. For example, we use Google analytics cookies to monitor the performance of our sites. Our ability to use and share information collected by Google Analytics about your visit to our sites is restricted by the Google Analytics Terms of Use and the Google Privacy Policy. This Cookie Statement does not cover such third-parties’ use of your information.
            Some analytics cookies can perform tasks essential and/or functional to online services like enabling site improvements and testing changes on a site. 
          </Typography>
        </li>
        <li>
          Advertising Cookies. Dash Realty Group and our third-party advertising partners may use advertising cookies to show you advertisements both on and off our sites that are based on your interests. We may allow third-parties to collect information about your online activities through cookies to perform their services and may combine the information that they collect about you on our sites with other information that they have collected. These third-parties include business partners who collect information when you view or interact with one of (1) their advertisements on our sites or (2) our advertising or booking information on their sites and advertising networks, which collect information about your interests when you view or interact with one of the advertisements or tracking mechanisms they place on many different sites on the Internet. Our partners may make assumptions about your interests, characteristics, or preferences and add you to groups based on those assumptions in order to show you tailored advertisements. Dash Realty Group does not have access to the cookies these third-parties may use to collect information about your interests, and the information practices of these third-parties are not covered by this Cookie Statement, part of our Privacy Statement. Some of these companies are members of the Network Advertising Initiative, which offers a single location to opt out of ad targeting from member companies.
          To learn more, please click <a href={`http://optout.aboutads.info/?c=2&lang=EN`} target="_blank" rel="noreferrer">here</a> and <a href="http://optout.networkadvertising.org/" target="_blank" rel="noreferrer">here</a>.
        </li>
      </ul>

      <Typography className={classes.subtitle}>Your information choices</Typography>
      <Typography className={classes.text}>You can choose not to receive tailored online advertising on this site and other sites and learn more about opting out of having your information used for tailored advertising purposes by accessing one of the following resources:</Typography>
      <ul>
        <li>For EEA, Switzerland, and UK: <a href="http://www.youronlinechoices.eu/" target="_blank" rel="noreferrer">http://www.youronlinechoices.eu/</a></li>
        <li>For Canada: <a href="http://youradchoices.ca/choices/" target="_blank" rel="noreferrer">http://youradchoices.ca/choices/</a></li>
        <li>
          For U.S. and rest of world:
          <ul style={{ marginRight: '16px' }}>
            <li><a href="http://www.aboutads.info/choices/" target="_blank" rel="noreferrer">http://www.aboutads.info/choices/</a></li>
            <li><a href="http://optout.networkadvertising.org/" target="_blank" rel="noreferrer">http://optout.networkadvertising.org/</a></li>
          </ul>
        </li>
      </ul>
      <Typography className={classes.text}>Note that if you choose not to receive tailored ads, you will still see online advertisements, but they will be general and less relevant to you.</Typography>

      <Typography className={classes.subtitle}>How can you manage your cookies?</Typography>
      <Typography className={classes.text}>You can set or amend your web browser controls to accept or refuse cookies whenever you like, but please remember if you do choose to reject cookies, your access to some of the functionality and areas of our site may be restricted.</Typography>

      <Typography className={classes.subtitle}>Do-Not-Track Signals and Similar Mechanisms.</Typography>
      <Typography className={classes.text}>
        Some web browsers may transmit "do-not-track" signals to sites with which the browser communicates. Because of differences in how web browsers incorporate and activate this feature, it is not always clear whether users intend for these signals to be transmitted, or whether users are even aware of them. Participants in the leading Internet standards-setting organization that is addressing this issue are in the process of determining what, if anything, sites should do when they receive such signals. We currently do not take action in response to these signals. If and when a final standard is established and accepted, we will reassess our sites’ responses to these signals and make appropriate updates to this Cookie Statement.
      </Typography>

      <Typography className={classes.subtitle}>Contact Us</Typography>
      <Typography className={classes.text}>
        If you have any questions or concerns about our use of your personal information, or wish to inquire about our personal handling practices, please contact us via the Privacy Section found on our Customer Services Portal <a href="https://dashweek.com/contacts" target="_blank" rel="noreferrer">Contact Page</a>. For a list of the Dash Realty Group companies, click here.
      </Typography>
      <Typography className={classes.text}>For more information about the data controller and/or Representative for personal information we process, please click here.</Typography>
      <Typography className={classes.text}>
        If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third party dispute resolution provider (free of charge) at <a href="https://feedback-form.truste.com/watchdog/request" target="_blank" rel="noreferrer">https://feedback-form.truste.com/watchdog/request</a>.
      </Typography>

      <Typography className={classes.subtitle}>Updates to Statement</Typography>
      <Typography className={classes.text}>
        We may update this Statement in response to changing laws or technical or business developments. If we propose to make any material changes, we will notify you by means of a notice on this page. You can see when this Privacy Statement was last updated by checking the last updated” date displayed at the top of this Statement.
      </Typography>
    </div>
  )
}

export default PrivacyPolicy;

const useStyles = makeStyles({
  page: {
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
    },
  },
  header: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '-0.35px',
    '@media (min-width: 600px)': {
      fontSize: '24px',
      lineHeight: '133.4%',
      letterSpacing: '-0.5px',
    },
    '@media (min-width: 1240px)': {
      fontSize: '40px',
      lineHeight: '116.7%',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    paddingTop: '30px',
    '@media (min-width: 1240px)': {
      fontSize: '20px',
      lineHeight: '160%',
    },
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '-0.43px',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingTop: '20px',
    '@media (min-width: 600px)': {
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '-0.35px',
    },
  },
  text: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '-0.43px',
    paddingTop: '10px',
    textAlign: 'justify',
    '@media (min-width: 600px)': {
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '-0.35px',
    },
  }
});
