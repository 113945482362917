import { FC, useRef, useLayoutEffect } from 'react';

type Props = {
  onVisible: (visible:boolean) => void;
}

const ElementObserver:FC<Props> = ({ onVisible }) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const current = elementRef.current as Element;
    const observer = new IntersectionObserver(([entry]:any) => {
      onVisible(entry.isIntersecting);
    });
    observer.observe(current);
    return () => {
      observer.unobserve(current);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div ref={elementRef} style={{ height: '1px' }} />
  )
}

export default ElementObserver
