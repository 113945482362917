import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
// Async
import UsersAsync from 'store/users/usersAsync';
// Hooks 
import { useAppDispatch } from 'hooks/redux';
// Types
import UserRoles from 'types/UserRoles';
// Components
import Phone, { getCallingCode, getPhoneWithoutCallingCode } from 'components/Phone';
import ConfirmCard from './ConfirmCard';
// Mui
import {
  Box, Button, Checkbox, Grid,
  IconButton, Paper, TextField, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// Validation
import { isFieldRequired, isEmailValid } from '../utilities/Validation';
// Utilities
import { getContent } from 'utilities/Utilities';

type Props = {
  onClose: () => void,
  openSignIn?: () => void,
}

interface IFormData {
  email: string;
  firstName: string;
  lastName: string;
  callingCode: string;
  phone: string;
  role: UserRoles,
  notificationsEnabled: boolean;
}

const SignUpForm:React.FC<Props> = ({ onClose, openSignIn }) => {
  const dispatch = useAppDispatch();

  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);

  const { control, handleSubmit, formState: { errors }, setValue } = useForm<IFormData>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      callingCode: '',
      phone: '',
      role: UserRoles.Guest,
      notificationsEnabled: true,
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    const nextData:IFormData = {
      ...data,
      callingCode: getCallingCode(data.callingCode),
      phone: getPhoneWithoutCallingCode(data.callingCode, data.phone)
    }
    setIsLoading(true);
    dispatch(UsersAsync.registrationRequest(nextData))
    .unwrap()
    .then(() => {
      setSentEmail(true);
    })
    .finally(() => {
      setIsLoading(false);
    })
  });

  return (
    <React.Fragment>
      {sentEmail ? (
        <ConfirmCard onClose={onClose}>Confirmation link has been sent to your email</ConfirmCard>
      ) : (
        <Paper elevation={6} variant="elevation" sx={{p:3}}>
          <IconButton onClick={onClose} sx={{ position: 'absolute', top: '16px', right: '16px' }}>
            <CloseOutlinedIcon />
          </IconButton>
          <Box sx={{ pt: 2, pb: 4 }}>
            <Typography
              display="block"
              variant="h5"
              textAlign="center"
              sx={{ pb: 2 }}
            >
              Sign Up
            </Typography>
            <Typography variant="body2" color="text.secondary" textAlign="center">
              We will send you a link to your email <br /> so you could confirm your account and create a password
            </Typography>
          </Box>
  
          <form onSubmit={onSubmit} noValidate style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {/* First Name */}
                <Controller
                  control={control} name="firstName"
                  rules={{ required: isFieldRequired }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="First name"
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName ? errors.firstName.message : ''}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Last Name */}
                <Controller
                  control={control} name="lastName"
                  rules={{ required: isFieldRequired }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Last name"
                      error={Boolean(errors.lastName)}
                      helperText={errors.lastName ? errors.lastName.message : ''}
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* E-mail */}
            <Controller
              control={control} name="email"
              rules={{ required: isFieldRequired, pattern: isEmailValid }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoComplete="email"
                  label="E-mail" type="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email ? errors.email.message : ''}
                  required
                />
              )}
            />
            
            {/* Phone */}
            <Controller
              control={control} name="phone"
              rules={{ required: isFieldRequired }}
              render={({ field: { value, onChange } }) => (
                <Phone
                  label="Cell phone" value={value}
                  onChange={([ callingCode, phone ]:string[]) => {
                    setValue('callingCode', callingCode);
                    onChange(phone)
                  }}
                  margin="none"
                  error={Boolean(errors.phone)}
                  helperText={errors.phone ? (errors.phone as any).message : ''}
                  required={true}
                />
              )}
            />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Checkbox checked={accepted} onChange={(_:any, checked: boolean) => setAccepted(checked)} />
              <Typography sx={{ fontSize: '14px' }}>
                {'* I have read and accepted the '}
                <NavLink to="/terms-and-conditions" target="_blank" style={{ color: getContent('theme').primaryColor, textDecoration: 'none' }}>Terms & Conditions</NavLink>
                {' and '}
                <NavLink to="/privacy-policy" target="_blank" style={{ color: getContent('theme').primaryColor, textDecoration: 'none' }}>Privacy Policy</NavLink>
              </Typography>
            </Box>
  
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              loading={isLoading}
              disabled={!accepted}
              sx={{ p: 1 }}
            >
              Sign Up
            </LoadingButton>
          </form>
          {openSignIn && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 2, color: 'rgba(0,0,0,0.6)' }}>
              Already have an account?
              <Button onClick={openSignIn}>Sign In</Button>
            </Box>
          )}
        </Paper>
      )}
    </React.Fragment>
  );
}

export default SignUpForm;
