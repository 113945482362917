import React, { useEffect, useState } from "react";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Types
import ReservationRequestStatus from "types/ReservationRequestStatus";
// Async
import ReservationRequestsAsync from "store/reservationRequests/reservationRequestsAsync";
// Selectors
import { selectReservationRequests, selectTotal } from "store/reservationRequests/reservationRequestsSelectors";
// Components
import ReservationRequestCard from "./ReservationRequestCard";
import { Loader, NoData } from "components/Utilities";
import Title from "components/Title";
// MUI
import { makeStyles } from '@mui/styles';
import { Box, Tab, TablePagination, Tabs } from "@mui/material";

const ReservationRequests:React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const reservationRequests = useAppSelector(selectReservationRequests);
  const total = useAppSelector(selectTotal);

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ page, setPage ] = useState<number>(0);
  const [ size, setSize ] = useState<number>(20);

  const [tab, setTab] = React.useState<ReservationRequestStatus>(ReservationRequestStatus.Submitted);

  const handleChangeTab = (_: any, newTab: ReservationRequestStatus) => {
    setTab(newTab);
  };

  const handleChangePage = (_:any, nextPage:number) => {
    setIsLoading(true);
    setPage(nextPage);
    dispatch(ReservationRequestsAsync.fetchReservationRequests({ size, page: nextPage, statuses: [tab] }))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      })
  }

  const handleChangeLimit = (event: any) => {
    setIsLoading(true);
    const { value:size } = event.target;
    setSize(size);
    setPage(0);
    dispatch(ReservationRequestsAsync.fetchReservationRequests({ size, page: 0, statuses: [tab] }))
    .unwrap()
    .finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    setIsLoading(true);
    dispatch(ReservationRequestsAsync.fetchReservationRequests({ statuses: [tab], size }))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      })
    // eslint-disable-next-line
  }, [tab]);

  return (
    <Box className={classes.page}>
      <div className="container">
        <Title>Reservation requests</Title>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: 'max-content', mt: 2 }}>
          <Tabs
            variant="scrollable"
            value={tab}
            onChange={handleChangeTab}
            aria-label="reservation requests status tabs"
          >
            {Object.keys(ReservationRequestStatus).map((key: string) => (
              <Tab sx={{ fontSize: { xs: '12px', sm: '14px' } }} key={key} value={ReservationRequestStatus[key as keyof typeof ReservationRequestStatus]} label={key} />
            ))}
          </Tabs>
        </Box>
        {isLoading ? <Loader /> : (
          !!total ? (
            <Box className={classes.list} sx={{ mt: 2 }}>
              <Box className={classes.items}>
                {reservationRequests?.map(reservationRequest => (
                  <ReservationRequestCard key={reservationRequest.id} reservationRequest={reservationRequest} />
                ))}
              </Box>
              <Box className={classes.pagination}>
                <TablePagination
                  component="div"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={size}
                  onRowsPerPageChange={handleChangeLimit}
                  labelRowsPerPage="Items per page"
                  rowsPerPageOptions={[20, 50, 100]}
                />
              </Box>
            </Box>
          ) : <NoData />
        )}
      </div>
    </Box>
  );
}

export default ReservationRequests;

const useStyles = makeStyles({
  page: {
    height: 'calc(100vh - 266px)',
    backgroundColor: '#F5F6F7',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
      height: 'calc(100vh - 236px)',
    },
  },
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 454px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 495px)',
    },
  },
  pagination: {
    boxShadow: '0px 0px 0px 1px #E0E0E0'
  },
});
