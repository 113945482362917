import React from "react";
import { Navigate } from "react-router-dom";
// Hooks
import { useAppSelector } from 'hooks/redux';
// Models
import { ISubscription } from "models/Subscriptions";
// Types
import UserRoles from "types/UserRoles";
// Selectors
import { selectCurrentUser } from "store/users/usersSelectors";
import { selectSubscriptionModuleEnabled } from "store/accounts/accountsSelectors";
// Components
import SubscriptionCard from "./SubscriptionCard";
import Title from "components/Title";
// MUI
import { Box } from "@mui/material";
import { makeStyles } from '@mui/styles';

const SubscriptionsList:React.FC = () => {
  const classes = useStyles();

  const currentUser = useAppSelector(selectCurrentUser);
  const subscriptionModuleEnabled = useAppSelector(selectSubscriptionModuleEnabled);

  if ( subscriptionModuleEnabled === false || currentUser?.activeRole !== UserRoles.Owner ) return <Navigate to="/" replace />;

  return (
    <Box className={classes.page}>
      <div className="container">
        <Title>My subscriptions</Title>
        <Box className={classes.list} sx={{ mt: 3 }}>
          <Box className={classes.items}>
            {currentUser?.subscriptions?.map((subscription: ISubscription) => (
              <SubscriptionCard key={subscription.id} subscription={subscription} />
            ))}
          </Box>
        </Box>
      </div>
    </Box>
  );
}

export default SubscriptionsList;

const useStyles = makeStyles({
  page: {
    height: 'calc(100vh - 266px)',
    backgroundColor: '#F5F6F7',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
      height: 'calc(100vh - 236px)',
    },
  },
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 340px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 365px)',
    },
  },
});
