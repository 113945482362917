import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Types
import UserRoles from "types/UserRoles";
// Async
import SubscriptionsAsync from "store/subscriptions/subscriptionsAsync";
// Selectors
import { selectBenefitRequests, selectBenefitRequestsTotal } from "store/subscriptions/subscriptionsSelectors";
import { selectCurrentUser } from "store/users/usersSelectors";
import { selectSubscriptionModuleEnabled } from "store/accounts/accountsSelectors";
// Components
import BenefitRequestCard from "./BenefitRequestCard";
import Title from "components/Title";
import { Loader, NoData } from "components/Utilities";
// Mui
import { Box, Tab, TablePagination, Tabs } from "@mui/material";
import { makeStyles } from '@mui/styles';

const BenefitRequestsList:React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);

  const [tab, setTab] = useState<string>('current');
  const handleChangeTab = (_: any, newTab: string) => setTab(newTab);

  const currentUser = useAppSelector(selectCurrentUser);
  const subscriptionModuleEnabled = useAppSelector(selectSubscriptionModuleEnabled);
  const benefitRequests = useAppSelector(selectBenefitRequests);
  const total = useAppSelector(selectBenefitRequestsTotal);

  const handleChangePage = (_:any, nextPage:number) => {
    setIsLoading(true);
    setPage(nextPage);
    dispatch(SubscriptionsAsync.fetchBenefitRequests({ size, page: nextPage, current: tab === 'current' }))
      .unwrap()
      .finally(() => setIsLoading(false));
  }

  const handleChangeLimit = (event: any) => {
    setIsLoading(true);
    const { value:size } = event.target;
    setSize(size);
    setPage(0);
    dispatch(SubscriptionsAsync.fetchBenefitRequests({ size, page: 0, current: tab === 'current' }))
      .unwrap()
      .finally(() => setIsLoading(false));
  }
 
  useEffect(() => {
    setIsLoading(true);
    dispatch(SubscriptionsAsync.fetchBenefitRequests({ size, page: 0, current: tab === 'current' }))
      .unwrap()
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [tab]);

  if ( subscriptionModuleEnabled === false || currentUser?.activeRole !== UserRoles.Owner ) return <Navigate to="/" replace />;
  return (
    <Box className={classes.page}>
      <div className="container">
        <Title>Claimed benefits</Title>
        <Box sx={{ pt: 2, borderBottom: 1, borderColor: 'divider', maxWidth: 'max-content' }}>
          <Tabs
            variant="scrollable"
            value={tab}
            onChange={handleChangeTab}
            aria-label="Claimed benefits tabs"
          >
            <Tab sx={{ fontSize: { xs: '12px', sm: '14px' } }} value="current" label="Current month" />
            <Tab sx={{ fontSize: { xs: '12px', sm: '14px' } }} value="past" label="Archive" />
          </Tabs>
        </Box>
        {isLoading ? <Loader /> : (
          !!total ? (
            <Box className={classes.list} sx={{ mt: 3 }}>
              <Box className={classes.items}>
                {benefitRequests?.map(benefitRequest => (
                  <BenefitRequestCard key={benefitRequest.id} benefitRequest={benefitRequest} />
                ))}
              </Box>
              <Box className={classes.pagination}>
                <TablePagination
                  component="div"
                  count={total}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={size}
                  onRowsPerPageChange={handleChangeLimit}
                  labelRowsPerPage="Items per page"
                  rowsPerPageOptions={[20, 50, 100]}
                />
              </Box>
            </Box>
          ) : <NoData />
        )}
      </div>
    </Box>
  );
}

export default BenefitRequestsList;

const useStyles = makeStyles({
  page: {
    height: 'calc(100vh - 266px)',
    backgroundColor: '#F5F6F7',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
      height: 'calc(100vh - 236px)',
    },
  },
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 462px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 503px)',
    },
  },
  pagination: {
    boxShadow: '0px 0px 0px 1px #E0E0E0'
  }
});
