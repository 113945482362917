import dayjs from 'dayjs';
import IMessage from 'models/Message';
import { RootState } from 'store';

export const selectAllMessages = (state:RootState) => state.messages.messages;
export const selectTotal = (state: RootState) => state.messages.total;
export const selectIsUnreadMessages = (state: RootState) => state.messages.messages?.some(message => message.fromUser.id !== state.users.currentUser?.id && !message.read) || false;

export const selectMessagesObj = (state:RootState) => {
  const { messages } = state.messages;

  return messages?.reduce((acc: any, cur: IMessage) => {
    const key = dayjs(cur.createdOn).format('YYYY-MM-DD') as keyof typeof acc;
    acc[key] = acc[key] ? [...acc[key], cur] : [cur];
    return acc;
  }, {});
};
