import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Types
import { RootState } from 'store';
import AssetType from 'types/AssetType';
import AssetContractType from 'types/AssetContractType';
// Actions
import { AssetsActions, IFile } from 'store/assets/assetsSlice';
// Selectors
import { selectAssets, selectFiles } from 'store/assets/assetsSelectors';
// Mui
import { Box, Typography } from '@mui/material';
// Components
import AssetsDragAndDrop from './AssetsDragAndDrop';
import AssetsFilePicker from './AssetsFilePicker';
import AssetsList from './AssetsList';

type Props = {
  label?: string;
  type?: AssetType;
  contractType?: AssetContractType;
  multiple?: boolean;
  assets: any;
};

const AssetsUpload:React.FC<Props> = ({
  label, type = AssetType.Images, contractType = undefined, multiple = false,
  assets:initialAsset = undefined
}) => {
  const dispatch = useAppDispatch();
  
  const files = useAppSelector((state:RootState) => selectFiles(state, { type, contractType }));
  const assets = useAppSelector((state:RootState) => selectAssets(state, { type, contractType }));


  useEffect(() => {
    if ( type === AssetType.Contracts && !contractType ) throw new Error('`contractType` is required when type = `contracts`');
    if ( initialAsset ) {
      dispatch(AssetsActions.setAssets({ type, contractType, assets: Array.isArray(initialAsset) ? initialAsset : [initialAsset] }));
    }
    return () => {
      dispatch(AssetsActions.setInitialReducer());
    }
    // eslint-disable-next-line
  }, [initialAsset]);

  const handleChange = (newFiles:FileList) => {
    let nextFiles:IFile[] = Array.from(newFiles).map((file:File) => {
      return {
        uuid: uuid(),
        file,
      }
    });
    if ( !multiple ){
      dispatch(AssetsActions.setAssets({ type, contractType, assets: [] }));
    } else {
      nextFiles = [...files, ...nextFiles];
    }
    dispatch(AssetsActions.setFiles({ type, contractType, files: nextFiles }));
  }

  const filePicker = (
    <AssetsFilePicker type={type} contractType={contractType} multiple={multiple} onChange={handleChange} />
  );

  return (
    <React.Fragment>
      {label ? (
        <Typography sx={{
          display: 'block',
          color: 'rgba(0,0,0,0.87)',
          fontSize: '14px',
          fontWeight: 500
        }}>{label}</Typography>
      ) : null}
      <AssetsDragAndDrop multiple={multiple} onDrop={handleChange}>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
          {multiple ? filePicker : (
            !files?.length && !assets?.length ? filePicker : null
          )}
          {multiple && (
            <Typography display="block" textAlign="center">or drag {type === AssetType.Contracts ? 'documents' : type === AssetType.Images ? 'photos' : type} to this area to upload</Typography>
          )}
        </Box>
        <AssetsList type={type} contractType={contractType} />
      </AssetsDragAndDrop>
    </React.Fragment>
  )
}

export default AssetsUpload;
