import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';
import { getContent } from "utilities/Utilities";

const { accountId } = getContent('settings');

export default class RegionsAsync {
  private static _url:string = '/client/regions';
  private static _http:HttpClient = new HttpClient();

  // Fetch regions
  public static fetchRegions = createAsyncThunk('regions/fetchRegions', async (countryId: number, thunkApi) => {
    const response:Response = await this._http.get(this._url, { countryId });
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch region by slug
  public static fetchRegionBySlug = createAsyncThunk('regions/fetchRegionBySlug', async (params:any, thunkApi) => {
    const nextParams = { ...params, accountId };
    const response:Response = await this._http.get(`${this._url}/getByNameSlug`, nextParams);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch locations
  public static fetchLocations = createAsyncThunk('regions/fetchLocations', async (_, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/locations`, { accountId });
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });
}
