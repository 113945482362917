import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import ILocation from "models/Location";

export const selectRegions = (state:RootState) => state.regions.regions;
export const selectRegion = (state:RootState) => state.regions.region;
export const selectRegionsByCountryId = (state:RootState, countryId:number) => state.regions.regions[countryId];
export const selectLocations = (state:RootState) => state.regions.locations;

export const selectLocationsByCountryName = createSelector(
  [ selectLocations ],
  (locations) => {
    if (!locations) return null;

    const data = locations.reduce((acc:Record<string, ILocation[]>, cur) => ({
      ...acc, [cur.countryName]: [ ...(acc[cur.countryName] || []), cur]
    }), {});

    const sortedLocations = Object.entries(data).sort(([, a], [, b]) => b.length - a.length);
    return Object.fromEntries(sortedLocations);
  }
)
