import { FC, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Hooks
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useDialog from "hooks/useDialog";
// Async
import TimesharesAsync from "store/timeshares/timesharesAsync";
import SubscriptionsAsync from "store/subscriptions/subscriptionsAsync";
// Actions
import { TimesharesActions } from "store/timeshares/timesharesSlice";
// Selectors
import { selectIsTimesharesGroups, selectParams } from "store/timeshares/timesharesSelectors";
import { selectGuestModuleEnabled, selectSubscriptionModuleEnabled } from "store/accounts/accountsSelectors";
import { selectSelectedType } from "store/ui/uiSelectors";
// Components
import SearchBanner from "components/SearchBanner";
import Properties from "./Properties";
import DestinationTypes from "./DestinationTypes";
import AboutUs from "./AboutUs";
import SetPasswordForm from "components/SetPassword.form";
import Benefits from "./Benefits";
import Locations from "./Locations";
import ContactUs from "components/ContactUs";
// Utilities
import { getContent } from "utilities/Utilities";

const HomePage:FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const subscriptionModuleEnabled = useAppSelector(selectSubscriptionModuleEnabled);
  const guestModuleEnabled = useAppSelector(selectGuestModuleEnabled);
  const timesharesGroups = useAppSelector(selectIsTimesharesGroups);
  const params = useAppSelector(selectParams);
  const selectedType = useAppSelector(selectSelectedType);

  const { Dialog: SetPasswordDialog, openDialog:openSetPasswordDialog, closeDialog: closeSetPasswordDialog } = useDialog();

  const addSpecialOfferParam = () => {
    dispatch(TimesharesActions.setParams({...params, specialOfferOnly: true }));
  }

  useEffect(() => {
    if (guestModuleEnabled) {
      dispatch(TimesharesAsync.fetchTimesharesGroups({ groupSize: 4, type: selectedType }));
    }
    // eslint-disable-next-line
  }, [selectedType]);
  
  useEffect(() => {
    if (subscriptionModuleEnabled) {
      dispatch(SubscriptionsAsync.fetchBenefits({}));
    }
    if (pathname.includes('register') || pathname.includes('recover-password')) openSetPasswordDialog();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <SetPasswordDialog maxWidth="sm">
        <SetPasswordForm onClose={closeSetPasswordDialog} />
      </SetPasswordDialog>

      <SearchBanner />
      {subscriptionModuleEnabled && <Benefits />}
      {guestModuleEnabled && (
        <>
          <Properties
            timeshares={timesharesGroups?.specialOfferGroup}
            title="DashDeals"
            bgcolor="#f5f6f7"
            viewAllAdditionalFunc={addSpecialOfferParam}
          />
          <Properties
            timeshares={timesharesGroups?.topGroup}
            title={getContent('homePage').topProperties}
            bgcolor="#EBEEF0"
            showSkeletons={true}
          />
          <DestinationTypes destinationTypeGroups={timesharesGroups?.destinationTypeGroups} />
          <Locations />
          <ContactUs />
        </>
      )}
      <AboutUs />
    </Fragment>
  );
}

export default HomePage;
