import { RootState } from 'store';

export const selectTimeshares = (state:RootState) => state.timeshares.timeshares;
export const selectTimesharesByProperty = (state:RootState) => state.timeshares.timesharesByProperty;
export const selectTimeshareDetails = (state:RootState) => state.timeshares.timeshareDetails;
export const selectTotal = (state:RootState) => state.timeshares.total;
export const selectParams = (state:RootState) => state.timeshares.params;
export const selectMyTimesharesParams = (state:RootState) => state.timeshares.myTimesharesParams;
export const selectMyTimesharesTotal = (state:RootState) => state.timeshares.myTimesharesTotal;
export const selectIsMyTimeshares = (state:RootState) => state.timeshares.myTimeshares;
export const selectIsTimesharesGroups = (state:RootState) => state.timeshares.timesharesGroups;
export const selectFormData = (state:RootState) => state.timeshares.formData;
