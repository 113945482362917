import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import AccountsAsync from './accountsAsync';
// Types
import { PaymentProviders } from "types/PaymentTypes";
import { IUserAdditionalAttribut } from "models/UserAdditionalAttributes";

interface IState {
  settings: {
    paymentEnabled: boolean;
    paymentProvider: PaymentProviders | null;
    paymentCreditCardEnabled: boolean;
    paymentAchEnabled: boolean;
    chatModuleEnabled: boolean | null;
    subscriptionSquareLocationId: string;
    subscriptionSquareApplicationId: string;
    pollModuleEnabled: boolean | null;
    userAdditionalAttributes: IUserAdditionalAttribut[];
  };
  byDomain: {
    settings: any;
  };
}

const initialState:IState = {
  settings: {
    paymentEnabled: false,
    paymentProvider: null,
    paymentCreditCardEnabled: false,
    paymentAchEnabled: false,
    chatModuleEnabled: null,
    subscriptionSquareLocationId: '',
    subscriptionSquareApplicationId: '',
    pollModuleEnabled: null,
    userAdditionalAttributes: [],
  },
  byDomain: {
    settings: null,
  },
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      // Fetch accounts settings
      .addCase(AccountsAsync.fetchAccountsSetting.fulfilled, (state, action: PayloadAction<any>) => {
        state.settings = action.payload;
      })
      // Get by domain
      .addCase(AccountsAsync.getByDomain.fulfilled, (state, action: PayloadAction<any>) => {
        state.byDomain = action.payload;
      })
  }
});

export const AccountsActions = accountsSlice.actions;

export default accountsSlice.reducer;
