import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISubscriptionBenefit, ISubscriptionBenefitRequest, ISubscriptionPackage } from "models/Subscriptions";
import SubscriptionsAsync from "./subscriptionsAsync";
import BenefitRequestStatus from "types/BenefitRequestStatus";

interface IState {
  subscriptionPackages: ISubscriptionPackage[] | null;
  benefits: ISubscriptionBenefit[] | null;
  benefitRequests: ISubscriptionBenefitRequest[] | null;
  benefitRequestsTotal: number;
}

const initialState:IState = {
  subscriptionPackages: null,
  benefits: null,
  benefitRequests: null,
  benefitRequestsTotal: 0,
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
    // Fetch subscription package
    .addCase(SubscriptionsAsync.fetchSubscriptionPackages.fulfilled, (state, action:PayloadAction<ISubscriptionPackage[]>) => {
      state.subscriptionPackages = action.payload;
    })
    // Fetch benefit requests
    .addCase(SubscriptionsAsync.fetchBenefitRequests.fulfilled, (state, action:any) => {
      state.benefitRequests = action.payload.data;
      state.benefitRequestsTotal = action.payload.total;
    })
    // Create benefit request
    .addCase(SubscriptionsAsync.createBenefitRequest.fulfilled, (state, action:PayloadAction<ISubscriptionBenefitRequest>) => {
      state.benefitRequests = state.benefitRequests
        ? [action.payload, ...state.benefitRequests]
        : [action.payload];
        state.benefitRequestsTotal = state.benefitRequestsTotal + 1;
    })
    // Update benefit request
    .addCase(SubscriptionsAsync.updateBenefitRequest.fulfilled, (state, action:PayloadAction<ISubscriptionBenefitRequest>) => {
      state.benefitRequests = state.benefitRequests
        ? state.benefitRequests.map(benefitRequest => benefitRequest.id === action.payload.id ? action.payload : benefitRequest)
        : state.benefitRequests;
    })
    // Cancel benefit request
    .addCase(SubscriptionsAsync.cancelBenefitRequest.fulfilled, (state, action) => {
      const { arg: { benefitRequestId } } = action.meta;
      state.benefitRequests = state.benefitRequests
        ? state.benefitRequests.map(benefitRequest => benefitRequest.id === benefitRequestId ? {...benefitRequest, status: BenefitRequestStatus.Cancelled} : benefitRequest)
        : state.benefitRequests;
    })
    // Fetch benefits
    .addCase(SubscriptionsAsync.fetchBenefits.fulfilled, (state, action:PayloadAction<ISubscriptionBenefit[]>) => {
      state.benefits = action.payload;
    })
  }
});

export const SubscriptionsActions = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
