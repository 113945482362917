import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Models
import IAsset from "models/Asset";
// Types
import AssetType from "types/AssetType";
import AssetContractType from "types/AssetContractType";

export interface IFile {
  uuid: string;
  file: File;
}

interface IState {
  images: {
    files: IFile[];
    assets: IAsset[];
  },
  contracts: {
    owner: {
      files: IFile[];
      assets: IAsset[];
    },
  };
  assetsIdsToDelete: number[];
}

const initialState:IState = {
  images: {
    files: [],
    assets: []
  },
  contracts: {
    owner: {
      files: [],
      assets: []
    }
  },
  assetsIdsToDelete: [],
};

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setFiles: (state, action:PayloadAction<{ type: AssetType, contractType: AssetContractType | undefined, files: IFile[] }>) => {
      state.contracts = action.payload.type === AssetType.Contracts && action.payload.contractType ? {
        ...state.contracts,
        [action.payload.contractType]: {
          ...state.contracts[action.payload.contractType],
          files: action.payload.files
        }
      } : state.contracts;
      state.images = action.payload.type !== AssetType.Contracts ? {
        ...state.images,
        files: action.payload.files
      } : state.images;
    },
    setAssets: (state, action:PayloadAction<{ type: AssetType, contractType: AssetContractType | undefined, assets: IAsset[] }>) => {
      state.contracts = action.payload.type === AssetType.Contracts && action.payload.contractType ? {
        ...state.contracts,
        [action.payload.contractType]: {
          ...state.contracts[action.payload.contractType],
          assets: action.payload.assets
        }
      } : state.contracts;
      state.images = action.payload.type !== AssetType.Contracts ? {
        ...state.images,
        assets: action.payload.assets
      } : state.images;
    },
    addAssetIdToDelete: (state, action) => {
      state.assetsIdsToDelete = [...state.assetsIdsToDelete, action.payload]
    },
    removeAssetIdFromDelete: (state, action) => {
      state.assetsIdsToDelete = state.assetsIdsToDelete.filter((assetId:number) => assetId !== action.payload)
    },
    setInitialReducer: (state) => {
      state.images = initialState['images'];
      state.contracts = initialState['contracts']
      state.assetsIdsToDelete = initialState['assetsIdsToDelete'];
    },
  }
});

export const AssetsActions = assetsSlice.actions;

export default assetsSlice.reducer;
