import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';
import { getContent } from "utilities/Utilities";

const { accountId } = getContent('settings');

export default class DestinationTypesAsync {
  private static _url:string = '/client/destinationTypes';
  private static _http:HttpClient = new HttpClient();

  // Fetch destination yypes
  public static fetchDestinationTypes = createAsyncThunk('destinationTypes/fetchDestinationTypes', async (params:any, thunkApi) => {
    const nextParams = { accountId, ...params };
    const response:Response = await this._http.get(this._url, nextParams);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });
}
