import { useCallback, useEffect, useState, Fragment, ChangeEvent, FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useDialog from 'hooks/useDialog';
// Async
import TimesharesAsync from 'store/timeshares/timesharesAsync';
import PropertiesAsync from 'store/properties/propertiesAsync';
// Components
import PropertiesFormDialog from 'components/PropertiesForm.dialog';
// Types
import ViewTypes from 'types/ViewTypes';
import TimeshareStatus from 'types/TimeshareStatus';
import TimeshareTypes from 'types/TimeshareTypes';
import TimeshareSeasons from 'types/TimeshareSeasons';
import TimeshareUsages from 'types/TimeshareUsages';
import AssetType from 'types/AssetType';
import AssetContractType from 'types/AssetContractType';
// Models
import ITimeshare from 'models/Timeshare';
import IProperty from 'models/Property';
// Actions
import { TimesharesActions } from 'store/timeshares/timesharesSlice';
import { PropertiesActions } from 'store/properties/propertiesSlice';
// Selectors
import { selectFormData } from 'store/timeshares/timesharesSelectors';
import { selectAllProperties } from 'store/properties/propertiesSelectors';
// Mui
import {
  Button, DialogActions, DialogContent,
  DialogTitle, TextField, Autocomplete,
  Grid, FormControl, InputLabel, Select as MuiSelect,
  MenuItem, Box, debounce, FormHelperText,
  IconButton, InputAdornment,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { Add as AddIcon } from '@mui/icons-material';
// Components
import { Input, Select } from 'components/Controls';
import BrokerLicense from 'components/BrokerLicense';
import AssetsUpload from 'components/AssetsUpload';
// Utilities
import { isFieldRequired, isValidDate } from 'utilities/Validation';
import { getContent, getOptionsFromEnum } from "utilities/Utilities";

interface IFormData {
  type: TimeshareTypes;
  view: string;
  bedrooms: string | number;
  bathrooms: string | number;
  netPrice: string | number;
  ownerNotes: string;
  status: TimeshareStatus | null;

  start: any;
  end: any;

  week?: number | string;
  season?: TimeshareSeasons | null;
  ownershipType?: string;
  usage?: TimeshareUsages | null;
  nextUsageYear?: string;
  maintenanceFees?: string;
}

type Props = {
  onClose: () => void;
  timeshare?: ITimeshare | null;
  type: TimeshareTypes;
}

const MyTimesharesForm:FC<Props> = ({ onClose, timeshare, type }) => {
  const timeshareId:number | null = timeshare?.id || null;
  const dispatch = useAppDispatch();

  const properties = useAppSelector(selectAllProperties) || [];
  const formData = useAppSelector(selectFormData);

  const [selectedProperty, setSelectedProperty] = useState(formData.property);
  const [selectedPropertyError, setSelectedPropertyError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingProperties, setIsLoadingProperties] = useState<boolean>(false);

  const handleSelectedProperty = (property: IProperty | null) => {
    setSelectedProperty(property);
    setSelectedPropertyError(property ? false : true);
  }

  useEffect(() => {
    setSelectedProperty(formData.property);
  }, [formData]);

  const { control, handleSubmit, formState:{ errors }, setValue, watch, setError } = useForm<IFormData>({
    defaultValues: {
      type: timeshare?.type || type,
      view: timeshare?.view || '',
      bedrooms: timeshare?.bedrooms || '',
      bathrooms: timeshare?.bathrooms || '',
      netPrice: timeshare?.netPrice || '',
      ownerNotes: timeshare?.ownerNotes || '',

      start: timeshare?.start || '',
      end: timeshare?.end || '',

      week: timeshare?.week || ' ',
      season: timeshare?.season || null,
      ownershipType: timeshare?.ownershipType || '',
      usage: timeshare?.usage || null,
      nextUsageYear: timeshare?.nextUsageYear || '',
      maintenanceFees: timeshare?.maintenanceFees || '',
    }
  });

  const startDate = watch('start');
  const endDate = watch('end');

  const isRentType = type === TimeshareTypes.Rent;

  const onSubmit = handleSubmit((data:IFormData) => {
    if (!startDate && endDate) {
      setError('start', { type: 'required', message: 'Either both or none dates should be selected' }, { shouldFocus: true });
      return;
    }

    if (startDate && !endDate) {
      setError('end', { type: 'required', message: 'Either both or none dates should be selected' }, { shouldFocus: true });
      return;
    }

    if (!selectedProperty && !timeshareId) {
      setSelectedPropertyError(true);
      return;
    }

    const {
      week, season, ownershipType, usage, nextUsageYear, maintenanceFees,
      start, end, netPrice, type, ...otherData
    } = data;
    const nextData:any = { ...otherData };

    if (netPrice) nextData['netPrice'] = Number(netPrice);

    if (start && end && isRentType) {
      nextData['start'] = dayjs(start).format('YYYY-MM-DD');
      nextData['end'] = dayjs(end).format('YYYY-MM-DD');
    }

    if (!isRentType) {
      if (week && typeof week === 'number') nextData['week'] = week;
      if (season) nextData['season'] = season;
      if (ownershipType) nextData['ownershipType'] = ownershipType;
      if (usage) nextData['usage'] = usage;
      if (nextUsageYear) nextData['nextUsageYear'] = nextUsageYear;
      if (maintenanceFees) nextData['maintenanceFees'] = maintenanceFees;
    }

    setIsLoading(true);

    if (timeshareId) {
      const newData = { id: timeshareId, ...nextData };
      dispatch(TimesharesAsync.updateTimeshares(newData))
        .unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    } else {
      const newData = { propertyId: selectedProperty?.id, type, ...nextData };
      dispatch(TimesharesAsync.createTimeshares(newData))
        .unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(TimesharesActions.setFormData(null));
    }
    // eslint-disable-next-line
  }, []);

  const { Dialog, openDialog, closeDialog } = useDialog();

  // eslint-disable-next-line
  const debounceProperty = useCallback(debounce((search:string) => {
    if (search.trim()) {
      setIsLoadingProperties(true);
      dispatch(PropertiesAsync.fetchProperties({ search, size: 50 }))
        .unwrap()
        .finally(() => setIsLoadingProperties(false));
    } else {
      dispatch(PropertiesActions.clearProperties());
    }
  }, 500), []);

  const onChangeProperty = (event:ChangeEvent<HTMLInputElement>) => {
    debounceProperty(event.target.value);
  }

  return (
    <Fragment>
      <DialogTitle>{`${timeshareId ? 'Edit' : 'Create'} ${getContent('labels').labelTimeshareSingularText}`}</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={onSubmit} noValidate>
          <Grid container spacing={2}>
            {isRentType ? (
              <Fragment>
                <Grid item xs={12} md={6}>
                  {/* Start date */}
                  <Controller
                    control={control} name="start"
                    rules={{ validate: {
                      isValid: (date: Dayjs | null) => isValidDate(date),
                    } }}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        value={value ? dayjs(value) : null}
                        minDate={dayjs().subtract(10, 'year')}
                        maxDate={endDate ? dayjs(endDate).subtract(1, 'day') : dayjs().add(10, 'year')}
                        onChange={(date:any) => {
                          onChange(date);
                          if (date && !endDate) {
                            setValue('end', dayjs(date).add(7, 'day'));
                          }
                        }}
                        label="Start date"
                        views={['year', 'month', 'day']}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            margin: 'none',
                            error: Boolean(errors.start),
                            helperText: errors.start ? errors.start.message : ''
                          },
                          actionBar: {
                            actions: ['clear', 'accept'],
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
    
                <Grid item xs={12} md={6}>
                  {/* End date */}
                  <Controller
                    control={control} name="end"
                    rules={{ validate: {
                      isValid: (date: Dayjs | null) => isValidDate(date),
                    } }}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        value={value ? dayjs(value) : null}
                        maxDate={dayjs().add(10, 'year')}
                        minDate={startDate ? dayjs(startDate).add(1, 'day') : dayjs().subtract(10, 'year')}
                        onChange={(date:any) => {
                          onChange(date);
                          if (date && !startDate) {
                            setValue('start', dayjs(date).subtract(7, 'day'));
                          }
                        }}
                        label="End date"
                        views={['year', 'month', 'day']}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            margin: 'none',
                            error: Boolean(errors.end),
                            helperText: errors.end ? errors.end.message : ''
                          },
                          actionBar: {
                            actions: ['clear', 'accept'],
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid item xs={6}>
                  <Controller
                    control={control} name="week"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={weekOptions}
                        label="Week"
                        margin="none"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control} name="season"
                    render={({ field: { value, ...nextFields } }) => (
                      <Select
                        {...nextFields}
                        value={value || ''}
                        options={getOptionsFromEnum(TimeshareSeasons)}
                        label="Season"
                        margin="none"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control} name="ownershipType"
                    render={({ field }) => (
                      <Input
                        {...field}
                        label="Ownership type"
                        margin="none"
                        helperText="i.e. Deeded or Right to use"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control} name="usage"
                    render={({ field: { value, ...nextFields } }) => (
                      <Select
                        {...nextFields}
                        value={value || ''}
                        options={getOptionsFromEnum(TimeshareUsages)}
                        label="Usage"
                        margin="none"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control} name="nextUsageYear"
                    render={({ field }) => (
                      <Input
                        {...field}
                        label="Next usage year"
                        margin="none"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control} name="maintenanceFees"
                    render={({ field }) => (
                      <Input
                        {...field}
                        label="Maintenance fees"
                        margin="none"
                      />
                    )}
                  />
                </Grid>
              </Fragment>
            )}

            <Grid item xs={12} md={6}>
              {/* Amount bedrooms */}
              <Controller
                control={control} name="bedrooms"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="Bedrooms-select-label">Bedrooms</InputLabel>
                    <MuiSelect
                      {...field}
                      labelId="Bedrooms-select-label"
                      id="Bedrooms-select"
                      label="Bedrooms"
                      name="bedrooms"
                    >
                      <MenuItem value="">Unknown</MenuItem>
                      <MenuItem value="studio">Studio</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                    </MuiSelect>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* Amount bathrooms */}
              <Controller
                control={control} name="bathrooms"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="Bathrooms-select-label">Bathrooms</InputLabel>
                    <MuiSelect
                      {...field}
                      labelId="Bathrooms-select-label"
                      id="Bathrooms-select"
                      label="Bathrooms"
                      name="bathrooms"
                    >
                      <MenuItem value="">Unknown</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                    </MuiSelect>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* View */}
              <Controller
                control={control} name="view"
                rules={{ required: isFieldRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="View"
                    select
                    fullWidth
                    error={Boolean(errors.view)}
                    helperText={errors.view ? errors.view.message : ''}
                    required
                  >
                    {(Object.keys(ViewTypes) as Array<keyof typeof ViewTypes>).map((key) => (
                      <MenuItem key={key} value={ViewTypes[key]}>{ViewTypes[key]}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* netPrice */}
              <Controller
                control={control} name="netPrice"
                render={({ field }) => (
                  <Input
                    {...field}
                    fullWidth
                    label="Price"
                    type="number"
                    margin="none"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                  />
                )}
              />
            </Grid>

            {!timeshareId && (
              <Fragment>
                <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center', mt: 2, width: '100%' }}>
                  <Autocomplete
                    sx={{ flexGrow: 1, ml: 2 }}
                    disablePortal
                    id="my-property-timeshare-select-propertyId"
                    options={properties}
                    value={selectedProperty}
                    getOptionLabel={(property) => property.name}
                    onChange={(_:any, value:IProperty | null) => handleSelectedProperty(value || null)}
                    onClose={() => debounceProperty('')}
                    loading={isLoadingProperties}
                    loadingText="Search..."
                    noOptionsText=""
                    forcePopupIcon={false}
                    filterOptions={options => options}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => 
                      <TextField
                        {...params}
                        label="Property"
                        fullWidth
                        required
                        error={selectedPropertyError}
                        helperText={selectedPropertyError ? 'This field is required' : ''}
                        onChange={onChangeProperty}
                        placeholder="e.g. Marriott"
                      />
                    }
                  />
                  <Button
                    startIcon={<AddIcon />}
                    sx={{ height: '56px', width: 'max-content', display: { xs: 'none', md: 'inline-flex' } }}
                    onClick={openDialog}
                  >
                    Create property
                  </Button>
                  <IconButton
                    sx={{ height: '56px', display: { md: 'none' }, color: getContent('theme').secondaryColor }}
                    onClick={openDialog}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                <FormHelperText sx={{ ml: 2, mt: 0.5 }}>
                  Start typing to find your property. If not found then create a new property.
                </FormHelperText>
              </Fragment>
            )}

            {!isRentType ? (
              <Grid item xs={12}>
                <AssetsUpload
                  label="Documents"
                  type={AssetType.Contracts}
                  contractType={AssetContractType.Owner}
                  multiple={true}
                  assets={timeshare?.ownerDocuments}
                />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              {/* ownerNotes */}
              <Controller
                control={control} name="ownerNotes"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Notes"
                    type="text"
                    multiline
                    rows={3}
                  />
                )}
              />
            </Grid>

            {!isRentType ? (
              <Grid item xs={12}>
                <BrokerLicense />
              </Grid>
            ) : null}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ width: '100%' }}>
        <Button
          variant="text"
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          {timeshareId ? 'Save' : 'Create' }
        </LoadingButton>
      </DialogActions>
      <Dialog maxWidth="sm">
        <PropertiesFormDialog onClose={closeDialog} />
      </Dialog>
    </Fragment>
  )
}

export default MyTimesharesForm;

const weekOptions = [
  { label: 'Floating', value: ' ' },
  ...(new Array(52).fill('').map((_, i) => ({ label: `${i + 1}`, value: i + 1 })))
];
