import React, { useLayoutEffect, useRef } from "react";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Async
import NotificationsAsync from "store/notifications/notificationsAsync";
// models
import INotification from "models/Notification";
// Selectors
import { selectAllNotifications, selectTotal } from "store/notifications/notificationsSelectors";
// mui
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
// components
import NotificationCard from "./NotificationsCard";

const size = 50;
let page = 0;
let observer:any;

const NotificationsList:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const notifications = useAppSelector(selectAllNotifications);
  const total = useAppSelector(selectTotal);

  const totalPages = Math.ceil(total / size) - 1;

  const notificationRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (notifications && notifications.length < size) return;
    observer = new IntersectionObserver(([entry]:any) => {
      if ( page === totalPages ) return;
      if ( !entry.isIntersecting ) return;

      page++;

      dispatch(NotificationsAsync.refetchNotifications({ page, size }));

      observer.unobserve(entry.target);
    });
    return () => {
      // eslint-disable-next-line
      observer.unobserve((notificationRef.current as Element));
    }
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (notifications && notifications.length < size) return;
    if ( observer ) observer.observe((notificationRef.current as Element));
    // eslint-disable-next-line
  }, [notifications]);

  return (
    <Box className={classes.list} sx={{ mt: { xs: 2, sm: 3 } }}>
      <Box className={classes.items}>
        {notifications?.map((notification: INotification, index: number) => (
          <Box key={notification.id} ref={index === notifications.length - 5 ? notificationRef : null }>
            <NotificationCard notification={notification} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default NotificationsList;

const useStyles = makeStyles({
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 350px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 390px)',
    },
    '@media (min-width: 1240px)': {
      maxHeight: 'calc(100vh - 386px)',
    }
  },
});
