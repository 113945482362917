import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { v4 as uuid } from 'uuid';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Actions
import { ChatsActions } from "store/chats/chatsSlice";
// Selectors
import { selectCurrentUser } from "store/users/usersSelectors";
// MUI
import { Button, Divider, InputBase, Paper } from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
// Components
import Smiles from "components/Smiles";
import dayjs from "dayjs";

type Props = {
  scrollToEnd: () => void,
}

interface IFormData {
  message: string;
}

const SendMessage:React.FC<Props> = ({ scrollToEnd }) => {
  const dispatch = useAppDispatch();
  const chatId = useParams()['chatId'];

  const currentUser = useAppSelector(selectCurrentUser);

  const { control, handleSubmit, reset, watch, setValue } = useForm<IFormData>({
    defaultValues: {
      message: '',
    },
  });

  const onSubmit = handleSubmit((data: IFormData) => {
    const message = data.message.trim();
    if (!message) return;

    const nextData = {
      id: uuid(),
      message,
      sender: {...currentUser},
      createdOn: dayjs(),
      chatId: chatId ? +chatId : null,
    };

    dispatch(ChatsActions.sendMessage(nextData));
    reset();
    scrollToEnd();
  });

  const messageWatcher = watch('message');

  const addSmile = (smile: string) => {
    setValue('message', `${messageWatcher}${smile}`);
  }

  const handleKeyDown = (event: any) => {
    const { shiftKey, key } = event;
    
    if (shiftKey && key === 'Enter') {
      event.preventDefault();
      setValue('message', `${messageWatcher}\n`);
    }
    if (!shiftKey && key === 'Enter') {
      event.preventDefault();
      onSubmit();
    }
  }

  return (
    <Paper
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.12)' }}
      component="form"
      onSubmit={onSubmit}
    >
      <Controller
        control={control} name="message"
        rules={{ required: true }}
        render={({ field }) => (
          <InputBase
            {...field}
            multiline
            rows={2}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Write a message..."
            inputProps={{ 'aria-label': 'write a message' }}
            onKeyDown={handleKeyDown}
          />
        )}
      />
      <Smiles addSmile={addSmile} />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <Button
        sx={{ p: 1 }}
        endIcon={<SendOutlinedIcon />} 
        type="submit"
      >
        Send
      </Button>
    </Paper>
  )
}

export default SendMessage;
