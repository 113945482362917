import { Box, Typography } from '@mui/material';
import { FC } from 'react';


const BrokerLicense:FC = () => {
  return (
    <Box sx={{
      py: 2,
      color: 'rgba(0, 0, 0, 0.7)',
      textAlign: 'justify',
      '& p': {
        fontSize: '13px'
      }
    }}>
      <Typography>
        DashWeek Brokerage, LLC is a licensed real estate company offering luxurious timeshare resales at competitive prices. The benefits of vacation ownership allow you to explore some of the most sought-after vacation destinations worldwide. Buying a timeshare resale on the secondary market enables you to get all the benefits of vacation ownership at a fraction of the cost.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Whether you are interested in selling or buying a timeshare, our real estate professionals use reputable licensed closing and escrow companies that make the entire process seamless. To learn more about buying or selling a timeshare, reach out to one of our licensed agents today.
      </Typography>
      <Typography>Disclaimer: All timeshare resale transactions will be facilitated through DashWeek</Typography>
      <Typography>Brokerage, LLC</Typography>
      <Typography>Dana Ferretti</Typography>
      <Typography>Licensed Real Estate Broker #BK3260198</Typography>
    </Box>
  )
}

export default BrokerLicense;
