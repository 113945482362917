import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Hooks
import { useAppDispatch, useAppSelector } from 'hooks/redux';
// Models
import { RootState } from 'store';
import IRegion from 'models/Region';
import ICountry from 'models/Country';
// Async
import PropertiesAsync from 'store/properties/propertiesAsync';
import DestinationTypesAsync from 'store/destinationTypes/destinationTypesAsync';
import RegionsAsync from 'store/regions/regionsAsync';
import CountriesAsync from 'store/countries/countriesAsync';
// Actions
import { UiActions } from 'store/ui/uiSlice';
// Selectors
import { selectDestinationTypes } from 'store/destinationTypes/destinationTypesSelectors';
import { selectCountries } from 'store/countries/countriesSelectors';
import { selectRegionsByCountryId } from 'store/regions/regionsSelectors';
// Mui
import {
  Button, DialogActions, DialogContent,
  DialogTitle, TextField, MenuItem, InputLabel,
  FormControl, OutlinedInput, Box, Chip, Select,
  FormHelperText, Autocomplete, Tooltip
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Utilities
import { isFieldRequired } from 'utilities/Validation';
import { getContent } from 'utilities/Utilities';

interface IFormData {
  name: string,
  description: string,
  destinationTypesIds: number[],
  country: string,
  regionId: IRegion | null,
}

type Props = {
  onClose: () => void;
}

const PropertiesFormDialog:React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const destinationTypes = useAppSelector(selectDestinationTypes) || [];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const countries = useAppSelector(selectCountries) || [];
  const [country, setCountry] = useState<ICountry | null>(null);
  const regions = useAppSelector((state:RootState) => selectRegionsByCountryId(state, (country?.id || 0))) || [];

  const handleChangeCountry = (country: ICountry | null) => {
    setCountry(country);
  }

  const { control, handleSubmit, formState:{ errors }, setValue } = useForm<IFormData>({
    defaultValues: {
      name: '',
      description: '',
      destinationTypesIds: [],
      country: '',
      regionId: null,
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    const nextData = { ...data, regionId: data.regionId?.id };
    setIsLoading(true);
    dispatch(PropertiesAsync.createProperty(nextData))
      .unwrap()
      .then(() => {
        dispatch(UiActions.enqueueSnackbar({ message: 'Property was created' }));
      })
      .then(() => onClose())
      .finally(() => setIsLoading(false));
  });

  useEffect(() => {
    dispatch(DestinationTypesAsync.fetchDestinationTypes({}));
    dispatch(CountriesAsync.fetchCountries());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!country) return;
    dispatch(RegionsAsync.fetchRegions(country.id));
    // eslint-disable-next-line
  }, [country]);

  return (
    <React.Fragment>
      <DialogTitle>Create property</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={onSubmit} noValidate>
          {/* Name */}
          <Controller
            control={control} name="name"
            rules={{ required: isFieldRequired }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin='normal'
                label="Name"
                type="text"
                error={Boolean(errors.name)}
                helperText={errors.name ? errors.name.message : ''}
                required
              />
            )}
          />

          
          {/* Destination Type */}
          <Controller
            control={control} name="destinationTypesIds"
            rules={{ required: isFieldRequired }}
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel id="destinationType-multiple-chip-label">{`${getContent('labels').labelDestinationTypeSingularText}(s) *`}</InputLabel>
                <Select
                  {...field}
                  labelId="destinationType-multiple-chip-label"
                  id="destinationType-multiple-chip"
                  multiple
                  required
                  error={Boolean(errors.destinationTypesIds)}
                  input={<OutlinedInput id="destinationType-multiple-chip" label="Destination type(s) *" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value:number) => (
                        <Chip key={value} label={destinationTypes.find(destinationType => destinationType.value === value)?.label} sx={{ height: '23px' }} />
                      ))}
                    </Box>
                  )}
                >
                  {destinationTypes.map((destinationType) => (
                    <MenuItem
                      key={destinationType.value}
                      value={destinationType.value}
                    >
                      {destinationType.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ color: '#d32f2f' }}>{errors.destinationTypesIds ? (errors.destinationTypesIds as any).message : ''}</FormHelperText>
              </FormControl>
            )}
          />

          <Autocomplete
            value={country}
            disablePortal
            id="select-countryId"
            options={countries}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(country) => country.name}
            onChange={(_:any, value:ICountry | null) => {
              setValue('regionId', null);
              handleChangeCountry(value || null);
            }}
            loadingText=""
            noOptionsText=""
            forcePopupIcon={false}
            renderOption={(props, option) => (
              <li {...props} key={option.id} >
                {option.name}
              </li>
            )}
            renderInput={(params) => 
              <TextField
                {...params}
                label="Country"
                margin="normal"
                placeholder="e.g. USA"
              />
            }
          />

          {/* Region */}
          <Controller
            control={control} name="regionId"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                value={value}
                disablePortal
                id="select-regionId"
                options={regions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(region) => region.name}
                onChange={(_:any, value:IRegion | null) => onChange(value || null)}
                loadingText=""
                noOptionsText=""
                forcePopupIcon={false}
                renderOption={(props, option) => (
                  <li {...props} key={option.id} >
                    {option.name}
                  </li>
                )}
                disabled={!country}
                renderInput={(params) => 
                  <Tooltip title={!country ? 'Select country' : ''}>
                    <TextField
                      {...params}
                      label="Region"
                      margin="normal"
                      placeholder="e.g. FL"
                    />
                  </Tooltip>
                }
              />
            )}
          />

          {/* Description */}
          <Controller
            control={control} name="description"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin='normal'
                label="Description"
                type="text"
                multiline
                rows={5}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={onClose}
        >Cancel</Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          Create
        </LoadingButton>
      </DialogActions>
    </React.Fragment>
  )
}

export default PropertiesFormDialog;
