import AssetContractType from "types/AssetContractType";
import AssetType from "types/AssetType";
import { RootState } from "..";

export const selectFiles = (state:RootState, props:{ type:AssetType, contractType:AssetContractType | undefined }) => {
  const { type, contractType } = props;
  if ( type === AssetType.Contracts ){
    if ( !contractType ) throw new Error('`contractType` is required when type = `contracts`');
    return state.assets.contracts[contractType].files;
  }
  return state.assets.images.files;
};

export const selectAssets = (state:RootState, props:{ type:AssetType, contractType:AssetContractType | undefined }) => {
  const { type, contractType } = props;
  if ( type === AssetType.Contracts ){
    if ( !contractType ) throw new Error('`contractType` is required when type = `contracts`');
    return state.assets.contracts[contractType].assets;
  }
  return state.assets.images.assets;
};

export const selectAssetsIdsToDelete = (state:RootState) => state.assets.assetsIdsToDelete;
