import { createAsyncThunk } from "@reduxjs/toolkit";
import { UsersActions } from "store/users/usersSlice";
// Utilities
import HttpClient from 'utilities/HttpClient';
import { getContent } from "utilities/Utilities";

const { accountId } = getContent('settings');

export default class SubscriptionsAsync {
  private static _url:string = '/client/subscriptions';
  private static _http:HttpClient = new HttpClient();

  // Cancel subscription
  public static cancelSubscription = createAsyncThunk('subscriptions/cancelSubscription', async (subscriptionId:any, thunkApi) => {
    const response:Response = await this._http.put(`${this._url}/${subscriptionId}/actions/cancel`, null);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch subscription package
  public static fetchSubscriptionPackages = createAsyncThunk('subscription/fetchSubscriptionPackages', async (_:any, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/packages`, { accountId });
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Create Subscription
  public static createSubscription = createAsyncThunk('subscriptions/createSubscription', async (data:any, thunkApi) => {
    const response:Response = await this._http.post(this._url, data);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
  });

  // Fetch benefit requests
  public static fetchBenefitRequests = createAsyncThunk('subscription/fetchBenefitRequests', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/benefitRequests`, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Create benefit request
  public static createBenefitRequest = createAsyncThunk('subscriptions/createBenefitRequest', async (data:any, thunkApi) => {
    const response:Response = await this._http.post(`${this._url}/benefitRequests`, data);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    thunkApi.dispatch(UsersActions.toggleBenefitAvailable(data.benefitId));
    return response.json();
  });

  // Update benefit request
  public static updateBenefitRequest = createAsyncThunk('subscriptions/updateBenefitRequest', async (benefitRequestData:any, thunkApi) => {
    const { id, ...data } = benefitRequestData;
    const response:Response = await this._http.put(`${this._url}/benefitRequests/${id}`, data);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Cancel benefit request
  public static cancelBenefitRequest = createAsyncThunk('subscriptions/cancelBenefitRequest', async (data:any, thunkApi) => {
    const { benefitRequestId, benefitId } = data;
    const response:Response = await this._http.put(`${this._url}/benefitRequests/${benefitRequestId}/actions/cancel`, null);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    thunkApi.dispatch(UsersActions.toggleBenefitAvailable(benefitId));
  });

  // Fetch benefits
  public static fetchBenefits = createAsyncThunk('subscription/fetchBenefits', async (_:any, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/benefits`, { accountId });
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });
}
