import { FC, Fragment } from 'react';
// Models
import { ISubscriptionBenefit } from 'models/Subscriptions';
// MUI
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// Components
import { InfoBlock } from 'components/Utilities';

type Props = {
  onClose: () => void;
  benefit: ISubscriptionBenefit;
}

const BenefitInfo:FC<Props> = ({ onClose, benefit }) => {
  const imgUrl = benefit.image ? benefit.image.url : '/img/noImageAvailable.png';

  return (
    <Fragment>
      <DialogTitle>
        {benefit.name}
      </DialogTitle>
      <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
        <img src={imgUrl} alt={benefit.name} style={{ height: '200px', objectFit: 'contain', marginBottom: '20px' }} />
        <InfoBlock title="Frequency" text={benefit.frequency} />
        {benefit.value && <InfoBlock title="Value" text={benefit.value} />}
        {benefit.description && <InfoBlock title="Description" text={benefit.description} />}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Fragment>
  );
}

export default BenefitInfo;
