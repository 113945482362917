import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import DestinationTypesAsync from "./destinationTypesAsync";
// Models
import IDestinationType from "models/DestinationType";

interface IState {
  destinationTypes: IDestinationType[] | null,
}

const initialState:IState = {
  destinationTypes: null,
};

const destinationTypesSlice = createSlice({
  name: 'destinationTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch destination types
      .addCase(DestinationTypesAsync.fetchDestinationTypes.pending, (state) => {
        state.destinationTypes = null;
      })
      .addCase(DestinationTypesAsync.fetchDestinationTypes.fulfilled, (state, action:PayloadAction<any>) => {
        state.destinationTypes = action.payload;
      })
  }
});

export const PropertiesActions = destinationTypesSlice.actions;

export default destinationTypesSlice.reducer;
