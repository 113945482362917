import { FC, Fragment } from "react";
// components
import SearchBanner from "components/SearchBanner";
import ContactUs from "components/ContactUs";

const ContactUsPage:FC = () => {
  return (
    <Fragment>
      <SearchBanner />
      <ContactUs />
    </Fragment>
  )
}

export default ContactUsPage;
