import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import RegionsAsync from "./regionsAsync";
// Models
import IRegion from "models/Region";
import ILocation from "models/Location";

interface IState {
  regions: Record<number, IRegion[]>;
  region: IRegion | null;
  locations: ILocation[] | null;
}

const initialState:IState = {
  regions: {},
  region: null,
  locations: null,
};

const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch regions
      .addCase(RegionsAsync.fetchRegions.fulfilled, (state, action) => {
        const { arg:countryId } = action.meta;
        state.regions = { ...state.regions, [countryId]: action.payload };
      })
      // Fetch region by slug
      .addCase(RegionsAsync.fetchRegionBySlug.fulfilled, (state, action:PayloadAction<IRegion>) => {
        state.region = action.payload;
      })
      // Fetch locations
      .addCase(RegionsAsync.fetchLocations.fulfilled, (state, action:PayloadAction<ILocation[]>) => {
        state.locations = action.payload;
      })
  }
});

export const regionsActions = regionsSlice.actions;

export default regionsSlice.reducer;
