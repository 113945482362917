import IOption from "models/Option";

export const textFromCamelToNormalCase = (text:string) => {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, (l:string) => l.toUpperCase());
}

export const textToCapitalizeCase = (text: string) => {
  return text.replace(/^\w/, c => c.toUpperCase());
}

export const formatCash = (amount: number = 0): string => {
  return `$${amount.toLocaleString('en-EN')}`;
}

export const formatePhone = (phone: string) => {
  return phone ? `(${phone.slice(0,3)}) ${phone.slice(3, 6)}-${phone.slice(6)}` : '';
}

export const checkPage = (currentPage:number, limit:number, total:number): number => {
  const totalPages = Math.ceil(total / limit) - 1;
  return currentPage > totalPages ? totalPages : currentPage;
}

export const getDomain = ():string => {
  const { hostname } = window.location;
  if (hostname === 'localhost') return 'dashweek.com';
  return hostname.split('.').splice(-2).join('.');
}

export const getContent = (property: string) => {
  const domain = getDomain();

  const Content = require(`content/${domain}.json`);
  return Content[property];
}

export const openWindow = (link: string) => window.open(link, '_blank');

export const getOptionsFromEnum = (Enum: any) => {
  return (Object.keys(Enum) as Array<keyof typeof Enum>).map(key => ({value: Enum[key], label: textFromCamelToNormalCase(key as string) })) as IOption[];
}
