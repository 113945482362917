import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';

export default class NotificationsAsync {
  private static _url:string = '/client/notifications';
  private static _http:HttpClient = new HttpClient();

  // Fetch notifications
  public static fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(this._url, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Refetch notifications
  public static refetchNotifications = createAsyncThunk('notifications/refetchMessages', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(this._url, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Mark as read
  public static markAsReadNotifications = createAsyncThunk('notifications/markAsReadNotifications', async (notificationIds:number[], thunkApi) => {
    const response:Response = await this._http.put(`${this._url}/actions/markAsRead`, notificationIds);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return notificationIds;
  });

  // Delete notification
  public static deleteNotifications = createAsyncThunk('notifications/deleteNotifications', async (notificationIds:number[], thunkApi) => {
    const response:Response = await this._http.put(`${this._url}/actions/delete`, notificationIds);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return notificationIds;
  });
}
