import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import PollsAsync from "./pollsAsync";
// Models
import { IPoll } from "models/Poll";

interface IState {
  polls: IPoll[] | null;
  selectedPoll: IPoll | null;
  total: number;
  params: {
    size: number;
    page: number;
    voted: string;
  };
}

const initialState:IState = {
  polls: null,
  selectedPoll: null,
  total: 0,
  params: {
    size: 20,
    page: 0,
    voted: 'true',
  },
};

const pollSlice = createSlice({
  name: 'poll',
  initialState,
  reducers: {
    setSelectedPoll: (state:IState, action:PayloadAction<IPoll>) => {
      state.selectedPoll = action.payload;
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch polls
      .addCase(PollsAsync.fetchPolls.pending, (state, action) => {
        state.polls = null;
        state.total = 0;
        state.params = action.meta.arg;
      })
      .addCase(PollsAsync.fetchPolls.fulfilled, (state, action:PayloadAction<any>) => {
        state.polls = action.payload.data || [];
        state.total = action.payload.total;
      })
      // Fetch poll by id
      .addCase(PollsAsync.fetchPoll.fulfilled, (state, action:PayloadAction<IPoll>) => {
        state.selectedPoll = action.payload;
      })
      // Vote
      .addCase(PollsAsync.vote.fulfilled, (state, action) => {
        const { arg: { id:pollId } } = action.meta;
        state.polls = state.polls ? state.polls.filter((poll) => poll.id !== pollId) : state.polls;
        state.total = state.total - 1;
      })
  }
});

export const pollActions = pollSlice.actions;

export default pollSlice.reducer;
