import { FC, Fragment } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
// Hooks
import useDialog from 'hooks/useDialog';
import { useAppSelector } from 'hooks/redux';
import useMenu from 'hooks/useMenu';
// Selectors
import { selectGuestModuleEnabled } from 'store/accounts/accountsSelectors';
// Mui
import {
  Box, IconButton, ListItemIcon, Button
} from '@mui/material';
import {
  PersonOutline as PersonOutlineIcon,
  LoginOutlined as LoginOutlinedIcon,
  PersonAddAltOutlined as PersonAddAltOutlinedIcon,
  HouseOutlined as HouseOutlinedIcon,
} from '@mui/icons-material';
// Components
import SignInForm from 'components/SignIn.form';
import SignUpForm from 'components/SignUp.form';
// Utilities
import { getContent } from 'utilities/Utilities';

const PublicMenu:FC = () => {
  const { pathname } = useLocation();

  const guestModuleEnabled = useAppSelector(selectGuestModuleEnabled);

  const { Menu, MenuItem, openMenu } = useMenu();

  const { Dialog:SignInDialog, openDialog:openSignInDialog, closeDialog: closeSignInDialog } = useDialog();
  const { Dialog:SignUpDialog, openDialog:openSignUpDialog, closeDialog: closeSignUpDialog } = useDialog();

  const openSignIn = () => {
    closeSignUpDialog();
    openSignInDialog();
  }

  const openSignUp = () => {
    closeSignInDialog();
    openSignUpDialog();
  }

  return (
    <Fragment>
      <SignInDialog maxWidth="sm">
        <SignInForm onClose={closeSignInDialog} openSignUp={openSignUp} />
      </SignInDialog>
      <SignUpDialog maxWidth="sm">
        <SignUpForm onClose={closeSignUpDialog} openSignIn={openSignIn} />
      </SignUpDialog>

      <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 1 }}>
        {pathname !== '/list-your-property' && (
          <Fragment>
            <Button
              variant="outlined"
              component={NavLink}
              to="list-your-property"
              size="small"
            >
              {getContent('common').listYourTimeshare}
            </Button>
            {guestModuleEnabled && (
              <Button
                size="small"
                variant="contained"
                onClick={openSignUpDialog}
              >
                Sign up
              </Button>
            )}
          </Fragment>
        )}
        <Button
          size="small"
          variant="contained"
          onClick={openSignInDialog}
        >
          Sign in
        </Button>
      </Box>

      <IconButton
        color="secondary"
        sx={{ display: { sm: 'none' } }}
        onClick={openMenu}
      >
        <PersonOutlineIcon fontSize="small" />
      </IconButton>
      <Menu
        PaperProps={{
          elevation: 0,
          sx: {
            display: { sm: 'none' },
            minWidth: '160px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={Link} to="list-your-property">
          <ListItemIcon>
            <HouseOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {getContent('common').listYourTimeshare}
        </MenuItem>
        <MenuItem onClick={openSignUpDialog}>
          <ListItemIcon>
            <PersonAddAltOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Sign Up
        </MenuItem>
        <MenuItem onClick={openSignInDialog}>
          <ListItemIcon>
            <LoginOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Sign In
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default PublicMenu;
