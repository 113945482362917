import React from 'react';
// Mui
import { Box, CircularProgress, Grid, SxProps, Theme, Tooltip, Typography } from '@mui/material';

export const Loader:React.FC = () => {
  return (
    <Box textAlign="center"><CircularProgress /></Box>
  )
}

type NoDataTexProps = {
  text?: string;
}

export const NoDataText:React.FC<NoDataTexProps> = ({
  text = 'No item(s)'
}) => {
  return (
    <Box textAlign="center">
      <Typography variant="body2">{text}</Typography>
    </Box>
  )
}

export const NoData:React.FC = () => {
  return (
    <Box sx={{
      width: '100%', padding: '48px 32px 72px', backgroundColor: '#e8eced', borderRadius: '8px', mt: 2,
      display: 'flex', flexDirection: 'column', justifyContent: 'cetner', alignItems: 'center', gap: 2
    }}>
      <img src="/img/icons/noData.svg" alt="" />
      <Typography sx={{ color: 'rgba(0, 0, 0, 0.38)', fontWeight: 500, textAlign: 'center' }}>No data to display</Typography>
    </Box>
  );
}

export const NoSearchData:React.FC = () => {
  return (
    <Box sx={{
      width: '100%', padding: '48px 32px 72px', backgroundColor: '#e8eced', borderRadius: '8px',
      display: 'flex', flexDirection: 'column', justifyContent: 'cetner', alignItems: 'center', gap: 2
    }}>
      <img src="/img/icons/noSearchData.svg" alt="" />
      <Typography sx={{ color: 'rgba(0, 0, 0, 0.38)', fontWeight: 500, textAlign: 'center' }}>No data matching your search or filter criteria</Typography>
    </Box>
  );
}

export const NoNotifications:React.FC = () => {
  return (
    <Box sx={{
      width: '100%', height: '100%', padding: '48px 32px 72px', backgroundColor: '#e8eced', borderRadius: '8px', mt: 2,
      display: 'flex', flexDirection: 'column', justifyContent: 'cetner', alignItems: 'center', gap: 2
    }}>
      <img src="/img/icons/noNotifications.svg" alt="" />
      <Typography sx={{ color: 'rgba(0, 0, 0, 0.38)', fontWeight: 500, textAlign: 'center' }}>No new notifications</Typography>
    </Box>
  );
}

export const NoChats:React.FC = () => {
  return (
    <Box sx={{
      width: '100%', height: '100%', padding: '48px 32px 72px', backgroundColor: '#e8eced', borderRadius: '8px', mt: 2,
      display: 'flex', flexDirection: 'column', justifyContent: 'cetner', alignItems: 'center', gap: 2
    }}>
      <img src="/img/chats.png" alt="" style={{ height: '100px', maxWidth: '100%', objectFit: 'contain' }} />
      <Typography sx={{ color: 'rgba(0, 0, 0, 0.38)', fontWeight: 500, textAlign: 'center', pt: 2 }}>You don’t have any active chats</Typography>
    </Box>
  );
}

type InfoBlockProps = {
  disablePadding?: boolean;
  title?: string;
  text: React.ReactNode;
  textSx?: SxProps<Theme>;
}

export const InfoBlock:React.FC<InfoBlockProps> = ({ disablePadding = false, title, text, textSx = {} }) => (
  <Box sx={{ pb: disablePadding ? 0 : 2 }}>
    {title ? (
      <Typography sx={{
        fontSize: '12px',
        lineHeight: '166%',
        letterSpacing: '0.4px',
        color: 'rgba(0, 0, 0, 0.6)',
      }}>
        {title}
      </Typography>
    ) : null}
    <Typography sx={{
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.17px',
      color: 'rgba(0, 0, 0, 0.87)',
      ...textSx
    }}>
      {text}
    </Typography>
  </Box>
);

type GridBlockProps =  {
  title?: string;
  titleReverse?: boolean;
  text: React.ReactNode;
  secondText?: React.ReactNode;
  boldText?: boolean;
  isLink?: boolean;
  rowEnabled?: boolean;
  sx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
  tooltip?: React.ReactNode;
  onClick?: () => void;
  // ToDo: grid sizes
  xs?: number; sm?: number; md?: number;
}

export const GridBlock:React.FC<GridBlockProps> = ({
  title, titleReverse = false, text, secondText, boldText = false, isLink = false, rowEnabled = true,
  sx = {}, titleSx = {}, textSx = {}, tooltip, onClick, ...nextProps
}) => (
  <Grid item {...nextProps}
    sx={{
      display: 'flex', alignSelf: 'center', justifyContent: 'space-between',
      flexDirection: { xs: rowEnabled ? 'row' : 'column', sm: 'column' },
      alignItems: { xs: rowEnabled ? 'center' : 'flex-start', sm:'flex-start' },
      gap: { xs: rowEnabled ? 2 : 0, sm: 0 },
      ...sx
    }}
  >
    {title ? (
      <Typography sx={{
        fontSize: '12px',
        lineHeight: '166%',
        letterSpacing: '0.4px',
        color: 'rgba(0, 0, 0, 0.6)',
        order: titleReverse ? 1 : 0,
        ...titleSx
      }}>
        {title}
      </Typography>
    ) : null}
    <Tooltip title={tooltip ? tooltip : ''}>
      <div style={{ maxWidth: '100%' }}>
        <Typography component={isLink ? 'a' : 'p'} onClick={onClick} sx={{
          fontSize: boldText ? '16px' : '14px',
          fontWeight: boldText ? 500 : 400,
          lineHeight: boldText ? '150%' : '143%',
          letterSpacing: '0.17px',
          color: isLink ? '#1976D2' : 'rgba(0, 0, 0, 0.87)',
          cursor: isLink ? 'pointer' : 'default',
          whiteSpace: 'pre-line',
          wordWrap: 'break-word',
          ...textSx,
        }}>
          {text}
        </Typography>
        {secondText ? (
          <Typography sx={{
            fontSize: '14px',
            lineHeight: '143%',
            letterSpacing: '0.17px',
            color: isLink ? '#1976D2' : 'rgba(0, 0, 0, 0.87)',
            cursor: isLink ? 'pointer' : 'default',
            whiteSpace: 'pre-line',
            wordWrap: 'break-word',
            ...textSx,
          }}>
            {secondText}
          </Typography>
        ) : null}
      </div>
    </Tooltip>
  </Grid>
);
