import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';

export default class PurchaseInquiriesAsync {
  private static _url:string = '/client/purchaseInquiries';
  private static _http:HttpClient = new HttpClient();

  // Fetch purchase inquiries
  public static fetchPurchaseInquiries = createAsyncThunk('purchaseInquiries/fetchPurchaseInquiries', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(this._url, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Create purchase inquiry
  public static createPurchaseInquiry = createAsyncThunk('purchaseInquiries/createPurchaseInquiry', async (data:any, thunkApi) => {
    const response:Response = await this._http.post(this._url, data);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response;
  });

  // Create purchase inquiry anonymous
  public static createPurchaseInquiryAnonymous = createAsyncThunk('purchaseInquiries/createPurchaseInquiryAnonymous', async (data:any, thunkApi) => {
    const response:Response = await this._http.post(`${this._url}/anonymous`, data);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response;
  });
}
