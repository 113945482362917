import React, { useState, useEffect } from "react";
// Mui
import { Box, IconButton, Paper } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./Swiper.css";
import { FreeMode, Navigation, Thumbs } from "swiper";

type Props = {
  onClose: () => void,
  images: any[],
}

const PropertyImages:React.FC<Props> = ({ onClose, images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [countCard, setCountCard] = useState<number>(window.innerWidth >= 1900 ? 6 : window.innerWidth >= 720 ? 4 : 2);

  const handlerScreenWidth = () => {
    setCountCard(window.innerWidth >= 1900 ? 6 : window.innerWidth >= 720 ? 4 : 2);
  }

  useEffect(() => {
    window.addEventListener('resize', handlerScreenWidth);

    return () => {
      window.removeEventListener('resize', handlerScreenWidth);
    }
  }, []);

  return (
    <Paper elevation={6} variant="elevation" sx={{ padding: { xs: '64px 16px 16px', md: '64px' } }}>
      <IconButton onClick={onClose} sx={{ position: 'absolute', top: '8px', right: '8px' }}>
        <CloseOutlinedIcon />
      </IconButton>
      <Box sx={{ userSelect: 'none' }}>
        <Box sx={{ height: '430px' }}>
          <Swiper
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {images.map(image => (
              <SwiperSlide key={image.url}>
                <img src={image.url} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Box sx={{ height: '100px' }}>
          <Swiper
            onSwiper={(swiper) => setThumbsSwiper(swiper)}
            spaceBetween={10}
            slidesPerView={countCard}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >
            {images.map(image => (
              <SwiperSlide key={image.url}>
                <img src={image.url} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Paper>
  )
}

export default PropertyImages;
