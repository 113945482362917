import { createAsyncThunk } from "@reduxjs/toolkit";
// Selectors
import { selectAssets, selectFiles } from "./assetsSelectors";
// Models
import { RootState } from "store";
import IAsset from "models/Asset";
import { IFile } from "./assetsSlice";
// Types
import AssetContractType from "types/AssetContractType";
import AssetType from "types/AssetType";
import ImageAssetType from "types/ImageAssetType";
// Utilities
import HttpClient from 'utilities/HttpClient';

export default class AssetsAsync {
  private static _url:string = '/client/assets';
  private static _http:HttpClient = new HttpClient();

  // Create assets
  public static createAssets = createAsyncThunk(
  'assets/createAssets',
  async (assetData:{
    type:AssetType,
    files:IFile[],
    publiclyAccessible: boolean,
    imageAssetType: ImageAssetType
  }, thunkApi) => {

    const { type, files, publiclyAccessible, imageAssetType } = assetData;

    const formData = new FormData();
    Array.from(files).forEach((file:IFile) => {
      formData.append('files', file.file, file.file.name)
    });

    const queryParams = new URLSearchParams();
    queryParams.append('publiclyAccessible', `${publiclyAccessible}`);
    if (type === AssetType.Images) queryParams.append('imageAssetType', imageAssetType);

    const response:Response = await this._http.post(`${this._url}?${queryParams}`, formData);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Get Asset Url
  public static getAssetUrl = createAsyncThunk('assets/getAssetUrl', async (assetId:number, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/${assetId}/url`, assetId);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    const url = await response.json() as URL;
    window.open(url, '_blank');
  });

  // Validate assets
  public static validateAssets = createAsyncThunk(
  'assets/validateAssets',
  async (assetData: {
    type?:AssetType,
    contractType?:AssetContractType | undefined,
    publiclyAccessible?: boolean,
    imageAssetType?: ImageAssetType
  }, thunkApi) => {
    const {
      type = AssetType.Images,
      contractType = undefined,
      publiclyAccessible = true,
      imageAssetType = ImageAssetType.Small,
    } = assetData;

    const state = thunkApi.getState() as RootState;
    
    const files:IFile[] = selectFiles(state, { type, contractType });
    const assets:IAsset[] = selectAssets(state, { type, contractType });

    const assetIdsToDelete:number[] = state.assets.assetsIdsToDelete;

    let result:IAsset[] = [...assets];
    
    if ( files && files.length ){
      const { payload }: any = await thunkApi.dispatch(this.createAssets({ type, files, publiclyAccessible, imageAssetType }))
      result = [...result, ...payload];
    }
    if ( assetIdsToDelete.length ){
      result = result.filter((asset:any) => !assetIdsToDelete.includes(asset.id) && !assetIdsToDelete.includes(asset.asset?.id));
    }

    return result;
  });
}
