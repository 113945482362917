import React, { useState } from "react";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import useMenu from "hooks/useMenu";
// Async
import NotificationsAsync from "store/notifications/notificationsAsync";
// Actions
import { NotificationsActions } from "store/notifications/notificationsSlice";
// Selectors
import { selectAllNotifications, selectSelectedNotificationsIds, selectUnreadNotificationsIds } from "store/notifications/notificationsSelectors";
// mui
import { makeStyles } from '@mui/styles';
import { Box, Button, Divider, LinearProgress, Typography } from "@mui/material";
import {
  DeleteOutlined as DeleteOutlinedIcon,
  NotificationsActiveOutlined as NotificationsActiveOutlinedIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
// components
import Title from "components/Title";
import { NoNotifications } from "components/Utilities";
import NotificationsList from "./NotificationsList";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";

const NotificationsPage:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const notifications = useAppSelector(selectAllNotifications);
  const selectedNotificationsIds = useAppSelector(selectSelectedNotificationsIds);
  const unreadNotificationsIds = useAppSelector(selectUnreadNotificationsIds) || [];

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const handleOpenRemoveModal = () =>  setOpenDeleteModal(prev => !prev);

  const [openDeleteAllModal, setOpenDeleteAllModal] = useState<boolean>(false);
  const handleOpenRemoveAllModal = () => setOpenDeleteAllModal(prev => !prev);

  const markAsReadAll = () => {
    dispatch(NotificationsAsync.markAsReadNotifications([]))
      .unwrap()
      .then(() => dispatch(NotificationsActions.setInitialField('selectedNotificationsIds')))
  }

  const markAsRead = () => {
    dispatch(NotificationsAsync.markAsReadNotifications(selectedNotificationsIds))
      .unwrap()
      .then(() => {
        dispatch(NotificationsActions.setInitialField('selectedNotificationsIds'));
      })
  }

  const removeAll = () => {
    dispatch(NotificationsAsync.deleteNotifications([]))
      .unwrap()
      .then(() => {
        dispatch(NotificationsActions.setInitialField('selectedNotificationsIds'));
        handleOpenRemoveAllModal();
      })
  }

  const remove = () => {
    dispatch(NotificationsAsync.deleteNotifications(selectedNotificationsIds))
      .unwrap()
      .then(() => {
        dispatch(NotificationsActions.setInitialField('selectedNotificationsIds'));
        handleOpenRemoveModal();
      })
  }

  const { Menu, MenuItem, openMenu } = useMenu();

  return (
    <React.Fragment>
      <ConfirmDeleteModal open={openDeleteModal} onClose={handleOpenRemoveModal} handleRemove={remove} title={`${selectedNotificationsIds.length} notification(s)`} />
      <ConfirmDeleteModal open={openDeleteAllModal} onClose={handleOpenRemoveAllModal} handleRemove={removeAll} title="all notifications" />

      <Box className={classes.page}>
        <div className="container">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
            <Title>Notifications</Title>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap', width: '100%', '@media (min-width: 900px)': { width: 'auto' } }}>
              {!!selectedNotificationsIds.length && (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', width: '100%', '@media (min-width: 700px)': { width: 'auto' } }}>
                  <Typography>{`${selectedNotificationsIds.length} selected`}</Typography>
                  <Button
                    variant="outlined"
                    onClick={openMenu}
                  >
                    Actions
                    <ArrowDropDownIcon />
                  </Button>
                  <Menu>
                    <MenuItem onClick={markAsRead} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <NotificationsActiveOutlinedIcon />
                      Mark as read
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleOpenRemoveModal} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <DeleteOutlinedIcon />
                      Delete
                    </MenuItem>
                  </Menu>
                </Box>
              )}
              {!!unreadNotificationsIds?.length && (
                <Button
                  variant="outlined"
                  onClick={markAsReadAll}
                  className={classes.button}
                >
                  <NotificationsActiveOutlinedIcon />
                  <Typography>Mark all as read</Typography>
                </Button>
              )}
              {notifications && !!notifications.length && (
                <Button
                  variant="outlined"
                  onClick={handleOpenRemoveAllModal}
                  className={classes.button}
                  >
                  <DeleteOutlinedIcon />
                  <Typography>Delete all notifications</Typography>
                </Button>
              )}
            </Box>
          </Box>
          <Box>
            {notifications === null ? <LinearProgress /> : !!notifications?.length ? <NotificationsList /> :  <NoNotifications />}
          </Box>
        </div>
      </Box>
    </React.Fragment>
  )
}

export default NotificationsPage;

const useStyles = makeStyles({
  page: {
    minHeight: 'calc(100vh - 266px)',
    backgroundColor: 'rgba(245, 246, 247, 1)',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
      minHeight: 'calc(100vh - 236px)',
    },
  },
  button: {
    flexGrow: 1,
    display: 'flex',
    gap: '8px',
    '@media (min-width: 900px)': {
      flexGrow: 0,
      '& p': {
        display: 'none',
      },
      '&:hover p': {
        display: 'block',
      },
    }
  },
});
