import React from 'react';
// mui
import { Box, Skeleton } from '@mui/material';

const DestinationTypeCardSkeleton:React.FC = () => {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
      <Skeleton variant="rectangular" width="100%" height="178px" sx={{ borderRadius: '8px' }} />
      <Skeleton variant="rectangular" width="100%" height="50px" sx={{ borderRadius: '8px' }} />
      <Skeleton variant="rectangular" width="100%" height="50px" sx={{ borderRadius: '8px' }} />
      <Skeleton variant="rectangular" width="100%" height="50px" sx={{ borderRadius: '8px' }} />
    </Box>
  )
}

export default DestinationTypeCardSkeleton;
