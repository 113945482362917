enum TimeshareSeasons {
  high = 'high',
  medium = 'medium',
  low = 'low',
  platinum = 'platinum',
  gold = 'gold',
  silver = 'silver',
  other = 'other',
}

export default TimeshareSeasons;
