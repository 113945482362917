import { createAsyncThunk } from "@reduxjs/toolkit";
import StorageService from "services/StorageService";
import config from 'config';
// Async
import AccountsAsync from "store/accounts/accountsAsync";
import AssetsAsync from "store/assets/assetsAsync";
// Actions
import { AuthActions } from "store/auth/authSlice";
// Selectors
import { selectFiles } from "store/assets/assetsSelectors";
// Models
import { RootState } from "store";
import IUser from "models/User";
import { IFile } from "store/assets/assetsSlice";
// Types
import UserRoles from "types/UserRoles";
import AssetType from "types/AssetType";
import ImageAssetType from "types/ImageAssetType";
// Utilities
import HttpClient from 'utilities/HttpClient';
import { getContent } from "utilities/Utilities";

const { accountId } = getContent('settings');

export default class UsersAsync {
  private static _url:string = '/client/users';
  private static _http:HttpClient = new HttpClient();

  // Switch role
  public static switchRole = createAsyncThunk('users/switchRole', async (userRole:UserRoles, thunkApi) => {
    const response:Response = await this._http.post(`${this._url}/me/switchRole?role=${userRole}`);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    const credential = await response.json();
    StorageService.setCredential(credential);
    thunkApi.dispatch(UsersAsync.fetchMe({}));
  });

  // Fetch me
  public static fetchMe = createAsyncThunk('users/fetchMe', async (_:any, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/me`);
    if ( !response.ok ) {
      StorageService.removeCredential();
      thunkApi.dispatch(AuthActions.setAuthenticated(false));
      return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any)
    }
    const currentUser: IUser = await response.json();
    thunkApi.dispatch(AccountsAsync.fetchAccountsSetting({}));
    return currentUser;
  });

  // Update user
  public static updateUser = createAsyncThunk('users/updateUser', async (data:any, thunkApi) => {
    const nextData = {...data};
    // Update assets
    const state = thunkApi.getState() as RootState;
    const files:IFile[] = selectFiles(state, { type: AssetType.Images, contractType: undefined });

    if (!!files.length) {
      const { payload }: any = await thunkApi.dispatch(AssetsAsync.createAssets({ type: AssetType.Images, files, imageAssetType: ImageAssetType.Medium, publiclyAccessible: true }));
      nextData['imageId'] = payload[0].id;
    }
  
    const response:Response = await this._http.put(`${this._url}/me`, nextData);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    const user: IUser = await response.json();
    return user;
  });

  // Registration request
  public static registrationRequest = createAsyncThunk('users/registrationRequests', async (data:any, thunkApi) => {
    const nextData = { accountId, ...data };
    const response:Response = await this._http.post(`${this._url}/registrationRequests`, nextData);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response;
  });

  // Register user
  public static registerUser = createAsyncThunk('users/register', async (data:any, thunkApi) => {
    const response:Response = await this._http.post(`${this._url}`, data);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    const credential = await response.json();
    StorageService.setCredential(credential);
    thunkApi.dispatch(UsersAsync.fetchMe({}));
  });

  // password recovery request
  public static passwordRecoveryRequest = createAsyncThunk('users/passwordRecoveryRequest', async (data:any, thunkApi) => {
    const nextData = { accountId, ...data };
    const response:Response = await this._http.post(`${this._url}/passwordRecoveryRequests`, nextData);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response;
  });

  // Recover password
  public static recoverPassword = createAsyncThunk('users/recoverPassword', async (data:any, thunkApi) => {
    const response:Response = await this._http.post(`${this._url}/passwords`, data);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response;
  });

  // Unsubscribe
  public static unsubscribe = createAsyncThunk('users/unsubscribe', async (token:string, thunkApi) => {
    const response: any = await fetch(`${config.apiUrl}${this._url}/emailSubscriptions/unsubscribe`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` }
    })
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
  });
}
