interface IConfig {
  [key:string]: {
    production: boolean;
    apiUrl: string;
    publicApiKey: string;
  }
}

const config:IConfig = {
  'development': {
    production: false,
    apiUrl: 'https://api-qa.dashweek.com/api',
    publicApiKey: 'pkapi_cert_5biYwOYd6BUbWpkGa6',
  },
  'qa': {
    production: false,
    apiUrl: 'https://api-qa.dashweek.com/api',
    publicApiKey: 'pkapi_cert_5biYwOYd6BUbWpkGa6',
    
  },
  'production': {
    production: true,
    apiUrl: 'https://api.dashweek.com/api',
    publicApiKey: 'pkapi_cert_5biYwOYd6BUbWpkGa6',
  }
}

export const stage:string = process.env.REACT_APP_STAGE || 'development';

export default config[stage];