import { FC, Fragment } from 'react';
// Hooks
import useDialog from 'hooks/useDialog';
// Models
import { ISubscriptionPackage } from 'models/Subscriptions';
// MUI
import { makeStyles } from '@mui/styles';
import {
  Typography, Box, Button
} from '@mui/material';
// Components
import ListYourPropertyForm from './ListYourPropertyForm';
// Utilities
import { formatCash } from 'utilities/Utilities';

type Props = {
  subscriptionPackage: ISubscriptionPackage;
}

const SubscriptionPackageCard:FC<Props> = ({ subscriptionPackage }) => {
  const classes = useStyles();

  const { Dialog, openDialog, closeDialog } = useDialog();

  return (
    <Fragment>
      <Dialog>
        <ListYourPropertyForm subscriptionPackageId={subscriptionPackage.id} onClose={closeDialog} />
      </Dialog>

      <Box className={classes.card}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500, color: '#fff' }}>{subscriptionPackage.name}</Typography>
        <Typography sx={{ fontSize: '13px', color: '#fff' }}>{`Trial period: ${subscriptionPackage.trialPeriodDaysNumber} days`}</Typography>
        <ul style={{ paddingTop: '16px', color: '#fff' }}>
          {subscriptionPackage.benefits?.map(benefit => (
            <li key={benefit.id}>{benefit.name}</li>
          ))}
        </ul>
        <Box flexGrow="1" />
        <Button
          sx={{ mt: 2, mb: 2 }}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={openDialog}
        >
          Get Started
        </Button>
        <Typography sx={{ color: '#fff' }}>{`${formatCash(subscriptionPackage.price)} / month`}</Typography>
      </Box>
    </Fragment>
  )
}

export default SubscriptionPackageCard;

const useStyles = makeStyles({
  card: {
    position: 'relative',
    flexBasis: '100%',
    padding: '40px 20px',
    border: '1px solid #eee',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '@media (min-width: 600px)': {
      flexBasis: 'calc(50% - 20px)',
    },
    '@media (min-width: 1240px)': {
      flexBasis: 'calc(100% / 3 - 27px)',
    },
  },
})