import React, { Fragment } from 'react';
import dayjs from 'dayjs';
// hooks
import { useAppDispatch } from 'hooks/redux';
import useMenu from 'hooks/useMenu';
import useToggle from 'hooks/useToggle';
// Async
import SubscriptionsAsync from 'store/subscriptions/subscriptionsAsync';
// Models
import { ISubscriptionBenefitRequest } from 'models/Subscriptions';
// Types
import BenefitRequestStatus from 'types/BenefitRequestStatus';
// MUI
import { makeStyles } from '@mui/styles';
import {
  Box, Card, Chip, Collapse, Divider, Drawer, Grid, IconButton, Tooltip, Typography
} from '@mui/material';
import {
  DoneAll, Done, Close, AccessTimeOutlined, ReportOutlined,
  MoreVert, ExpandLess, ExpandMore
} from '@mui/icons-material';
// Components
import Collapsable from 'components/Collapsable';
import BenefitRequestsForm from 'components/BenefitRequests.form';
import ConfirmCancelBenefitRequest from './ConfirmCancelBenefitRequest';
import { GridBlock } from 'components/Utilities';
// Utilities
import { formatDate, formatDateTime } from 'utilities/DateTimeFormatter';

type Props = {
  benefitRequest: ISubscriptionBenefitRequest,
};

const BenefitRequestCard:React.FC<Props> = ({ benefitRequest }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open, toggle } = useToggle();
  const { open:openDrawer, toggle:toggleDrawer } = useToggle();
  const { open:extended, toggle:toggleExtended } = useToggle(true);


  const cancelBenefitRequest = () => {
    dispatch(SubscriptionsAsync.cancelBenefitRequest({ benefitRequestId: benefitRequest.id, benefitId: benefitRequest.benefit.id })).unwrap()
      .then(() => toggle())
  }

  const isPosibleToEdit = benefitRequest.status === BenefitRequestStatus.Submitted
    || (benefitRequest.status === BenefitRequestStatus.Scheduled && dayjs(benefitRequest.scheduledDate).add(benefitRequest.benefit.duration || 0, 'minute').isAfter(dayjs()));
  
  return (
    <React.Fragment>
      <ConfirmCancelBenefitRequest
        open={open} onClose={toggle}
        cancel={cancelBenefitRequest}
        name={benefitRequest.benefit.name}
      />
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        sx={{ zIndex: 1300 }}
        PaperProps={{
          sx: { maxWidth: '100%', width: '420px' }
        }}
      >
        <BenefitRequestsForm onClose={toggleDrawer} benefitRequest={benefitRequest} />
      </Drawer>

      <Card sx={{ p: 2, borderRadius: 0, backgroundColor: extended ? 'rgba(0, 0, 0, 0.04)' : '#fff', borderBottom: '1px solid #eee', width: '100%' }}>
        <Collapsable onClick={toggleExtended}>
          <Grid container spacing={2} sx={{ cursor: 'pointer' }}>
            <Grid item xs={10} md={3} sx={{ alignSelf: 'center', order: -1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                {extended ? <ExpandLess /> : <ExpandMore />}
                <Box>
                  <Typography className={classes.text}>
                    {benefitRequest.benefit.name}
                  </Typography>
                  <Typography className={classes.title}>
                    {formatDate(benefitRequest.submissionDate)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <GridBlock
              xs={12} sm={6} md={3}
              title="Scheduled date"
              text={formatDateTime(benefitRequest.scheduledDate)}
            />
            <GridBlock
              xs={12} sm={6} md={3}
              title="Completion date"
              text={formatDateTime(benefitRequest.completionDate)}
            />
            <GridBlock
              xs={12} sm={6} md={2}
              title="Status"
              text={(
                <Fragment>
                  {benefitRequest.status === BenefitRequestStatus.Scheduled && <Chip color="info" size="small" label="Scheduled" icon={<AccessTimeOutlined />} />}
                  {benefitRequest.status === BenefitRequestStatus.Submitted && <Chip color="secondary" size="small" label="Submitted" icon={<Done />} />}
                  {benefitRequest.status === BenefitRequestStatus.Completed && <Chip color="success" size="small" label="Completed" icon={<DoneAll />} />}
                  {benefitRequest.status === BenefitRequestStatus.Cancelled && <Chip color="warning" size="small" label="Cancelled" icon={<Close />} />}
                  {benefitRequest.status === BenefitRequestStatus.Rejected && <Chip color="error" size="small" label="Rejected" icon={<ReportOutlined />} />}
                </Fragment>
              )}
            />
            {isPosibleToEdit && (
              <Grid item xs={2} md={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignSelf: 'center', order: { xs: -1, md: 0 } }}>
                <Tooltip title="Actions">
                  <IconButton onClick={openMenu}>
                    <MoreVert />
                  </IconButton>
                </Tooltip>
                <Menu>
                  <MenuItem onClick={toggleDrawer}>Edit</MenuItem>
                  <MenuItem onClick={toggle}>Cancel</MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
        </Collapsable>
        <Collapse in={extended}>
          <Divider sx={{ p: 1 }} />
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <GridBlock
              xs={12} sm={4}
              title="Notes"
              text={benefitRequest.ownerNotes || '-'}
              rowEnabled={false}
            />
            <GridBlock
              xs={12} sm={4}
              title="Agent notes"
              text={benefitRequest.agentNotes || '-'}
              rowEnabled={false}
            />
            <GridBlock
              xs={12} sm={4}
              title="Provider notes"
              text={benefitRequest.providerNotes || '-'}
              rowEnabled={false}
            />
          </Grid>
        </Collapse>
      </Card>
    </React.Fragment>
  );
}

export default BenefitRequestCard;

const useStyles = makeStyles({
  title: {
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});
