import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import LandingPagesAsync from "./landingPagesAsync";
// Models
import ILandingPage from "models/LandingPage";

interface IState {
  landingPage: ILandingPage | null;
}

const initialState:IState = {
  landingPage: null,
};

const landingPagesSlice = createSlice({
  name: 'landingPages',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch locations
      .addCase(LandingPagesAsync.fetchLandingPage.fulfilled, (state, action:PayloadAction<ILandingPage>) => {
        state.landingPage = action.payload;
      })
  }
});

export const landingPagesActions = landingPagesSlice.actions;

export default landingPagesSlice.reducer;
