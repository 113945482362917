import { FC, Fragment, MouseEventHandler } from "react";
import { useLocation } from "react-router-dom";
// hooks
import useDialog from "hooks/useDialog";
import { useAppDispatch } from "hooks/redux";
// Actions
import { TimesharesActions } from "store/timeshares/timesharesSlice";
// Models
import ITimeshare from "models/Timeshare";
// Types
import TimeshareTypes from "types/TimeshareTypes";
// components
import ReservationRequestForm from "components/ReservationRequest.form";
import MarqueeWrapper from "./MarqueeWrapper";
// mui
import { makeStyles } from "@mui/styles";
import {
  Box, Button, Card, CardContent,
  Chip, Tooltip, Typography
} from "@mui/material";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
// utilities
import { formatStartEndDate } from "utilities/DateTimeFormatter";
import { formatCash, openWindow } from "utilities/Utilities";

type Props = {
  timeshare: ITimeshare,
}

const TimeshareCard:FC<Props> = ({ timeshare }) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const imgUrl = timeshare.property.images ? timeshare.property.images[0].url : timeshare.images ? timeshare.images[0].url : '/img/noPropertyFound.png';

  const goToDetails = (link: string) => {
    if (pathname === link) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      openWindow(link);
    }
  }

  const openRequest = () => {
    dispatch(TimesharesActions.setTimeshareDetails(timeshare));
    openDialog();
  }

  const { Dialog, openDialog, closeDialog } = useDialog();

  const isRentTimeshare = timeshare.type === TimeshareTypes.Rent;
  const hasSalePrice = timeshare.displaySalePrice;
  const timeshareDetailsLink = `/timeshares/id/${timeshare.id}`;

  const handleClick:MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    hasSalePrice ? openRequest() : goToDetails(timeshareDetailsLink);
  }

  return (
    <Fragment>
      <Dialog maxWidth="md">
        <ReservationRequestForm onClose={closeDialog} isRent={isRentTimeshare} />
      </Dialog>

      <Card className={classes.card}>
        <div className={classes.gradient} />
        {isRentTimeshare ? (
          <Chip
            color={timeshare.start ? 'default' : 'success'} size="small"
            icon={<EventAvailableOutlinedIcon />}
            label={timeshare.start && timeshare.end ? formatStartEndDate(timeshare.start, timeshare.end) : 'Flexible dates'}
            onClick={() => goToDetails(`/timeshares/id/${timeshare.id}`)}
            sx={{
              position: 'absolute', top: '16px', left: '16px',
              backgroundColor: timeshare.start ? '#fff' : "auto",
              zIndex: 2, cursor: 'pointer'
            }}
          />
        ) : null}
        <Box sx={{ position: 'relative', cursor: 'pointer' }} onClick={() => goToDetails(timeshareDetailsLink)}>
          <img src={imgUrl} style={{ width: '100%', height: '240px', objectFit: 'cover' }} alt={timeshare.property.name} />
          {timeshare.specialOffer && (
            <MarqueeWrapper title={timeshare.specialOfferLabel || 'Special offer'} />
          )}
        </Box>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Tooltip title={timeshare?.property.name} arrow placement="top">
              <Typography className={classes.title} onClick={() => goToDetails(`/property/${timeshare.property.nameSlug}`)}>
                {timeshare?.property.name}
              </Typography>
            </Tooltip>
            <Box sx={{ display: 'flex' }}>
              <Tooltip title={timeshare?.property.region?.name} arrow>
                <Typography className={`${classes.text} ${classes.subtitle}`} onClick={() => goToDetails(`/timeshares/${timeshare.property.country?.nameSlug}/${timeshare.property.region?.nameSlug}`)}>
                  {`${timeshare?.property.region?.name}`}
                </Typography>
              </Tooltip>
              <Typography className={classes.text} sx={{ pr: 0.5 }}>
                {', '}
              </Typography>
              <Tooltip title={timeshare?.property.country?.name} arrow>
                <Typography className={`${classes.text} ${classes.subtitle}`} onClick={() => goToDetails(`/timeshares/${timeshare.property.country?.nameSlug}`)}>
                  {timeshare?.property.country?.name}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
          <Button
            sx={{ p: 1 }}
            variant="contained"
            onClick={handleClick}
            startIcon={<RequestQuoteOutlinedIcon />}
          >
            {hasSalePrice ? `${isRentTimeshare ? 'Reserve for' : 'Offered at'} ${formatCash(timeshare.salePrice)}` : 'Listing details'}
          </Button>
        </CardContent>
      </Card>
    </Fragment>
  )
}

export default TimeshareCard;

const useStyles = makeStyles({
  card: {
    width: '100%',
    position: 'relative',
    flexGrow: 1,
  },
  title: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '-0.35px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': {
      color: '#53B8E0'
    }
  },
  text: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '-0.43px',
    color: 'rgba(0, 0, 0, 0.6)',
    transition: 'color 0.3s',
    cursor: 'pointer',
    '&:hover': {
      color: '#53B8E0'
    }
  },
  subtitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '50%',
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: '120px',
    left: 0,
    top: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)',
  },
});
