import { FC, Fragment, useRef, useState, useLayoutEffect, useEffect } from 'react';
import dayjs from "dayjs";
// Models
import { IChatMessage } from 'models/Chat';
// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
// Async
import ChatsAsync from 'store/chats/chatsAsync';
// Selectors
import { selectCurrentUser } from "store/users/usersSelectors";
import { selectChatsMessages, selectChatsMessagesObj, selectChatsMessagesTotal } from 'store/chats/chatsSelectors';
// Mui
import { makeStyles } from "@mui/styles";
import { Box, Chip, Divider, IconButton, Typography, Avatar, Tooltip } from "@mui/material";
import {
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';
// Components
import ElementObserver from 'components/ElementObserver';
// Utilities
import { formatDateForMessages } from "utilities/DateTimeFormatter";
import { getContent } from 'utilities/Utilities';

const size = 50;

let page = 0;
let observer:any;

type Props = {
  scrollToEnd: () => void;
}

const MessagesList:FC<Props> = ({ scrollToEnd }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  
  const currentUser = useAppSelector(selectCurrentUser);
  const messages = useAppSelector(selectChatsMessages);
  const messagesObj = useAppSelector(selectChatsMessagesObj);
  const total = useAppSelector(selectChatsMessagesTotal);
  const totalPages = Math.ceil(total / size) - 1;

  const messageRef = useRef<HTMLDivElement | null>(null);

  const [ visible, setVisible ] = useState(false);

  useLayoutEffect(() => {
    scrollToEnd();
    if (messages && messages.length < size) return;
    observer = new IntersectionObserver(([entry]:any) => {
      if ( page === totalPages ) return;
      if ( !entry.isIntersecting ) return;

      page++;

      dispatch(ChatsAsync.refetchChatsMessages({ page, size }));

      observer.unobserve(entry.target);
    });
    return () => {
      // eslint-disable-next-line
      observer.unobserve((messageRef.current as Element));
    }
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if ( visible ) scrollToEnd();
    if (messages && messages.length < size) return;
    if ( observer ) observer.observe((messageRef.current as Element));
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    scrollToEnd();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {Object.keys(messagesObj).map((key, keyIndex: number) => (
        <Fragment key={key}>
          <Divider sx={{ mb: 2 }}>
            <Chip label={formatDateForMessages(key)} sx={{ transform: 'translateY(50%)' }} />
          </Divider>
          {messagesObj[key].map((message: IChatMessage, index: number) => {
            const topElemIndex = messagesObj[key].length > 5 ? 5 : messagesObj[key].length - 1;
            return (
              <Box key={message.id} sx={{
                display: 'flex', alignItems: 'flex-end',
                justifyContent: message.sender.id === currentUser?.id ? 'flex-end' : 'flex-start',
              }}>
                {messages && keyIndex === Object.keys(messagesObj).length - 1 && index === messagesObj[key].length - 1 && <ElementObserver onVisible={setVisible} />}
                {message.sender.id !== currentUser?.id && (
                  <Tooltip title={message.sender.id !== currentUser?.id ? `${message.sender.firstName} ${message.sender.lastName}` : ''}>
                    <Avatar sx={{ mr: 1, background: getContent('theme').primaryColor }}>
                      {`${message.sender.firstName.slice(0,1)}${message.sender.lastName.slice(0,1)}`}
                    </Avatar>
                  </Tooltip>
                )}
                <Box ref={keyIndex === 0 && index === topElemIndex ? messageRef : null} sx={{
                  maxWidth: { xs: '100%', sm: '60%' }, padding: '10px 16px',
                  borderRadius: message.sender.id === currentUser?.id ? '8px 8px 0px 8px' : '8px 8px 8px 0px',
                  background: message.sender.id === currentUser?.id ? `linear-gradient(315deg, ${getContent('theme').secondaryColor} 0%, ${getContent('theme').primaryColor} 100%)` : `rgba(${getContent('theme').primaryColorRgb}, 0.3)`,
                }}>
                  <Typography sx={{
                    color: message.sender.id === currentUser?.id ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                    fontWeight: message.sender.id === currentUser?.id ? 500 : 400,
                    whiteSpace: 'pre-line', wordWrap: 'break-word'
                  }}>
                    {message.message}
                  </Typography>
                  <Typography sx={{
                    fontSize: '12px',
                    display: 'flex', alignItems: 'center', gap: 0.5,
                    justifyContent: message.sender.id === currentUser?.id ? 'flex-end' : 'flex-start',
                    color: message.sender.id === currentUser?.id ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
                  }}>
                    {dayjs(message.createdOn).format('HH:mm A')}
                  </Typography>
                </Box>
                {message.sender.id === currentUser?.id && (
                  <Avatar sx={{ ml: 1, background: getContent('theme').primaryColor }}>
                    {`${message.sender.firstName.slice(0,1)}${message.sender.lastName.slice(0,1)}`}
                  </Avatar>
                )}
              </Box>
            )
          })}
        </Fragment>
      ))}

      <IconButton
        sx={{ display: visible || (messages && messages.length < 5) ? 'none' : 'flex' }}
        className={classes.arrowBtn}
        onClick={scrollToEnd}
      >
        <ArrowDownwardIcon />
      </IconButton>
    </Fragment>
  )
}

export default MessagesList;

const useStyles = makeStyles({
  arrowBtn: {
    width: '40px',
    height: '40px',
    position: 'sticky',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50%',
    backgroundColor: getContent('theme').primaryColor,
    color: '#fff',
    opacity: 0.7,
    cursor: 'pointer',
    transition: 'opacity 0.3s',
    '&:hover': {
      backgroundColor: getContent('theme').primaryColor,
      opacity: 1,
    }
  },
});
