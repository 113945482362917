import { FC, Fragment, useRef, useState, useLayoutEffect, useEffect } from 'react';
import dayjs from "dayjs";
// Models
import IMessage from "models/Message";
// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
// Async
import MessagesAsync from "store/messages/messagesAsync";
// Selectors
import { selectCurrentUser } from "store/users/usersSelectors";
import { selectAllMessages, selectMessagesObj, selectTotal } from "store/messages/messagesSelectors";
// Mui
import { makeStyles } from "@mui/styles";
import { Box, Chip, Divider, IconButton, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// Components
import ElementObserver from 'components/ElementObserver';
// Utilities
import { formatDateForMessages } from "utilities/DateTimeFormatter";
import { getContent } from 'utilities/Utilities';

const size = 50;

let page = 0;
let observer:any;

type Props = {
  scrollToEnd: () => void;
}

const MessagesList:FC<Props> = ({ scrollToEnd }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  
  const currentUser = useAppSelector(selectCurrentUser);
  const messages = useAppSelector(selectAllMessages);
  const messagesObj = useAppSelector(selectMessagesObj);
  const total = useAppSelector(selectTotal);
  const totalPages = Math.ceil(total / size) - 1;

  const messageRef = useRef<HTMLDivElement | null>(null);

  const [ visible, setVisible ] = useState(false);

  useLayoutEffect(() => {
    scrollToEnd();
    if (messages && messages.length < size) return;
    observer = new IntersectionObserver(([entry]:any) => {
      if ( page === totalPages ) return;
      if ( !entry.isIntersecting ) return;

      page++;

      dispatch(MessagesAsync.refetchMessages({ page, size }));

      observer.unobserve(entry.target);
    });
    return () => {
      // eslint-disable-next-line
      observer.unobserve((messageRef.current as Element));
    }
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if ( visible ) scrollToEnd();
    if (messages && messages.length < size) return;
    if ( observer ) observer.observe((messageRef.current as Element));
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    scrollToEnd();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {Object.keys(messagesObj).map((key, keyIndex: number) => (
        <Fragment key={key}>
          <Divider sx={{ mb: 2 }}>
            <Chip label={formatDateForMessages(key)} sx={{ transform: 'translateY(50%)' }} />
          </Divider>
          {messagesObj[key].map((message: IMessage, index: number) => {
            const topElemIndex = messagesObj[key].length > 5 ? 5 : messagesObj[key].length - 1;
            return (
              <Fragment key={message.id}>
                {messages && keyIndex === Object.keys(messagesObj).length - 1 && index === messagesObj[key].length - 1 && <ElementObserver onVisible={setVisible} />}
                <Box
                  ref={keyIndex === 0 && index === topElemIndex ? messageRef : null }
                  sx={{
                    maxWidth: { xs: '100%', sm: '60%' }, padding: '10px 16px',
                    alignSelf: message.fromUser.id === currentUser?.id ? 'flex-end' : 'flex-start',
                    borderRadius: message.fromUser.id === currentUser?.id ? '8px 8px 0px 8px' : '8px 8px 8px 0px',
                    background: message.fromUser.id === currentUser?.id ? `linear-gradient(315deg, ${getContent('theme').secondaryColor} 0%, ${getContent('theme').primaryColor} 100%)` : `rgba(${getContent('theme').primaryColorRgb}, 0.3)`,
                  }}
                  >
                  <Typography sx={{
                    color: message.fromUser.id === currentUser?.id ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                    whiteSpace: 'pre-line', wordWrap: 'break-word'
                  }}>
                    {message.message}
                  </Typography>
                  <Typography sx={{
                    fontSize: '12px',
                    display: 'flex', alignItems: 'center', gap: 0.5,
                    justifyContent: message.fromUser.id === currentUser?.id ? 'flex-end' : 'flex-start',
                    color: message.fromUser.id === currentUser?.id ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
                  }}>
                    {dayjs(message.createdOn).format('HH:mm A')}
                    {message.fromUser.id === currentUser?.id && (
                      message.read === null ? <AccessTimeOutlinedIcon  fontSize="small" /> : message.read ? <DoneAllIcon fontSize="small" /> : <DoneIcon fontSize="small" />
                    )}
                  </Typography>
                </Box>
              </Fragment>
            )
          })}
        </Fragment>
      ))}

      <IconButton
        sx={{ display: visible || (messages && messages.length < 5) ? 'none' : 'flex' }}
        className={classes.arrowBtn}
        onClick={scrollToEnd}
      >
        <ArrowDownwardIcon />
      </IconButton>
    </Fragment>
  )
}

export default MessagesList;

const useStyles = makeStyles({
  arrowBtn: {
    width: '40px',
    height: '40px',
    position: 'sticky',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50%',
    backgroundColor: getContent('theme').primaryColor,
    color: '#fff',
    opacity: 0.7,
    cursor: 'pointer',
    transition: 'opacity 0.3s',
    '&:hover': {
      backgroundColor: getContent('theme').primaryColor,
      opacity: 1,
    }
  },
});
