import React, { useLayoutEffect, useRef } from "react";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Async
import ChatsAsync from "store/chats/chatsAsync";
// models
import { IChat } from "models/Chat";
// Selectors
import { selectChats, selectChatsTotal } from "store/chats/chatsSelectors";
// mui
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
// components
import ChatCard from "./ChatCard";

const size = 50;
let page = 0;
let observer:any;

const ChatsList:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const chats = useAppSelector(selectChats);
  const total = useAppSelector(selectChatsTotal);

  const totalPages = Math.ceil(total / size) - 1;

  const chatRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (chats && chats.length < size) return;
    observer = new IntersectionObserver(([entry]:any) => {
      if ( page === totalPages ) return;
      if ( !entry.isIntersecting ) return;

      page++;

      dispatch(ChatsAsync.refetchChats({ page, size }));

      observer.unobserve(entry.target);
    });
    return () => {
      // eslint-disable-next-line
      observer.unobserve((chatRef.current as Element));
    }
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (chats && chats.length < size) return;
    if ( observer ) observer.observe((chatRef.current as Element));
    // eslint-disable-next-line
  }, [chats]);

  return (
    <Box className={classes.list} sx={{ mt: { xs: 2, sm: 3 } }}>
      <Box className={classes.items}>
        {chats?.map((chat: IChat, index: number) => (
          <Box key={chat.id} ref={index === chats.length - 5 ? chatRef : null }>
            <ChatCard chat={chat} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default ChatsList;

const useStyles = makeStyles({
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 350px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 390px)',
    },
    '@media (min-width: 1240px)': {
      maxHeight: 'calc(100vh - 386px)',
    }
  },
});
