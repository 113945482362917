import React, { Fragment, useState } from "react";
import { Navigate } from "react-router-dom";
// Hooks
import { useAppSelector } from 'hooks/redux';
// Models
import { ISubscriptionBenefit } from "models/Subscriptions";
// Types
import { RootState } from "store";
import UserRoles from "types/UserRoles";
import FrequencyType from "types/FrequencyType";
// Selectors
import { selectBenefitsByFrequency, selectCurrentUser } from "store/users/usersSelectors";
import { selectSubscriptionModuleEnabled } from "store/accounts/accountsSelectors";
// Components
import BenefitCard from "./BenefitCard";
import Title from "components/Title";
import { NoData } from "components/Utilities";
// MUI
import { Box, Button, Drawer, Tab, Tabs } from "@mui/material";
import { makeStyles } from '@mui/styles';
import DailyBenefitsForm from "./DailyBenefits.form";
import useToggle from "hooks/useToggle";

const BenefitsList:React.FC = () => {
  const classes = useStyles();

  const [tab, setTab] = useState<FrequencyType>(FrequencyType.Permanent);
  const handleChangeTab = (_: any, newTab: FrequencyType) => setTab(newTab);

  const currentUser = useAppSelector(selectCurrentUser);
  const benefits = useAppSelector((state: RootState) => selectBenefitsByFrequency(state, tab));
  const subscriptionModuleEnabled = useAppSelector(selectSubscriptionModuleEnabled);

  const { open, toggle } = useToggle();

  if ( subscriptionModuleEnabled === false || currentUser?.activeRole !== UserRoles.Owner ) return <Navigate to="/" replace />;
  return (
    <Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggle}
        sx={{ zIndex: 1300 }}
        PaperProps={{
          sx: { maxWidth: '100%', width: '420px' }
        }}
      >
        <DailyBenefitsForm onClose={toggle} />
      </Drawer>

      <Box className={classes.page}>
        <div className="container">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
            <Title>My benefits</Title>
            <Button
              variant="contained"
              onClick={toggle}
            >
              Daily benefits
            </Button>
          </Box>
          <Box sx={{ pt: 2, borderBottom: 1, borderColor: 'divider', maxWidth: 'max-content' }}>
            <Tabs
              variant="scrollable"
              value={tab}
              onChange={handleChangeTab}
              aria-label="Benefit tabs"
            >
              <Tab sx={{ fontSize: { xs: '12px', sm: '14px' } }} value={FrequencyType.Permanent} label="Permanent" />
              <Tab sx={{ fontSize: { xs: '12px', sm: '14px' } }} value={FrequencyType.Monthly} label="Recurring" />
            </Tabs>
          </Box>
          {!!benefits?.length ? (
            <Box className={classes.list} sx={{ mt: 3 }}>
              <Box className={classes.items}>
                {benefits?.map((benefit: ISubscriptionBenefit) => (
                  <BenefitCard key={benefit.id} benefit={benefit} />
                ))}
              </Box>
            </Box>
          ) : <NoData />}
        </div>
      </Box>
    </Fragment>
  );
}

export default BenefitsList;

const useStyles = makeStyles({
  page: {
    height: 'calc(100vh - 266px)',
    backgroundColor: '#F5F6F7',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
      height: 'calc(100vh - 236px)',
    },
  },
  list: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    overflow: 'hidden',
  },
  items: {
    maxHeight: 'calc(100vh - 420px)',
    overflowY: 'scroll',
    backgroundColor: '#eee',
    '@media (min-width: 600px)': {
      maxHeight: 'calc(100vh - 448px)',
    },
  },
});
