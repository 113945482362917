import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
// Models
import ITimeshare from "models/Timeshare";
// components
import Title from 'components/Title';
import TimeshareCard from "../../components/TimeshareCard";
import TimeshareCardSkeleton from "components/TimeshareCard.skeleton";
// mui
import { makeStyles } from "@mui/styles";
import { Box, Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

type Props = {
  timeshares?: ITimeshare[];
  title: string;
  bgcolor: string;
  viewAllAdditionalFunc?: () => void;
  showSkeletons?: boolean;
}

const Properties:React.FC<Props> = ({ timeshares, title, bgcolor, viewAllAdditionalFunc, showSkeletons = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [countCard, setCountCard] = useState<number>(window.innerWidth >= 1900 ? 4 : window.innerWidth >= 1240 ? 3 :  window.innerWidth >= 720 ? 2.2 : window.innerWidth >= 600 ? 1.6 : 1.2);

  const handlerScreenWidth = () => {
    setCountCard(window.innerWidth >= 1900 ? 4 : window.innerWidth >= 1240 ? 3 :  window.innerWidth >= 720 ? 2.2 : window.innerWidth >= 600 ? 1.6 : 1.2);
  }

  useEffect(() => {
    window.addEventListener('resize', handlerScreenWidth);

    return () => {
      window.removeEventListener('resize', handlerScreenWidth);
    }
  }, []);

  const handleViewAll = () => {
    navigate('timeshares');
    if (viewAllAdditionalFunc) viewAllAdditionalFunc();
  }

  if (!showSkeletons && !timeshares) return null;
  return (
    <Box className={classes.block} sx={{ backgroundColor: bgcolor }}>
      <div className="big-container">
        <Box className={classes.header}>
          <Title>{title}</Title>
          <Button endIcon={<ArrowForwardIcon />} onClick={handleViewAll}>
            View all
          </Button>
        </Box>
        <Swiper
          style={{ padding: '35px 5px' }}
          spaceBetween={24}
          slidesPerView={countCard}
        >
          {timeshares ? (
            timeshares.map((timeshare: ITimeshare) => (
              <SwiperSlide key={timeshare.id}>
                <TimeshareCard timeshare={timeshare} />
              </SwiperSlide>
            ))
          ) : (
            [1,2,3,4].map(item => (
              <SwiperSlide key={item}>
                <TimeshareCardSkeleton />
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </div>
    </Box>
  )
}

export default Properties;

const useStyles = makeStyles({
  block: {
    padding: '16px 0 0 16px',
    '@media (min-width: 600px)': {
      padding: '24px 0 0 24px',
    },
    '@media (min-width: 1240px)': {
      padding: '32px 0 0',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '16px',
    '@media (min-width: 600px)': {
      paddingRight: '32px',
    },
    '@media (min-width: 1240px)': {
      paddingRight: 0,
    },
  },
});
