import React from 'react';
// Models
import { IChat } from 'models/Chat';
// MUI
import {
  Paper,
  IconButton, Typography, Box, Avatar, Button
} from "@mui/material";
import { Close as CloseIcon } from '@mui/icons-material';

type Props = {
  onClose: () => void;
  chat: IChat;
}

const ChatInfo:React.FC<Props> = ({ onClose, chat }) => {
  return (
    <Paper variant="elevation" elevation={6} sx={{ padding: '60px 40px' }}>
      <IconButton onClick={onClose} sx={{
        position: 'absolute', top: '16px', right: '16px'
      }}>
        <CloseIcon />
      </IconButton>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Avatar sx={{ width: '60px', height: '60px', fontSize: '26px' }}>
          {chat.name.split(' ').map(i => i.slice(0, 1).toUpperCase()).join('').slice(0, 2)}
        </Avatar>
        <Typography sx={{ fontSize: '30px', pt: 1 }}>
          {chat.name}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography>Participants</Typography>
        <Box sx={{ maxHeight: '250px', overflow: 'scroll' }}>
          {chat.participants.map((user) => (
            <Box key={user.id} sx={{ display: 'flex', alignItems: 'center', gap: 1, borderBottom: '1px solid #eee', pt: 1, pb: 1 }}>
              <Avatar sx={{ width: '30px', height: '30px', fontSize: '13px' }}>{`${user.firstName.slice(0,1)}${user.lastName.slice(0,1)}`}</Avatar>
              <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, gap: 1 }}>
        <Button
          variant="contained"
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
    </Paper>
  );
}

export default ChatInfo;
