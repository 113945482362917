import React from 'react';
// components
import Title from 'components/Title';
// mui
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
// Utilities
import { getContent } from 'utilities/Utilities';

const AboutPage:React.FC = () => {
  const classes = useStyles();

  const { block1, block2, block3, block4 } = getContent('aboutPage');

  return (
    <Box>
      <Box className={[classes.bigBlock, 'container'].join(' ')}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography className={classes.title}>{block1.title}</Typography>
          <Typography className={classes.text} sx={{ pt: { xs: 1, sm: 2, lg: 3 } }}>
            {block1.text}
          </Typography>
        </Box>
        <img src={block1.image} alt="" className={classes.img} />
      </Box>
      <Box sx={{ backgroundColor: 'rgba(245, 246, 247, 1)' }}>
        <Box className={[classes.smallBlock, 'container'].join(' ')}>
          <img src={block2.image} alt="" className={classes.icon} />
          <Title>{block2.title}</Title>
          <Typography className={classes.text} sx={{ textAlign: 'center' }}>
            {block2.text}
          </Typography>
        </Box>
      </Box>
      <Box className={[classes.block, 'container'].join(' ')}>
        <img src={block3.image} alt="" className={classes.img} />
        <Box sx={{ flexGrow: 1, order: { xs: -1, md: 0 } }}>
          <Title>{block3.title}</Title>
          <Typography className={classes.text} sx={{ pt: { xs: 1, sm: 2, lg: 3 }, mb: 2 }}>
            {block3.text}
          </Typography>
          {block3.title2 && <Title>{block3.title2}</Title>}
          {block3.text2 && (
            <Typography className={classes.text} sx={{ pt: { xs: 1, sm: 2, lg: 3 } }}>
              {block3.text2}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ backgroundColor: 'rgba(245, 246, 247, 1)' }}>
        <Box className={[classes.block, 'container'].join(' ')}>
          <Box>
            <Title>{block4.title}</Title>
            <Typography className={classes.text} sx={{ py: { xs: 1, sm: 2, lg: 3 } }}>
              {block4.text}
            </Typography>
            <Box className={classes.items}>
              <Box className={classes.item}>
                <img className={classes.itemImg} src="/img/dashweek.com/aboutPage/item1.png" alt="" />
                <Box className={classes.itemData}>
                  <h3 className={classes.itemTitle}>Dana Ferretti</h3>
                  <p className={classes.itemSubtitle}>Licensed Real Estate Broker</p>
                  <p className={classes.itemText}>#BK3260198</p>
                  <a href="tel:4079133274" className={`${classes.itemText} ${classes.itemLink}`}>407-913-3274(DASH)</a>
                </Box>
              </Box>
              <Box className={classes.item}>
                <img className={classes.itemImg} src="/img/dashweek.com/aboutPage/item2.png" alt="" />
                <Box className={classes.itemData}>
                  <h3 className={classes.itemTitle}>Tara Harned</h3>
                  <p className={classes.itemSubtitle}>Real Estate Sales Associate</p>
                  <p className={classes.itemText}>#SL3014248</p>
                  <a href="tel:4079133274" className={`${classes.itemText} ${classes.itemLink}`}>407-913-3274(DASH)</a>
                </Box>
              </Box>
              <Box className={classes.item}>
                <img className={classes.itemImg} src="/img/dashweek.com/aboutPage/item3.png" alt="" />
                <Box className={classes.itemData}>
                  <h3 className={classes.itemTitle}>Eileen Scramoncin</h3>
                  <p className={classes.itemSubtitle}>Real Estate Sales Associate</p>
                  <p className={classes.itemText}>#SL3603448</p>
                  <a href="tel:4079133274" className={`${classes.itemText} ${classes.itemLink}`}>407-913-3274(DASH)</a>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AboutPage;

const useStyles = makeStyles({
  bigBlock: {
    display: 'flex',
    gap: '24px',
    padding: '16px', // { xs: '32px', md: '80px 32px' },
    flexDirection: 'column', // { xs: 'column', md: 'row' }, 
    '@media (min-width: 600px)': {
      padding: '32px',
      gap: '48px',
    },
    '@media (min-width: 1240px)': {
      flexDirection: 'row',
      padding: '80px 0',
    }
  },
  block: {
    display: 'flex',
    gap: '16px',
    padding: '16px',
    flexDirection: 'column',
    '@media (min-width: 600px)': {
      gap: '32px',
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      gap: '48px',
      flexDirection: 'row',
      padding: '64px 0',
    }
  },
  smallBlock: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    '@media (min-width: 600px)': {
      gap: '16px',
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      gap: '24px',
      padding: '64px 0',
    }
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '120%',
    letterSpacing: '-1px',
    '@media (min-width: 600px)': {
      fontSize: '40px',
    },
    '@media (min-width: 1240px)': {
      fontSize: '60px',
    }
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '-0.35px',
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'justify',
    '@media (min-width: 600px)': {
      fontSize: '16px',
    },
  },
  img: {
    width: '560px',
    maxWidth: '100%',
    height: '400px',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  icon: {
    width: '64px',
    height: '64px',
    '@media (min-width: 600px)': {
      width: '96px',
      height: '96px',
    },
    '@media (min-width: 1240px)': {
      width: '112px',
      height: '112px',
    }
  },
  items: {
    width: '100%',
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '@media (min-width: 600px)': {
      paddingTop: '16px',
      gap: '16px',
    },
    '@media (min-width: 1240px)': {
      flexDirection: 'row',
    }
  },
  item: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 32px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '@media (min-width: 600px)': {
      padding: '24px',
    },
    '@media (min-width: 1240px)': {
      flexBasis: 'calc(100% / 3)',
      padding: '24px 16px 40px',
      flexDirection: 'column',
      gap: '24px',
    }
  },
  itemData: {
    textAlign: 'center',
  },
  itemImg: {
    width: '86px',
    height: '97px',
    borderRadius: '8px',
    objectFit: 'cover',
    '@media (min-width: 600px)': {
      width: '130px',
      height: '145px',
    }
  },
  itemTitle: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '133.4%',
    color: 'rgba(0,0,0,0.87)',
    '@media (min-width: 600px)': {
      fontSize: '24px'
    }
  },
  itemSubtitle: {
    paddingTop: '4px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '-0.15px',
    color: 'rgba(0,0,0,0.87)',
    textAlign: 'center',
    '@media (min-width: 600px)': {
      paddingTop: '8px'
    },
    '@media (min-width: 1240px)': {
      fontSize: '16px'
    }
  },
  itemText: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '132%',
    color: '#88889C',
    marginBottom: '4px'
  },
  itemLink: {
    textDecoration: 'none',
    transition: 'color 0.3s',
    '&:hover': {
      color: getContent('theme').primaryColor,
    }
  }
});
