import { createSlice } from "@reduxjs/toolkit";
// Async
import PaymentsAsync from "./paymentsAsync";
// Types
import Statuses from 'types/Statuses';

interface IState {
  status: Statuses;
  errorMessage: string;
}

const initialState:IState = {
  status: Statuses.Initial,
  errorMessage: '',
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setInitialStatus: (state: IState) => {
      state.status = Statuses.Initial;
      state.errorMessage = '';
    }
  },
  extraReducers: (builder) => {
    builder
      // Create payment
      .addCase(PaymentsAsync.createPayment.fulfilled, (state: IState) => {
        state.status = Statuses.Success;
      })
      .addCase(PaymentsAsync.createPayment.rejected, (state: IState, action: any) => {
        state.status = Statuses.Error;
        state.errorMessage = action.payload.message;
      })
  }
});

export const PaymentsActions = paymentsSlice.actions;

export default paymentsSlice.reducer;
