import React, { useState } from 'react';
import dayjs from 'dayjs';
// Hooks
import { useAppDispatch } from 'hooks/redux';
// Async
import SubscriptionsAsync from 'store/subscriptions/subscriptionsAsync';
// Actions
import { UsersActions } from 'store/users/usersSlice';
import { AuthActions } from 'store/auth/authSlice';
// Models
import { ISubscription } from 'models/Subscriptions';
// Types
import SubscriptionStatus from 'types/SubscriptionStatus';
// Services
import StorageService from 'services/StorageService';
// MUI
import {
  Box, Button, Card, Chip, Collapse, Divider, Grid, Tooltip, Typography
} from '@mui/material';
import {
  DoneAll as DoneAllIcon,
  Done as DoneIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
// Components
import ConfirmCancelSubscription from './ConfirmCancelSubscription';
import Collapsable from 'components/Collapsable';
import { GridBlock } from 'components/Utilities';
// Utilities
import { formatCash } from 'utilities/Utilities';

type Props = {
  subscription: ISubscription,
};

const SubscriptionCard:React.FC<Props> = ({ subscription }) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(prev => !prev);

  const handleSignOut = () => {
    StorageService.removeCredential();
    dispatch(AuthActions.setAuthenticated(false));
    dispatch(UsersActions.removeCurrentUser());
  };

  const cancelSubscription = () => {
    setLoading(true);
    dispatch(SubscriptionsAsync.cancelSubscription(subscription.id))
      .unwrap()
      .then((data) => {
        dispatch(UsersActions.updateUserSubscriptions(data));
        if (subscription.status === SubscriptionStatus.Trial) handleSignOut();
        handleOpen();
      })
      .finally(() => setLoading(false))
  }

  const endTrialDate: boolean = dayjs(subscription.startDate).add(subscription.subscriptionPackage.trialPeriodDaysNumber, 'day') < dayjs();
  const endTrialDateDays = dayjs(subscription.startDate).add(subscription.subscriptionPackage.trialPeriodDaysNumber, 'day').diff(dayjs(), 'days');

  const [ extended, setExtended ] = useState<boolean>(false);

  const toggleExtended = () => setExtended(prev => !prev);

  return (
    <React.Fragment>
      <ConfirmCancelSubscription
        open={open} onClose={handleOpen}
        cancel={cancelSubscription}
        status={subscription.status}
        name={subscription.subscriptionPackage.name}
      />

      <Card sx={{ p: 2, borderRadius: 0, backgroundColor: extended ? 'rgba(0, 0, 0, 0.04)' : '#fff', borderBottom: '1px solid #eee', width: '100%' }}>
        <Collapsable onClick={toggleExtended}>
          <Grid container spacing={2}>
            <GridBlock
              xs={10} md={4}
              sx={{ order: -1 }}
              title="Package"
              text={subscription.subscriptionPackage.name}
              rowEnabled={false}
            />
            <GridBlock
              xs={12} sm={6} md={2}
              title="Start date"
              text={dayjs(subscription.startDate).format('MMM DD YYYY')}
            />
            <GridBlock
              xs={12} sm={6} md={1}
              title="Price"
              text={formatCash(subscription.subscriptionPackage.price)}
            />
            <GridBlock
              xs={12} sm={6} md={3}
              title="Status"
              text={(
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Chip
                    size="small" 
                    color={subscription.status === SubscriptionStatus.Cancelled ? 'error' : subscription.status === SubscriptionStatus.Active ? 'success' : 'info'}
                    label={subscription.status === SubscriptionStatus.Cancelled ? 'Cancelled' : subscription.status === SubscriptionStatus.Active ? 'Active' : 'Trial'}
                    icon={subscription.status === SubscriptionStatus.Cancelled ? <CloseIcon /> : subscription.status === SubscriptionStatus.Active ? <DoneAllIcon /> : <DoneIcon />}
                  />
                  <Typography sx={{
                    fontSize: '12px',
                    color: subscription.status === SubscriptionStatus.Trial && !endTrialDate ? 'rgba(0,0,0,0.87)' : '#d32f2f'
                  }}>
                    {subscription.status === SubscriptionStatus.Trial
                      ? endTrialDate ? 'Expired' : `Expires in ${endTrialDateDays} day(s)`
                      : subscription.status === SubscriptionStatus.Active && subscription.overdue ? 'Overdue'
                      : ''
                    }
                  </Typography>
                </Box>
              )}
            />
            {subscription.cancelledDate && subscription.status === SubscriptionStatus.Active && (
              <GridBlock
                xs={12} sm={6} md={2}
                title="Cancellation scheduled"
                titleSx={{ color: '#d32f2f' }}
                text={dayjs(subscription.cancelledDate).format('MMM DD YYYY')}
              />
            )}
            {(subscription.status === SubscriptionStatus.Trial || (subscription.status === SubscriptionStatus.Active && !subscription.cancelledDate)) && (
              <Grid item xs={2} alignSelf="center" sx={{ display: 'flex', justifyContent: 'flex-end', order: { xs: -1, md: 0 } }}>
                <Tooltip title="Cancel subscription">
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    disabled={loading}
                    onClick={handleOpen}
                  >
                    Cancel
                  </Button>
                </Tooltip>
              </Grid>
              )}
          </Grid>
        </Collapsable>
        <Collapse in={extended}>
          <Divider sx={{ p: 1 }} />
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <GridBlock
              xs={12}
              title="Billing period"
              text="Monthly"
            />
          </Grid>
        </Collapse>
      </Card>
    </React.Fragment>
  );
}

export default SubscriptionCard;
