import { useRef } from 'react';

const excludedTagNames:string[] = ['BODY', 'BUTTON', 'A', 'INPUT'];

type Props = {
  onClick?: () => void;
  children: React.ReactNode;
}

const Collapsable:React.FC<Props> = ({ onClick, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClick = (event:any) => {
    if ( excludedTagNames.includes(event.target.tagName) ) return;

    const parents = getParents(event.target, ref.current);

    if ( parents.some((parent:any) => excludedTagNames.includes(parent.tagName)) ) return;

    if ( typeof onClick !== 'undefined' ) onClick();
  }

  return (
    <div ref={ref} onClick={handleClick}>
      {children}
    </div>
  )
}

export default Collapsable;


const getParents = (elem:any, parent:any) => {
  const result = [];
  for ( ; elem && elem !== parent; elem = elem.parentNode ) {
    result.push(elem);
  }
  return result;
};