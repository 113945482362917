import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
// Async
import UsersAsync from 'store/users/usersAsync';
// Hooks
import { useAppDispatch } from 'hooks/redux';
// Components
import { Input } from 'components/Controls';
// Mui
import { LoadingButton } from '@mui/lab';
import {
  FormGroup, Paper, FormHelperText, Typography
} from '@mui/material';
import {
  Circle as CircleIcon,
  CancelOutlined as CancelOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
} from '@mui/icons-material';
// Utitliies
import { isFieldRequired, isMatch, isMinValue, isUpperCase, isLowerCase, isSpecial } from 'utilities/Validation';

type Props = {
  onClose: () => void,
}

interface IFormData {
  password?: string;
  rePassword?: string;
};

const SetPasswordForm:React.FC<Props> = ({ onClose }) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryParams = new URLSearchParams(search);

  const isNewPassword = pathname === '/recover-password';
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const { control, handleSubmit, formState:{ errors }, watch } = useForm<IFormData>({
    defaultValues: {
      password: '',
      rePassword: ''
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    setIsLoading(true);
    const { password } = data;
    const nextData = {
      password: password,
      code: queryParams.get('code'),
    }
    if (isNewPassword) {
      dispatch(UsersAsync.recoverPassword(nextData))
        .unwrap()
        .then(() => {
          navigate('/');
        })
        .finally(() => {
          setIsLoading(false);
        })
    } else {
      dispatch(UsersAsync.registerUser(nextData))
        .unwrap()
        .finally(() => {
          setIsLoading(false);
        })
    }
  });

  const watchPassword = watch('password');

  return (
    <Paper variant="outlined" sx={{ p: 3 }}>
      <Typography variant="h5" textAlign="center">
        {`Create ${isNewPassword ? 'new' : ''} password`}
      </Typography>
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        {/* Password */}
        <Controller
          control={control} name="password"
          rules={{
            required: isFieldRequired,
            validate: {
              isMinValue: (value:string | undefined) => isMinValue(value, 8),
              isLowerCase,
              isUpperCase,
              isSpecial
            }
          }}
          render={({ field }) => (
            <Input
              {...field}
              label={`${isNewPassword ? 'New' : ''} Password`} type="password" autoComplete="off"
              required
              error={Boolean(errors.password)}
              helperText={errors.password ? errors.password.message : ''}
            />
          )}
        />
        <Controller
          control={control} name="rePassword"
          rules={{
            required: isFieldRequired,
            validate: {
              isPasswordMatch: (value:string | undefined) => isMatch(value, watchPassword, 'Password doesn`t match')
            }
          }}
          render={({ field }) => (
            <Input
              {...field}
              label={`Confirm ${isNewPassword ? 'New' : ''} Password`} type="password" autoComplete="off"
              required={watchPassword !== ''}
              error={Boolean(errors.rePassword && watchPassword !== '')}
              helperText={errors.rePassword && watchPassword !== '' ? errors.rePassword.message : ''}
            />
          )}
        />
        
        <FormGroup>
          <FormHelperText sx={{
            display: 'flex', gap: 0.5, alignItems: 'center',
            color: !watchPassword ? 'gray' : isMinValue(watchPassword, 8) === true ? '#2E7D32' : '#C62828'
          }}>
            
            {!watchPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isMinValue(watchPassword, 8) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
            Your password must be 8 or more characters in length.
          </FormHelperText>
          <FormHelperText sx={{
            display: 'flex', gap: 0.5, alignItems: 'center',
            color: !watchPassword ? 'gray' : isUpperCase(watchPassword) === true && isLowerCase(watchPassword) === true ? '#2E7D32' : '#C62828'
          }}>
            {!watchPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isUpperCase(watchPassword) === true && isLowerCase(watchPassword) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
            Your password must contain 1 or more uppercase and lowercase characters.
          </FormHelperText>
          <FormHelperText sx={{
            display: 'flex', gap: 0.5, alignItems: 'center',
            color: !watchPassword ? 'gray' : isSpecial(watchPassword) === true ? '#2E7D32' : '#C62828'
          }}>
            {!watchPassword ? <CircleIcon sx={{ fontSize: '10px' }} /> : isSpecial(watchPassword) === true ? <CheckCircleOutlinedIcon /> : <CancelOutlinedIcon /> }
            Your password must contain 1 or more special characters.
          </FormHelperText>
        </FormGroup>

        <div>
          <LoadingButton
            sx={{ mt: 2 }}
            type="submit"
            loading={isLoading}
            fullWidth
            variant="contained"
            color="primary"
          >
            {pathname === '/recover-password' ? 'Change password' : 'Confirm registration'}
          </LoadingButton>
        </div>
      </form>
    </Paper>
  );
}

export default SetPasswordForm;
