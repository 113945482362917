import React, { ChangeEvent } from "react";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Actions
import { TimesharesActions } from "store/timeshares/timesharesSlice";
// Selectors
import { selectParams } from "store/timeshares/timesharesSelectors";
import { selectAllDestinationTypes } from "store/destinationTypes/destinationTypesSelectors";
// Models
import IDestinationType from "models/DestinationType";
// MUI
import {
  Box, Button, ButtonGroup, Checkbox, Divider,
  FormControlLabel, IconButton, Typography
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
// Utilities
import { getContent } from 'utilities/Utilities';

type Props = {
  onClose?: () => void,
}

const TimeshareFilters:React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const destinationTypes = useAppSelector(selectAllDestinationTypes) || [];
  const params = useAppSelector(selectParams);

  const onChangeDestinationTypes = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const nextParams = {
      ...params,
      destinationTypesIds: params.destinationTypesIds ? checked ? [...params.destinationTypesIds, name] : params.destinationTypesIds.filter((destinationTypeId:any) => destinationTypeId !== name) : [name],
    }
    dispatch(TimesharesActions.setParams(nextParams));
  }

  const onChangeCountBedrooms = (event: any) => {
    const bedrooms = event.target.textContent.toLowerCase();
    const nextParams = {
      ...params,
      bedrooms: params.bedrooms === bedrooms ? null : bedrooms,
    }
    dispatch(TimesharesActions.setParams(nextParams));
  }

  const handleSpecialOffer = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const nextParams = {
      ...params,
      specialOfferOnly: checked,
    }
    dispatch(TimesharesActions.setParams(nextParams));
  }

  return (
    <Box sx={{
      backgroundColor: '#fff', boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)', border: '1px solid #eee', borderRadius: '8px',
      height: 'max-content', width: onClose ? '360px' : '100%'
    }}>
      <Box sx={{ display: 'flex', padding: '18px 24px' }}>
        <Typography className={classes.title}>Filters</Typography>
        <Box flexGrow="1" />
        {onClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Divider />
      <Box sx={{ p: 3 }}>
        <FormControlLabel control={<Checkbox checked={params.specialOfferOnly || false} onChange={handleSpecialOffer} />} label="Only DashDeals" />
      </Box>
      <Divider />
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
        <Typography className={classes.subtitle} sx={{ pb: 1 }}>{getContent('labels').labelDestinationTypeSingularText}</Typography>
        {destinationTypes.map((destinationType: IDestinationType) => (
          <FormControlLabel
            key={destinationType.id}
            control={
              <Checkbox
                id={`${destinationType.id}`}
                name={`${destinationType.id}`}
                checked={params.destinationTypesIds ? params.destinationTypesIds.includes(`${destinationType.id}`) : false}
                onChange={onChangeDestinationTypes}
              />
            }
            label={destinationType.name}
          />
        ))}
      </Box>
      <Divider />
      <Box sx={{ p: 3 }}>
        <Typography className={classes.subtitle} sx={{ pb: 1 }}>Bedrooms</Typography>
        <ButtonGroup variant="outlined" aria-label="outlined button group" onClick={onChangeCountBedrooms}>
          <Button variant={params.bedrooms === 'studio' ? 'contained' : 'outlined'}>Studio</Button>
          <Button variant={params.bedrooms === '1' ? 'contained' : 'outlined'}>1</Button>
          <Button variant={params.bedrooms === '2' ? 'contained' : 'outlined'}>2</Button>
          <Button variant={params.bedrooms === '3' ? 'contained' : 'outlined'}>3</Button>
          <Button variant={params.bedrooms === '4' ? 'contained' : 'outlined'}>4</Button>
        </ButtonGroup>
      </Box>
    </Box>
  )
}

export default TimeshareFilters;

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '-0.35px',
    color: 'rgba(0, 0, 0, 0.87)',
  }, 
  subtitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '48px',
    letterSpacing: '-0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  }
});
