import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';

export default class CalendarsAsync {
  private static _url:string = '/client/calendars';
  private static _http:HttpClient = new HttpClient();

  // Fetch calendars slots
  public static fetchCalendarsSlots = createAsyncThunk('calendars/fetchCalendarsSlots', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/slots`, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Fetch calendars slots
  public static fetchDailyBenefits = createAsyncThunk('calendars/fetchDailyBenefits', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(`${this._url}/dailyBenefits`, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });
}
