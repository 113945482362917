import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Async
import ChatsAsync from "./chatsAsync";
// Models
import { IChat, IChatMessage } from "models/Chat";

interface IState {
  chatsMessages: IChatMessage[] | null;
  chatsMessagesTotal: number;
  chats: IChat[] | null;
  chatsTotal: number;
  paramsChatsMessages: {
    size: number;
    page: number;
  };
  paramsChats: {
    size: number;
    page: number;
    search: string;
  };
  selectedChatId: number | null;
}

const initialState:IState = {
  chatsMessages: null,
  chatsMessagesTotal: 0,
  chats: null,
  chatsTotal: 0,
  paramsChatsMessages: {
    size: 50,
    page: 0,
  },
  paramsChats: {
    size: 100,
    page: 0,
    search: '',
  },
  selectedChatId: null,
};

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    addMessage: (state, action:PayloadAction<any>) => {
      const { chatId, ...message } = action.payload;
      const chat = state.chats?.find((chat: IChat) => chat.id === chatId);
      state.chatsMessages = state.chatsMessages && state.selectedChatId === chatId ? [...state.chatsMessages, action.payload] : state.chatsMessages;
      state.chats = state.chats && chat
        ? [{ ...chat, lastMessage: message.message, lastMessageCreatedOn: message.createdOn }, ...state.chats.filter((chat: IChat) => chat.id !== chatId)]
        : state.chats
    },
    sendMessage: (state, action:PayloadAction<any>) => {
      const { chatId, ...message } = action.payload;
      const chat = state.chats?.find((chat: IChat) => chat.id === chatId);
      state.chatsMessages = state.chatsMessages ? [...state.chatsMessages, message] : [message];
      state.chats = state.chats && chat
        ? [{ ...chat, lastMessage: message.message, lastMessageCreatedOn: message.createdOn }, ...state.chats.filter((chat: IChat) => chat.id !== chatId)]
        : state.chats
    },
    sendMessageSuccess: (state, action:PayloadAction<number>) => {
      state.chatsMessages = state.chatsMessages
      ? state.chatsMessages.length > state.paramsChatsMessages.size && state.chatsMessages.length < state.chatsMessagesTotal
        ? [...state.chatsMessages.slice(1).map(message => message.id === action.payload ? {...message, read: false} : message)]
        : [...state.chatsMessages.map(message => message.id === action.payload ? {...message, read: false} : message)]
      : [];
      state.chatsMessagesTotal = state.chatsMessagesTotal + 1;
    },
    setSelectedChatId: (state, action) => {
      state.selectedChatId = action.payload;
    },
    removeUserFromChat: (state, action) => {
      state.chats = state.chats ? state.chats?.filter(chat => chat.id !== action.payload) : state.chats;
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch chats messages
      .addCase(ChatsAsync.fetchChatsMessages.pending, (state, action) => {
        state.chatsMessages = null;
        state.paramsChatsMessages = action.meta.arg;
      })
      .addCase(ChatsAsync.fetchChatsMessages.fulfilled, (state, action:PayloadAction<any>) => {
        state.chatsMessages = action.payload.data ? action.payload.data.reverse() : [];
        state.chatsMessagesTotal = action.payload.total;
      })
      // Refetch chats messages
      .addCase(ChatsAsync.refetchChatsMessages.pending, (state, action) => {
        state.paramsChatsMessages = action.meta.arg;
      })
      .addCase(ChatsAsync.refetchChatsMessages.fulfilled, (state, action:PayloadAction<any>) => {
        state.chatsMessages = state.chatsMessages ? [...action.payload.data.reverse(), ...state.chatsMessages] : action.payload.data.reverse() || [];
        state.chatsMessagesTotal = action.payload.total;
      })
      // Fetch chats
      .addCase(ChatsAsync.fetchChats.pending, (state, action) => {
        state.paramsChats = action.meta.arg;
      })
      .addCase(ChatsAsync.fetchChats.fulfilled, (state, action:PayloadAction<any>) => {
        state.chats = action.payload.data || [];
        state.chatsTotal = action.payload.total;
      })
      // Refetch chats
      .addCase(ChatsAsync.refetchChats.fulfilled, (state, action:PayloadAction<any>) => {
        state.chats = state.chats ? [...state.chats, ...action.payload.data, ] : action.payload.data;
      })
      // Fetch chat by id
      .addCase(ChatsAsync.fetchChatById.fulfilled, (state, action:PayloadAction<any>) => {
        state.chats = state.chats ? [action.payload, ...state.chats] : [action.payload];
        state.chatsTotal = state.chatsTotal + 1;
      })
      // Leave chat
      .addCase(ChatsAsync.leaveChat.fulfilled, (state, action:PayloadAction<any>) => {
        state.chats = state.chats
        ? state.chats.filter((chat:IChat) => chat.id !== action.payload)
        : state.chats;
      })
  }
});

export const ChatsActions = chatsSlice.actions;

export default chatsSlice.reducer;
