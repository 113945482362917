import { FC, useEffect, useState } from 'react';
// Redux
import { useAppSelector } from 'hooks/redux';
import { selectLandingPage } from 'store/landingPages/landingPagesSelectors';
// MUI
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination as SwiperPagination } from "swiper";
// Styles
import './styles.css';
import Title from 'components/Title';
import { ExpandMore } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const LandingPage:FC = () => {
  const classes = useStyles();

  const landingPage = useAppSelector(selectLandingPage);
  const [centeredSlides, setCenteredSlides] = useState(window.innerWidth >= 1240 ? true : false);

  const handleCenteredSlides = () => {
    setCenteredSlides(window.innerWidth >= 1240 ? true : false);
  }

  useEffect(() => {
    window.addEventListener('resize', handleCenteredSlides);

    return () => {
      window.removeEventListener('resize', handleCenteredSlides);
    }
  }, []);

  if (!landingPage) return null;
  return (
    <Box sx={{ mb: 4 }}>
      {landingPage.title ? (
        <Title style={{ mb: 2 }}>{landingPage.title}</Title>
      ) : null}
      {landingPage.images ? (
        <Box sx={{ mb: 6 }}>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={centeredSlides}
            slidesPerView={centeredSlides ? 2 : 1.2}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
              
            }}
            pagination={{
              clickable: true,
            }}
            modules={[EffectCoverflow, SwiperPagination]}
            className="region-images-swiper"
          >
            {landingPage.images?.map(image => (
              <SwiperSlide key={image.id}>
                <img src={image.url} alt="" className={classes.image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      ) : null}
      <Box sx={{ textAlign: 'justify', mb: 6 }}>
        <div dangerouslySetInnerHTML={{ __html: landingPage.content }} />
      </Box>
      {landingPage.faqItems?.length ? (
        <Box>
          <Title>FAQ</Title>
          {landingPage.faqItems?.map(faqItem => (
            <Accordion key={faqItem.question} disableGutters sx={{
              background: 'none',
              boxShadow: 'none',
              '&:not(:last-child)': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
              },
              '&:before': {
                display: 'none'
              }
            }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`faq-${faqItem.question}`}
                id={`faq-${faqItem.question}`}
                sx={{ fontWeight: 'bold' }}
              >
                {faqItem.question}
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 4, opacity: 0.8 }}>
                {faqItem.answer}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      ) : null}
    </Box>
  )
}

export default LandingPage;

const useStyles = makeStyles({
  subtitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '48px',
    letterSpacing: '-0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  image: {
    maxHeight: '250px',
    objectFit: 'contain',
    '@media (min-width: 900px)': {
      maxHeight: '500px',
    },
  },
});
