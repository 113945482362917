import React, { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Async
import MessagesAsync from "store/messages/messagesAsync";
// Selectors
import { selectAllMessages, selectIsUnreadMessages } from "store/messages/messagesSelectors";
import { selectCurrentUser } from "store/users/usersSelectors";
// Components
import Title from "components/Title";
import SendMessage from "./SendMessage";
import MessagesList from "./MessagesList";
// Mui
import { makeStyles } from "@mui/styles";
import { Box, LinearProgress } from "@mui/material";

const MessagesPage:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const messageEndRef = useRef<null | HTMLDivElement>(null);

  const isUnreadMessages = useAppSelector(selectIsUnreadMessages);
  const messages = useAppSelector(selectAllMessages);
  const currentUser = useAppSelector(selectCurrentUser);

  const scrollToEnd = useCallback(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'auto' });
    // eslint-disable-next-line
  }, [messageEndRef]);

  useEffect(() => {
    if (isUnreadMessages && pathname === '/helpdesk' && currentUser) {
      dispatch(MessagesAsync.markAsReadMessages(currentUser.id))
    }
    // eslint-disable-next-line
  }, [isUnreadMessages]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  return (
    <Box className={classes.page}>
      <div className="container">
        <Title>Helpdesk</Title>
        <Box className={classes.messages}>
          <Box className={classes.messagesContent}>
            {messages ? (
                <MessagesList scrollToEnd={scrollToEnd} />
            ) : <LinearProgress />}
            <div style={{ height: '1px' }} ref={messageEndRef} />
          </Box>
          <SendMessage scrollToEnd={scrollToEnd} />
        </Box>
      </div>
    </Box>
  )
}

export default MessagesPage;

const useStyles = makeStyles({
  page: {
    backgroundColor: 'rgba(245, 246, 247, 1)',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
    },
  },
  messages: {
    marginTop: '16px',
    border: '1px solid #eee',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: '4px',
    '@media (min-width: 600px)': {
      marginTop: '24px',
    }
  },
  messagesContent: {
    position: 'relative',
    height: 'calc(100vh - 383px)',
    overflowY: 'scroll',
    backgroundColor: 'rgba(250, 250, 250, 1)',
    padding: '16px',
    display: 'flex',
    flexBasis: '100%',
    flexDirection: 'column',
    gap: '8px',
    '@media (min-width: 600px)': {
      height: 'calc(100vh - 443px)',
    },
    '@media (min-width: 1240px)': {
      height: 'calc(100vh - 447px)',
    },
  },
});
