import { useState, Fragment, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
// Hooks
import { useAppDispatch, useAppSelector } from 'hooks/redux';
// Models
// Async
import SubscriptionsAsync from 'store/subscriptions/subscriptionsAsync';
import CalendarsAsync from 'store/calendars/calendarsAsync';
// Actions
import { UiActions } from 'store/ui/uiSlice';
// Selectors
import { selectDailyBenefits } from 'store/calendars/calendarsSelectors';
// Mui
import { LoadingButton } from '@mui/lab';
import {
  DialogTitle, DialogContent, DialogActions,
  TextField, Button, Chip, Box, Typography, LinearProgress
} from '@mui/material';
// dayjs
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

interface IFormData {
  ownerNotes: string;
}

type Props = {
  onClose: () => void;
}

const DailyBenefitsForm:FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dailyBenefits = useAppSelector(selectDailyBenefits);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSlotId, setSelectedSlotId] = useState<number | null>(null);
  const [selectedBenefitId, setSelectedBenefitId] = useState<number | null>(null);

  const { control, handleSubmit } = useForm<IFormData>({
    defaultValues: {
      ownerNotes: '',
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    setIsLoading(true);

    const nextData:any = {...data};
    if (selectedSlotId) nextData['calendarSlotId'] = selectedSlotId;
    dispatch(SubscriptionsAsync.createBenefitRequest({ benefitId: selectedBenefitId, ...nextData }))
      .unwrap()
      .then(() => {
        dispatch(UiActions.enqueueSnackbar({ message: 'Benefit claim was created' }));
        navigate('/benefit-claims');
        onClose();
      })
      .finally(() => setIsLoading(false));
  });

  useEffect(() => {
    const zoneId = dayjs.tz.guess();
    
    dispatch(CalendarsAsync.fetchDailyBenefits({zoneId}));
    // eslint-disable-next-line
  }, []);

  if (!dailyBenefits) return <LinearProgress />;
  return (
    <Fragment>
      <DialogTitle>Daily benefits</DialogTitle>
      <DialogContent dividers>
        {!dailyBenefits.items?.length ? (
          <Typography textAlign="center">{`No free daily benefit(s) for claim`}</Typography>
        ) : (
          <>
            {dailyBenefits.items.map(dailyBenefit => (
              <Box key={dailyBenefit.benefitData.id} sx={{ my: 4 }}>
                <Typography sx={{ mb: 1.5 }}>{dailyBenefit.benefitData.name}</Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
                  {dailyBenefit.slots.map(slot => {
                    const startTime:string = dayjs(slot.start).format('hh:mm A');
                    return (
                      <Chip
                        key={startTime}
                        label={startTime}
                        color="secondary"
                        variant={selectedSlotId === slot.id ? 'filled' : 'outlined'}
                        onClick={() => {
                          setSelectedSlotId(slot.id);
                          setSelectedBenefitId(dailyBenefit.benefitData.id);
                        }}
                      />
                    )
                  })}
                </Box>
              </Box>
            ))}
            <form onSubmit={onSubmit} noValidate>
                {/* ownerNotes */}
                <Controller
                  control={control} name="ownerNotes"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Notes"
                      type="text"
                      multiline
                      rows={3}
                      helperText="Specify preferred dates and times or any other details"
                    />
                  )}
                />
            </form>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ width: '100%' }}>
        <Button
          variant="text"
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={!selectedSlotId}
        >
          Claim
        </LoadingButton>
      </DialogActions>
    </Fragment>
  )
}

export default DailyBenefitsForm;
