import React, { useEffect, useState } from 'react';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Async
import PollsAsync from 'store/polls/pollsAsync';
// Actions
import { pollActions } from 'store/polls/pollsSlice';
// Selectors
import { selectSelectedPoll } from 'store/polls/pollsSelectors';
// Mui
import { makeStyles } from '@mui/styles';
import {
  DialogContent, DialogTitle, Box, IconButton,
  Typography, CircularProgress,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// Components
import PollAnswerItem from './PollAnswerItem';
import { InfoBlock } from 'components/Utilities';

type Props = {
  onClose: () => void;
}

const PollDetailsDialog:React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const poll = useAppSelector(selectSelectedPoll);

  const vote = (answerId: number) => {
    if (loading || !poll) return;
    setLoading(true);
    dispatch(PollsAsync.vote({ id: poll.id, answerId }))
      .unwrap()
      .then(() => getPoll())
      .catch(() => setLoading(false))
  }

  const getPoll = () => {
    if (!poll) return;
    dispatch(PollsAsync.fetchPoll(poll.id))
      .unwrap()
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    return () => {
      dispatch(pollActions.setInitialField('selectedPoll'));
    }
    // eslint-disable-next-line
  }, []);

  const allVotes:number = poll?.answers.reduce((acc, cur) => acc + (cur.votes || 0), 0) || 0;

  if (!poll) return null;

  return (
    <React.Fragment>
      {loading && (
        <div className={classes.loading}><CircularProgress color="secondary" size={80} /></div>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogTitle>{poll.voted ? 'Details' : 'Vote'}</DialogTitle>
        <IconButton aria-label="close" onClick={onClose} sx={{ mr: 2 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <InfoBlock title="Question:" text={poll.question} />
        <Box className={classes.list}>
          <Typography sx={{ fonrSize: '18px', fontWeight: 500 }}>Answers</Typography>
          {poll.answers.map((answer) => (
            <PollAnswerItem
              key={answer.id}
              answer={answer}
              allVotes={allVotes}
              vote={vote}
              voted={poll.voted}
            />
          ))}
        </Box>
      </DialogContent>
    </React.Fragment>
  )
}

export default PollDetailsDialog;

const useStyles = makeStyles({
  list: {
    border: '1px solid #eee',
    margin: '20px 0',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2,
  }
});
