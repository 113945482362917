import React from 'react';
// Hooks
import { useAppSelector, useAppDispatch } from 'hooks/redux';
// Actions
import { AssetsActions } from 'store/assets/assetsSlice';
// Modules
import IAsset from 'models/Asset';
// Types
import AssetType from 'types/AssetType';
// Selectors
import { selectAssetsIdsToDelete } from 'store/assets/assetsSelectors';
// Mui
import { Box, IconButton, Tooltip } from '@mui/material';
// Icons
import { Delete as DeleteIcon, Cancel as CancelIcon } from '@mui/icons-material';

const imagesExt = ['.jpg', '.jpeg', '.png', '.bmp', '.avif'];

type Props = {
  asset: IAsset;
  type: AssetType;
}

const AssetsListAssetItem:React.FC<Props> = ({ asset, type }) => {
  const dispatch = useAppDispatch();

  const assetsIdsToDelete = useAppSelector(selectAssetsIdsToDelete);

  const isImage = asset.url ? imagesExt.some((ext:string) => asset.url.includes(ext)) : false;
  const isInDelete = assetsIdsToDelete.includes(asset.id);

  const toggleAssetDelete = () => {
    if ( assetsIdsToDelete.includes(asset.id) ){
      dispatch(AssetsActions.removeAssetIdFromDelete(asset.id))
    } else {
      dispatch(AssetsActions.addAssetIdToDelete(asset.id))
    }
  }

  const deleteButton = (
    <Tooltip title={isInDelete ? 'Cancel' : 'Delete'}>
      <IconButton
        sx={{
          p: 0.5,
          bgcolor: type === AssetType.Images ? 'rgba(211, 47, 47, 0.5)' : 'none',
          color: type === AssetType.Images ? '#fff' : '#d32f2f',
          '&:hover': {
            bgcolor: type === AssetType.Images ? '#d32f2f' : 'none',
          }
        }}
        onClick={toggleAssetDelete}
      >{isInDelete ? <CancelIcon /> : <DeleteIcon />}</IconButton>
    </Tooltip>
  );
  
  return (
    <React.Fragment>
      {isImage && type !== AssetType.Contracts  ? (
        <Box sx={{ position: 'relative', width: 160, height: 160, bgcolor: '#ededed' }}>
          <img src={asset.url} alt={asset.url} style={{ width: '100%', height: '100%', objectFit: 'contain', opacity: isInDelete ? 0.5: 1 }} />
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            {deleteButton}
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <Box sx={{ flexGrow: 1, opacity: isInDelete ? 0.5: 1 }}>
            <a href={asset.url} target="_blank" rel="noreferrer" style={{ color: '#48A8D0', textDecoration: 'none' }}>{asset.name}</a>
          </Box>
          <Box>
            {deleteButton}
          </Box>
        </Box>
      )}
    </React.Fragment>
  )
}

export default AssetsListAssetItem;
