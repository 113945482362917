import { useState } from 'react';

const useToggle = (initialOpen?:boolean) => {
  const [ open, setOpen ] = useState(initialOpen || false);

  const toggle = () => setOpen(!open);

  return { open, toggle };
}

export default useToggle;
