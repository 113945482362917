import { createAsyncThunk } from "@reduxjs/toolkit";
// Utilities
import HttpClient from 'utilities/HttpClient';

export default class MessagesAsync {
  private static _url:string = '/client/helpdesk/messages';
  private static _http:HttpClient = new HttpClient();

  // Fetch messages
  public static fetchMessages = createAsyncThunk('messages/fetchMessages', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(this._url, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Refetch messages
  public static refetchMessages = createAsyncThunk('messages/refetchMessages', async (params:any, thunkApi) => {
    const response:Response = await this._http.get(this._url, params);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return response.json();
  });

  // Mark as read
  public static markAsReadMessages = createAsyncThunk('messages/markAsReadMessages', async (currentUserId:number, thunkApi) => {
    const response:Response = await this._http.put(`${this._url}/actions/markAsRead`, null);
    if ( !response.ok ) return thunkApi.rejectWithValue(({...await response.json(), status: response.status}) as any);
    return currentUserId;
  });
}
