import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

type Props = {
  gaId: string;
}

const GoogleAnalytics:React.FC<Props> = ({ gaId }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(gaId);
    ReactGA.pageview(location.pathname + location.search);
    // eslint-disable-next-line
  }, [location]);

  return null;
};

export default GoogleAnalytics;
