import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IProperty from "models/Property";
// Async
import PropertiesAsync from "./propertiesAsync";

interface IState {
  properties: IProperty[] | null;
  property: IProperty | null;
  total: number;
}

const initialState:IState = {
  properties: null,
  property: null,
  total: 0,
};

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    clearProperties: (state) => {
      state.properties = null;
      state.total = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch properties
      .addCase(PropertiesAsync.fetchProperties.pending, (state) => {
        state.properties = null;
      })
      .addCase(PropertiesAsync.fetchProperties.fulfilled, (state, action:PayloadAction<any>) => {
        state.properties = action.payload.data;
        state.total = action.payload.total;
      })
      // Fetch property by slug
      .addCase(PropertiesAsync.fetchPropertyBySlug.fulfilled, (state, action:PayloadAction<IProperty>) => {
        state.property = action.payload;
      })
  }
});

export const PropertiesActions = propertiesSlice.actions;

export default propertiesSlice.reducer;
