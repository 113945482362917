import React from "react";
import { NavLink } from "react-router-dom";
// Redux
import { useAppSelector } from "hooks/redux";
import { selectSelectedType } from "store/ui/uiSelectors";
import TimeshareTypes from "types/TimeshareTypes";
// MUI
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { EmailOutlined as EmailOutlinedIcon, PhoneEnabledOutlined as PhoneEnabledOutlinedIcon } from '@mui/icons-material';
// Utilities
import { getContent } from 'utilities/Utilities';

const Footer:React.FC = () => {
  const classes = useStyles();

  const selectedType = useAppSelector(selectSelectedType);

  const year = new Date().getFullYear();
  
  const { rentEmail, saleEmail, phone, phoneLabel, twitter, facebook, instagram } = getContent('contacts');

  const email = selectedType === TimeshareTypes.Rent ? rentEmail : saleEmail;

  return (
    <Box className={classes.footer}>
      <Box className={[classes.footerContent, 'container'].join(' ')}>
        <Box sx={{
          display: 'flex', gap: '8px', flexDirection: { xs: 'column', sm: 'row' },
          alignSelf: 'flex-start', order: 1, '@media (min-width:1240px)': { alignSelf: 'flex-end', order: 0 }
        }}>
          <Typography sx={{ order: { xs: 1, sm: 0 } }}>{`© ${year} ${getContent('common').name}`}</Typography>
          <Typography sx={{ display: { xs: 'none', sm: 'inline-block' } }}>•</Typography>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <NavLink to="terms-and-conditions" target="_blank" className={classes.link}>
              Terms and Conditions
            </NavLink>
            •
            <NavLink to="privacy-policy" target="_blank" className={classes.link}>
              Privacy Policy
            </NavLink>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: { xs: 3, sm: 5 }, flexDirection: { xs: 'column', sm: 'row' } }}>
          <Box>
            <Typography sx={{ pb: 2, fontWeight: 500 }}>Get in touch</Typography>
            <Typography sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <EmailOutlinedIcon />
              <Typography component="a" href={`mailto:${email}`} className={classes.link}>{email}</Typography>
            </Typography>
            <Typography sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <PhoneEnabledOutlinedIcon />
              <Typography component="a" href={`tel:${phone}`} className={classes.link}>{phoneLabel}</Typography>
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ pb: { xs: 1, sm: 2.5 }, fontWeight: 500 }}>Follow us</Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography component="a" href={instagram} target="_blank" rel="noreferrer" className={classes.iconLink}>
                <img src="/img/icons/socials/instagram.svg" alt="instagram" width="40" height="40" />
              </Typography>
              <Typography component="a" href={facebook} target="_blank" rel="noreferrer" className={classes.iconLink}>
                <img src="/img/icons/socials/facebook.svg" alt="facebook" width="40" height="40" />
              </Typography>
              {/* <Typography component="a" href={linkedin} target="_blank" rel="noreferrer" className={classes.iconLink}>
                <img src="/img/icons/socials/linkedIN.svg" alt="linkedin" width="40" height="40" />
              </Typography> */}
              <Typography component="a" href={twitter} target="_blank" rel="noreferrer" className={classes.iconLink}>
                <img src="/img/icons/socials/twitter.svg" alt="twitter" width="40" height="40" />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer;

const useStyles = makeStyles({
  footer: {
    backgroundColor: 'rgba(232, 236, 237, 1)',
    padding: '16px',
    '@media (min-width: 600px)': {
      padding: '32px',
    },
    '@media (min-width: 1240px)': {
      padding: '40px 0',
    }
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    flexDirection: 'column',
    '@media (min-width: 600px)': {
      gap: '32px',
    },
    '@media (min-width: 1240px)': {
      flexDirection: 'row',
    },
  },
  link: {
    color: 'rgba(0,0,0,0.87)',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': {
      color: getContent('theme').primaryColor,
    }
  },
  iconLink: {
    transition: 'all 0.3s',
    '&:hover': {
      transform: 'scale(1.2)',
    }
  }
});
